import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { StringOrEmpty } from '../../../app.model';
import { Edge } from '../../../edge/edge.model';
import { EdgeActions } from './edge.action-types';
import { Update } from '@ngrx/entity/src/models';
import { UpdateCameraIpInEdge } from './edge.actions';
import _ from 'lodash';

export interface EdgeState extends EntityState<Edge.EdgeDocument> {
  isFirstLocationEdgeLoaded: boolean;
  isAllLocationEdgeLoaded: boolean;
  isPagination: boolean;
  error: StringOrEmpty;
}

export const adapter: EntityAdapter<Edge.EdgeDocument> = createEntityAdapter<Edge.EdgeDocument>({
  selectId: (edge: Edge.EdgeDocument) => edge.edgeId!,
});

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();

const initialLocationEdgeState: EdgeState = adapter.getInitialState({
  isFirstLocationEdgeLoaded: false,
  isAllLocationEdgeLoaded: false,
  isPagination: false,
  error: undefined,
});

export const locationEdgeReducer = createReducer(
  initialLocationEdgeState,

  on(EdgeActions.GetLocationEdgesSuccess, (state, action) => {
    return adapter.setMany(action.payload, state);
  }),

  on(EdgeActions.GetLocationEdgesFail, (state, action) => {
    return {
      ...initialLocationEdgeState,
      error: action.message,
    };
  }),

  on(EdgeActions.CreateLocationEdgeSuccess, (state, action) => {
    return adapter.setOne(action.payload, state);
  }),

  on(EdgeActions.DeleteCameraSuccess, (state, action) => {
    const cameras = _.cloneDeep(state.entities[action.edgeId].cameras);
    delete cameras[action.cameraId];
    const change: Update<Edge.EdgeDocument> = {
      id: action.edgeId,
      changes: {
        cameras,
      },
    };
    return adapter.updateOne(change, state);
  }),

  on(EdgeActions.CreateLocationEdgeFail, (state, action) => {
    return {
      ...initialLocationEdgeState,
      error: action.message,
    };
  }),

  on(EdgeActions.DeleteEdgeSuccess, (state, action) => {
    return adapter.removeOne(action.response.edgeId, state);
  }),

  on(EdgeActions.DeleteEdgeFail, (state, action) => {
    return {
      ...state,
      error: action.message,
    };
  }),

  on(EdgeActions.UpdateEdgeLocalAddressSuccess, (state, action) => {
    const { edgeId } = action.request;
    const update: Update<Edge.EdgeDocument> = {
      id: edgeId,
      changes: {
        ...action.request,
      },
    };
    return adapter.updateOne(update, state);
  }),

  on(EdgeActions.UpdateEdgeLocalAddressFail, (state, action) => {
    return {
      ...state,
      error: action.message,
    };
  }),

  on(EdgeActions.UpdateEdgeNoBackendCallSuccess, (state, action) => {
    const { edgeId } = action.request;
    const update: Update<Edge.EdgeDocument> = {
      id: edgeId,
      changes: {
        ...action.request.update,
      },
    };
    return adapter.updateOne(update, state);
  }),

  on(EdgeActions.UpdateEdgeNoBackendCallFail, (state, action) => {
    return {
      ...state,
      error: action.message,
    };
  }),

  on(EdgeActions.SetEdgeIsLocalDebug, (state, action) => {
    const { edgeId } = action.request;
    const update: Update<Edge.EdgeDocument> = {
      id: edgeId,
      changes: {
        ...action.request,
      },
    };
    return adapter.updateOne(update, state);
  }),

  on(EdgeActions.ResetEdgeIsLocal, (state, action) => {
    const { edgeId } = action.request;
    const update: Update<Edge.EdgeDocument> = {
      id: edgeId,
      changes: {
        localUrl: undefined,
        isLocal: false,
        localExpiry: 0,
      },
    };
    return adapter.updateOne(update, state);
  }),
  on(EdgeActions.UpdateCameraIpInEdge, (state, action) => {
    const { edgeId, cameraId, ipAddress } = action;
    const update: Update<Edge.EdgeDocument> = {
      id: edgeId,
      changes: {
        localUrl: undefined,
        isLocal: false,
        localExpiry: 0,
        cameras: {
          ...state.entities[edgeId].cameras,
          [cameraId]: {
            ...state.entities[edgeId].cameras[cameraId],
            edgeOnly: {
              ...state.entities[edgeId].cameras[cameraId].edgeOnly,
              networkDetails: {
                ...state.entities[edgeId].cameras[cameraId].edgeOnly.networkDetails,
                ipAddress,
              },
            },
          },
        },
      },
    };
    return adapter.updateOne(update, state);
  }),
  on(EdgeActions.getEdgesSwVersionSuccess, (state, { edgeId, swVersion }) => {
    const update: Update<Edge.EdgeDocument> = {
      id: edgeId,
      changes: {
        swVersion,
      },
    };
    return adapter.updateOne(update, state);
  }),
);
