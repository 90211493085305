import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DeviceOptions, ResolutionOptions, RetentionOptions, StorageOptions, BoxConfiguration, BoxPrice, LicenseTerms, CameraPrice, Pricing, PricingLocation, PricingCount } from '@models/pricing.model';
import { boxConfiguration, boxPricing, cameraPricing, PricingTermToYears, swLicensePricing } from '@consts/pricing.const';

@Injectable({
  providedIn: 'root',
})

export class PriceCalculatorService {

  constructor(private http: HttpClient) {
  }

  boxConfiguration: BoxConfiguration[] = boxConfiguration;
  boxPricing: BoxPrice[] = boxPricing;
  swLicensePricing: Record<LicenseTerms, number> = swLicensePricing;
  cameraPricing: CameraPrice[] = cameraPricing;

  getBoxPrice(storage: StorageOptions, device: DeviceOptions, terms: LicenseTerms): number {
    const elem = this.boxPricing.find((item) => {
      return (item.storage === storage && item.device === device);
    });
    if (elem != null)
      return elem.price[terms];
    return null;
  }

  getCameraPrice(resolution: ResolutionOptions, terms: LicenseTerms): number {
    const elem = this.cameraPricing.find((item) => {
      return (item.resolution === resolution);
    });
    if (elem != null)
      return elem.price[terms];
    return null;
  }

  getSWLicensePrice(terms: LicenseTerms): number {
    return this.swLicensePricing[terms];
  }

  getCloudAddonsPrice(cloudStorage: number, cloudSmartStorage: number, cloudFlowTagging: number): number {
    return cloudStorage * 99 + cloudSmartStorage * 129 + cloudFlowTagging * 39;
  }

  getBoxConfiguration(device: DeviceOptions, storage: StorageOptions, retention: RetentionOptions, resolution: ResolutionOptions): BoxConfiguration {
    return this.boxConfiguration.filter((item) => {
      return (retention === item.retention && resolution === item.resolution && item.device === device && item.storage === storage);
    })[0];
  }

  getMaxCameras(device: DeviceOptions, storage: StorageOptions, retention: RetentionOptions, resolution: ResolutionOptions): number {
    const boxConfiguration = this.getBoxConfiguration(device, storage, retention, resolution);
    return boxConfiguration.maxNumberOfCameras;
  }


  getMaxBoxConfiguration(numOfCameras: number, retention: RetentionOptions, resolution: ResolutionOptions, includeOrinNano: boolean = false): BoxConfiguration {
    if (numOfCameras <= 0)
      return null;
    const relevantConfigurations = (includeOrinNano ? boxConfiguration.filter((item) => {
        return (retention === item.retention && resolution === item.resolution);
      }) :
      boxConfiguration.filter((item) => {
        return (retention === item.retention && resolution === item.resolution && item.device != DeviceOptions.Nano8GBOrin);
      }))
      .sort((a, b) => {
        return (a.maxNumberOfCameras - b.maxNumberOfCameras);
      });
    for (let i = 0; i < relevantConfigurations.length; i++) {
      if (numOfCameras <= relevantConfigurations[i].maxNumberOfCameras) {
        return relevantConfigurations[i];
      }
    }
    // return the biggest
    return relevantConfigurations[relevantConfigurations.length - 1];
  }

  getLocationPricing(location: PricingLocation, term: LicenseTerms): { pricing: Pricing, count: PricingCount } {
    const {numberOfCameras, retention, resolution, numberOfNewCameras} = location;
    const termNum = PricingTermToYears[term];
    let cameraLeft = numberOfCameras;
    let pricing: Pricing = {boxes: 0, license: 0, cameras: 0, total: 0};
    let count: PricingCount = {
      box2tb: 0,
      box4tb: 0,
      license: numberOfCameras,
      cameras8mp: numberOfNewCameras * (resolution === ResolutionOptions.Resolution8MP ? 1 : 0),
      cameras5mp: numberOfNewCameras * (resolution === ResolutionOptions.Resolution5MP ? 1 : 0),
    };
    let box = 0;
    while (cameraLeft > 0) {
      const item = this.getMaxBoxConfiguration(cameraLeft, retention, resolution);
      cameraLeft -= item.maxNumberOfCameras;
      box++;

      // 2TB box is not a recommendation anymore
      const boxPrice = this.getBoxPrice(StorageOptions.Storage4TB, item.device, term);
      count.box4tb++
      pricing.boxes += boxPrice * termNum;
    }
    pricing.total += pricing.boxes;
    const cameraPrice = this.getCameraPrice(resolution, term);
    pricing.cameras = cameraPrice * numberOfNewCameras * termNum;
    pricing.total += pricing.cameras;

    const licensePrice = this.getSWLicensePrice(term);
    pricing.license = licensePrice * numberOfCameras * termNum;
    pricing.total += pricing.license;
    return {pricing, count};
  }
}
