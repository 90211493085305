import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { CamerasThumbnailsService } from './camera-thumnails.service';
import { CameraThumbnailsData, Thumbnail, ThumbnailEntity } from './camera-thumbnails.model';
import { FadeInAnimation } from '../../framework/animations';
import { environment } from '../../../environments/environment';
import * as moment from 'moment-timezone';
import { KeyValuePairs } from '../../core/interfaces';
import { ThumbnailTemplate } from 'src/app/shared/thumbnail/thumbnail.component';
import { MediaCacheService } from '../../shared/media-cache/media-cache.service';

const WEEK_IN_MSECS = 24 * 7 * 3600 * 1000;

export enum CameraThumbnailsSort {
  DESC,
  ASC,
}

@Component({
  selector: 'app-camera-thumbnails',
  templateUrl: './camera-thumbnails.component.html',
  styleUrls: ['./camera-thumbnails.component.scss'],
  animations: [FadeInAnimation],
})
export class CameraThumbnailsComponent implements OnInit, AfterViewInit, OnChanges {
  // @ViewChild('picker')
  // picker: MatDateRangePicker<any>;

  // @ViewChild('scroller')
  // scroller: ElementRef;

  @Output() thumbOpened = new EventEmitter<void>();
  @Output() thumbClosed = new EventEmitter<void>();

  @Input() scroller: HTMLDivElement;

  @ViewChild('startPicker')
  startPicker: ElementRef;

  @ViewChild('endPicker')
  endPicker: ElementRef;

  @Input()
  startInput: string;

  @Input()
  endInput: string;

  @Input()
  clipInput: string;

  @Input()
  offsetResInput: string;

  @Input()
  timezone: string;

  @Input()
  sort: CameraThumbnailsSort = CameraThumbnailsSort.DESC;

  @Input()
  isShareVisible: boolean = true;

  @Output()
  onTimePeriodChangeEvent: EventEmitter<{ startTime: number, endTime: number }> = new EventEmitter<{ startTime: number, endTime: number }>();

  rtl = false;

  // TODO: Remove default values - only for debug
  data: CameraThumbnailsData;

  thumbnailsRaw: ThumbnailEntity[];
  events: KeyValuePairs<number[]> = {};

  thumbnails: number[][] = [];
  rendered: Thumbnail[] = [];

  baseUrl: string;
  // Will be given with camera details
  duration: number = 20000;

  apiPage = 0;
  apiSize = 100;

  pageSize: number = 20;
  page: number = 0;
  numThumbs: number = 0;

  firstTimestamp: number;
  lastTimestamp: number;

  edgeId: string;
  cameraId: string;

  options = new UntypedFormGroup({
    start: new UntypedFormControl(new Date(new Date(new Date().getTime() - 24 * 2 * 3600 * 1000).setHours(0, 0, 0, 0))),
    end: new UntypedFormControl(new Date(new Date(new Date().getTime() + 24 * 1 * 3600 * 1000).setHours(0, 0, 0, 0))),
    clipInSeconds: new UntypedFormControl('86400'),
    offsetRes: new UntypedFormControl(600),
  });

  public ThumbnailTemplate = ThumbnailTemplate;

  constructor(
    private cameraThumbnailsService: CamerasThumbnailsService,
    private mediaCacheService: MediaCacheService,
    public cd: ChangeDetectorRef) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.data) {
      return;
    }
    if (changes['startInput'] || changes['endInput']) {
      if (changes['startInput']) {
        this.options.get('start')
          ?.setValue(changes['startInput'].currentValue);
      }
      if (changes['endInput']) {
        this.options.get('end')
          ?.setValue(changes['endInput'].currentValue);
      }
      this.onDateChanged();
    }

    if (changes['clipInput']) {
      this.options.get('clipInSeconds')
        ?.setValue(changes['clipInput'].currentValue);
      this.onClipChanged();
    }
    if (changes['offsetResInput']) {
      this.options.get('offsetRes')
        ?.setValue(changes['offsetResInput'].currentValue);
    }

    if (changes['sort']) {
      this.rtl = changes['sort'].currentValue === CameraThumbnailsSort.DESC ? true : false;
      this.onClipChanged();
    }
  }

  get startControl() {
    return this.options.get('start');
  }

  get start() {
    return this.options.get('start')?.value;
  }

  get end() {
    return this.options.get('end')?.value;
  }

  get clipInSeconds() {
    return this.options.get('clipInSeconds')?.value;
  }

  get offsetRes() {
    return this.options.get('offsetRes');
  }

  ngOnInit(): void {
    this.options.get('start')
      .setValue(this.startInput);
    this.options.get('end')
      .setValue(this.endInput);
    this.cameraThumbnailsService.refresh$.subscribe(() => {
      this.onClipChanged();
    });
    this.cameraThumbnailsService.thumbnailsData$.subscribe(data => {
      const { edgeId, cameraId } = { ...data };

      if (!data) {
        return;
      }

      this.edgeId = edgeId!;
      this.cameraId = cameraId!;

      if (!!this.data && this.data.edgeId === edgeId && this.data.cameraId === cameraId) {
        this.data.offsetResInDurations = data.offsetResInDurations;
        return;
      }

      this.data = data;
      this.setOffsetResolution(600);
      this.data.offsetResInDurations = 45;

      this.baseUrl = `${environment.thumbnailsUrl}/thumbnails/${data.edgeId}/${data.cameraId}`;
      const startInLocale = this.cameraThumbnailsService.convertTsToZone(new Date(this.start).getTime(), moment.tz.guess(), this.timezone);
      const endInLocale = this.cameraThumbnailsService.convertTsToZone(new Date(this.end).getTime(), moment.tz.guess(), this.timezone);

      const start = this.getBaseInLocale(new Date(startInLocale));
      const end = this.getBaseInLocale(new Date(endInLocale));

      this.mediaCacheService.getThumbnails([{
        edgeId: this.data.edgeId,
        cameraId: this.data.cameraId,
      }], startInLocale, endInLocale);

      this.buildThumbs();

      // this.cameraThumbnailsService.getThumbnailsByDateFromDb(this.data.edgeId, this.data.cameraId, start, end)
      //   .subscribe(list => {
      //     this.thumbnailsRaw = list;
      //     this.buildEvents();
      //   });
    });
  }

  ngAfterViewInit(): void {
  }

  resString(duration) {
    const resDate = new Date(duration);
    const minutes = Math.floor(duration / 60000);
    const secs = resDate.getSeconds();
    let res = (minutes ? `${minutes} minutes ` : '') + (secs ? `${secs} seconds` : '');
    return res;
  }

  onClipChanged() {
    this.buildThumbs();
    this.onScrollDown();
  }

  renderPage() {
    this.buildThumbsPage();
  }

  setOffsetResolution(offsetRes: number) {
    this.offsetRes?.setValue(offsetRes);
  }

  get desc() {
    return this.sort === CameraThumbnailsSort.DESC;
  }

  buildThumbsPage() {
    const start = this.cameraThumbnailsService.convertTsToZone(new Date(this.start).getTime(), moment.tz.guess(), this.timezone);
    const end = this.cameraThumbnailsService.convertTsToZone(new Date(this.end).getTime(), moment.tz.guess(), this.timezone);
    const firstItem = this.page * this.pageSize;
    const lastItem = Math.min((this.page + 1) * this.pageSize, this.numThumbs);
    const clipInMsec = this.normalizeTimestamp(this.clipInSeconds * 1000);

    for(let i = firstItem; i < lastItem; i++) {
      let thumbnails: number[] = [];
      let startTime;
      let endTime;
      if (this.desc) {
        startTime = this.normalizeTimestamp(end - (i + 1) * clipInMsec);
        if (startTime < start) {
          startTime = this.normalizeTimestamp(start);
        }
        endTime = this.normalizeTimestamp(end - i * clipInMsec - 1);
        if (endTime > end) {
          endTime = this.normalizeTimestamp(end);
        }
      } else {
        startTime = this.normalizeTimestamp(start + i * clipInMsec);
        if (startTime < start) {
          startTime = this.normalizeTimestamp(start);
        }
        endTime = this.normalizeTimestamp(start + (i + 1) * clipInMsec - 1);
        if (endTime > end) {
          endTime = this.normalizeTimestamp(end);
        }
      }

      const base = this.getBaseInLocale(new Date(startTime));
      const baseEnd = this.getBaseInLocale(new Date(endTime));

      // if (!!this.events[base] || !!this.events[baseEnd]) {
      //   if (base !== baseEnd && baseEnd !== endTime) {
      //     const indexStart = this.cameraThumbnailsService.getEventLocation(startTime, base);
      //     const indexEnd = this.cameraThumbnailsService.getEventLocation(endTime, baseEnd);
      //
      //     // We need to combine 2 days to one array
      //     let first: number[] = [];
      //     let second: number[] = [];
      //     if (!!this.events[base]) {
      //       first = this.events[base].slice(indexStart, this.events[base].length);
      //     } else {
      //       first = new Array(43200).fill(0)
      //         .slice(indexStart, 43200);
      //     }
      //     if (!!this.events[baseEnd]) {
      //       second = this.events[baseEnd].slice(0, indexEnd);
      //     } else {
      //       second = new Array(43200).fill(0)
      //         .slice(0, indexEnd);
      //     }
      //
      //     thumbnails = first.concat(second);
      //   } else {
      //     if (!!this.events[base]) {
      //       const indexStart = this.cameraThumbnailsService.getEventLocation(startTime, base);
      //       const indexEnd = this.cameraThumbnailsService.getEventLocation(endTime, base);
      //       thumbnails = this.events[base].slice(indexStart, indexEnd);
      //     }
      //   }
      // }

      this.rendered[i] = {
        thumbnails,
        options: {
          startTime,
          endTime,
          duration: this.duration,
          clipInSeconds: (endTime - startTime) / 1000,
        },
      };
    }
  }

  getDatesInRange() {
    const date = new Date(this.start);
    const dates: number[] = [];
    while (date <= this.end) {
      const hour = Math.floor(date.getUTCHours() / 24) * 24;
      const dateT = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), hour, 0, 0, 0);
      // TODO: Need to be changed calculated according to user <--> location locale user is Israel but locale is GMT
      var tz = moment.tz.guess();
      const currentLocale = moment.tz(dateT, tz)
        .format('YYYY-MM-DD HH:mm');
      const cameraLocale = moment.tz(currentLocale, 'Etc/GMT')
        .unix() * 1000;
      dates.push(cameraLocale);
      date.setDate(date.getDate() + 1);
    }
    return dates;
  }

  getBaseInLocale(date: Date) {
    return this.cameraThumbnailsService.getBaseInLocale(date, this.timezone);
  }

  getTimestampInLocale(date: Date) {
    return this.cameraThumbnailsService.getTimestampInLocale(date, 'GMT');
  }

  normalizeTimestamp(timestamp: number) {
    return this.cameraThumbnailsService.normalizeTimestamp(timestamp);
  }

  buildThumbs() {
    const start = this.getTimestampInLocale(new Date(this.start));
    const end = this.getTimestampInLocale(new Date(this.end));

    const timeDelta = end - start;
    const clipInMsec = this.clipInSeconds * 1000;
    // this.getDatesInRange()

    this.numThumbs = timeDelta >= clipInMsec ? Math.ceil(timeDelta / clipInMsec) : 1;
    this.rendered = [];
    this.page = 0;
    this.renderPage();
    if (!!this.scroller?.scrollTop) {
      this.scroller.scrollTop = 6;
    }
    setTimeout(() => this.cd.detectChanges(), 3000);
  }

  buildEvents() {
    // for(let thumb of this.thumbnailsRaw) {
    //   // Compute only if doesn't already exist
    //   if (!this.events[thumb.base]) {
    //     const events: number[] = [];
    //     for(let index = 0; index < 5400; index++) {
    //       let val = thumb.events[index];
    //       if (val) {
    //       }
    //       events.push(val & 0x0000000f);
    //       events.push((val & 0x000000f0) >> (4 * 1));
    //       events.push((val & 0x00000f00) >> (4 * 2));
    //       events.push((val & 0x0000f000) >> (4 * 3));
    //       events.push((val & 0x000f0000) >> (4 * 4));
    //       events.push((val & 0x00f00000) >> (4 * 5));
    //       events.push((val & 0x0f000000) >> (4 * 6));
    //       events.push((val & 0xf0000000) >> (4 * 7));
    //     }
    //     this.events[thumb.base] = events;
    //   }
    // }
    this.buildThumbs();
  }

  onDateChanged() {
    if (!!this.start && !!this.end && new Date(this.start).getTime() < new Date(this.end).getTime()) {
      const startInLocale = this.cameraThumbnailsService.convertTsToZone(new Date(this.start).getTime(), moment.tz.guess(), this.timezone);
      const endInLocale = this.cameraThumbnailsService.convertTsToZone(new Date(this.end).getTime(), moment.tz.guess(), this.timezone);

      const start = this.getBaseInLocale(new Date(startInLocale));
      const end = this.getBaseInLocale(new Date(endInLocale));

      /** TODO: We can and should only call backend to (and db) to fetch thumbnails when they are not exist
       Need to hold a sorted list of bases and fetch a range of bases when start and end exist instead of fetching over and over again
       */

      const data: CameraThumbnailsData = {
        edgeId: this.data.edgeId,
        cameraId: this.data.cameraId,
      };
      this.cameraThumbnailsService.getThumbnails(data, start, end);
      this.buildThumbs();
      // this.cameraThumbnailsService.getThumbnailsByDateFromDb(this.data.edgeId, this.data.cameraId, start, end)
      //   .subscribe(list => {
      //     this.thumbnailsRaw = list;
      //     this.buildEvents();
      //   });
    }
  }

  onScrollDown() {
    this.page++;
    this.renderPage();
  }


  public top() {
    if (!this.scroller?.scrollTop) {
      return true;
    }
    return this.scroller.scrollTop < 5;
  }

  getClipThumbnail(start) {
    this.cameraThumbnailsService.getClipThumbnail(this.baseUrl, start, this.duration)
      .subscribe(thumb => {
      });
  }


  onTimePeriodChange(period: { startTime: number, endTime: number }) {
    this.onTimePeriodChangeEvent.emit(period);
  }
}
