<ng-container *ngrxLet="locationName$; let locationName">
  <div appVisibilityChange (visibilityChanged)="checkHiddenDocument($event)" fxLayout="column"
       fxLayoutAlign="start center" class="playback-player new-design p-r" fxFlexFill
  >
    <div *ngIf="!videoLoader && !(offline && !lastFiveMinutes) && (!playbackErrors?.maxSessions && !playbackErrors?.internal && !hlsPlaybackError) && !isLive && !inactive"
         class="flex-row center-center control-overlay"
    >
      <div (click)="paused ? clickPlay.emit() : clickPause.emit()">
        <mat-icon [svgIcon]="paused ? 'playback-play' : 'playback-pause'"></mat-icon>
      </div>
    </div>
    <div *ngIf="processing || recentVideo" class="processing">
      <div class="flex-col center-center">
        <ui-preloader [diameter]="32" [color]="PreloaderColor.blue" class="mb-10"></ui-preloader>
        <div>{{ recentVideo ? 'Recent' : 'The' }} video is currently processing.</div>
        <div *ngIf="!recentVideo">It will be available in about {{ processingTime }} seconds.</div>
      </div>
    </div>
    <div #playerWrapper class="p-r playback-wrapper w-100">
      <div *ngIf="error" fxLayoutAlign="center center" class="reload-button">
        <button matTooltip="Reload playback" mat-icon-button (click)="play()">
          <mat-icon>refresh</mat-icon>
        </button>
      </div>
      <div *ngIf="loading && !error" fxLayoutAlign="center center" class="reload-button">
        <mat-spinner diameter="45"></mat-spinner>
      </div>

      <div class="offline-overlay"
           *ngIf="!webrtcPlayer?.inZoom()"
           [class.show]="!isLiveView && offline && !lastFiveMinutes"
      >
        <div>Video unavailable</div>
      </div>
      <div class="offline-overlay"
           *ngIf="!webrtcPlayer?.inZoom()"
           [class.show]="!isLiveView && playbackErrors?.internal"
      >
        <div class="flex-col start-center">
          <div>Oops! We're encountering some technical issues at the moment.<br> Please hang tight and try again
               shortly.
          </div>
          <ui-button type="primary" (click)="startPlayback(baseTime)" class="mt-24">Try again</ui-button>
        </div>
      </div>
      <div class="offline-overlay"
           *ngIf="!webrtcPlayer?.inZoom()"
           [class.show]="!isLiveView && playbackErrors?.maxSessions"
      >
        <div class="flex-col start-center">
          <div>Looks like there's a jam! The Core is currently handling the maximum<br>number of playback sessions.
               Please
               close any unnecessary playback<br> windows, or wait a moment and try again. Thank you for your patience.
          </div>
          <ui-button type="primary" (click)="clickPlay.emit()" class="mt-24" style="z-index: 999">Try again
          </ui-button>
        </div>
      </div>
      <div class="offline-overlay"
           *ngIf="!webrtcPlayer?.inZoom()"
           [class.show]="!isLiveView && !!playbackErrors?.error"
      >
        <div class="flex-col start-center">
          <div>
            {{ hlsPlaybackErrorsStr[playbackErrors?.statusCode] }}
            ({{ playbackErrors?.statusCode }})
          </div>
          <ui-button type="primary" (click)="clickPlay.emit()" class="mt-24" style="z-index: 999">Try again
          </ui-button>
        </div>
      </div>
      <div class="time-overlay" *ngIf="!isLiveView">
        {{ timeDate }} {{ timezoneAbbreviation }}
      </div>
      <ui-camera-status class="time-overlay" *ngIf="isLiveView" [cameraId]="cameraId"
                        [showStatus]="true"
                        [showTime]="true"
                        [timezone]="timezone"
                        [playing]="webrtcPlayer?.isPlaying"
      ></ui-camera-status>

      <ui-area-selector
        *ngIf="!(playbackErrors?.maxSessions || playbackErrors?.internal) && (!loading && !error && (webrtc ? !webrtcPlayer?.inZoom() : !player?.inZoom()))"
        (selected)="webrtc ? webrtcPlayer.zoomArea($event) : player.zoomArea($event)"
      ></ui-area-selector>
      <!--      <ui-quality-selector [class.hidden]="isFullscreen || (webrtc ? !webrtcPlayer?.inZoom() : player?.inZoom())"-->
      <!--                           #qualitySelector-->
      <!--                           class="playback-quality-selector"-->
      <!--                           (qualityChanged)="qualityChange($event, player?.video?.currentTime * 1000 * speed || 0)"></ui-quality-selector>-->
      <div *ngIf="false && !error"
           matRipple
           fxLayout="row"
           fxLayoutAlign="center center"
           class="take-snapshot c-p hover"
           [class.camera-view]="isCameraView"
           matTooltip="Take snapshot"
           (click)="webrtc ? webrtcPlayer.takeSnapshot(cameraName, locationName, time + player?.video?.currentTime * 1000 * speed || 0) : player.takeSnapshot(cameraName, locationName, time + player?.video?.currentTime * 1000 * speed || 0)"
      >
        <span class="ui-icon-camera1"></span>
      </div>

      <!--      <div *ngIf="!error" #t="matMenuTrigger" matRipple fxLayout="row" fxLayoutAlign="center center"-->
      <!--           class="speed-selector c-p" fxLayoutGap="5px" [ngClass]="{ hover: !t.menuOpen }"-->
      <!--           [matMenuTriggerFor]="speedMenu">-->
      <!--        <mat-icon svgIcon="lightning"></mat-icon>-->
      <!--        <div>{{ speed }}x</div>-->
      <!--      </div>-->
      <mat-menu yPosition="below" xPosition="before" #speedMenu="matMenu" fxLayoutAlign="column" class="speed-options">
        <button *ngFor="let s of speeds" mat-menu-item (click)="changeSpeed(s)">
          <mat-icon>{{ s === speed ? 'check' : '' }}</mat-icon>
          {{ s }}
        </button>
      </mat-menu>
      <div *ngIf="false && !error"
           fxLayout="row"
           fxLayoutAlign="center center"
           class="fullscreen-button hover c-p"
           fxLayoutGap="5px"
           (click)="clickFullScreen.emit()"
           matTooltip="Full screen"
      >
        <span class="ui-icon-fullscreen-2"></span>
      </div>
      <app-inactivity [countDown]="countDown" [inactive]="inactive" (keepPlaying)="keepPlaying()"
                      (pause)="pauseVid()" (resume)="play()" [playback]="true"
      ></app-inactivity>
      <stream-player *ngIf="!webrtc" #player [playback]="true" (mediaEvents)="handleMediaEvents($event)"
                     (playing)="setPlaying()"
                     (timeUpdate)="timeUpdate($event)" [src]="src" [allowZoom]="true" class="w-100"
      ></stream-player>
      <webrtc-player *ngIf="webrtc && !isLiveView"
                     #webrtcPlayer
                     [permissionCheck]="permissionCheck"
                     [allowZoom]="true"
                     [playback]="true"
                     [edgeId]="edgeId"
                     [cameraId]="cameraId"
                     [duration]="duration"
                     [playbackTS]="startTime"
                     [locationId]="locationId"
                     [isMediasoup]="false"
                     [showZoomButtons]="true"
                     [enableQualitySelection]="false"
                     [playbackSeek]="webrtcSeek"
                     class="w-100"
                     (noMoreFiles)="noMoreFiles()"
                     (_forceMp4)="forceMp4()"
                     [style.height]="!playerHeight && isFullscreen ? '100vh' : playerHeight + 'px'"
                     [class.fullscreen]="isFullscreen"
                     [cameraView]="isCameraView"
                     (clickFullScreen)="clickFullScreen.emit()"
                     (clickTakeSnapshot)="webrtcPlayer.takeSnapshot(cameraName, locationName, time + player?.video?.currentTime * 1000 * speed || 0)"
      ></webrtc-player>
      <webrtc-player *ngIf="webrtc && isLiveView"
                     #webrtcPlayer
                     [permissionCheck]="permissionCheck"
                     [allowZoom]="true"
                     [playback]="false"
                     [edgeId]="edgeId"
                     [cameraId]="cameraId"
                     [duration]="duration"
                     [playbackTS]="startTime"
                     [locationId]="locationId"
                     [isMediasoup]="false"
                     [showZoomButtons]="true"
                     [enableQualitySelection]="true"
                     [playbackSeek]="webrtcSeek"
                     class="w-100"
                     [style.height]="!playerHeight && isFullscreen ? '100vh' : playerHeight + 'px'"
                     (noMoreFiles)="noMoreFiles()"
                     [class.fullscreen]="isFullscreen"
                     [autostart]="true"
                     [cameraView]="isCameraView"
                     (clickFullScreen)="clickFullScreen.emit()"
                     (clickTakeSnapshot)="webrtcPlayer.takeSnapshot(cameraName, locationName, time + player?.video?.currentTime * 1000 * speed || 0)"
      ></webrtc-player>
      <ui-ptz class="hover" *ngIf="ptzSupport && isLiveView" [locationId]="locationId"
              [edgeId]="edgeId" [cameraId]="cameraId"
      ></ui-ptz>
    </div>
    <!--    <div fxLayout="column" class="w-100">-->
    <!--      <app-player-controls #controls [timezone]="timezone" [playing]="!player?.video?.paused" [player]="player"-->
    <!--                           *ngIf="!webrtc"-->
    <!--                           [videoCurrentTime]="player?.video?.currentTime * 1000 * speed || 0" [durationInMs]="duration"-->
    <!--                           [archiveTimeline]="archiveTimeline" (maximize)="maximize()" (reload)="pollReload()"-->
    <!--                           (stop)="pause()"-->
    <!--                           (start)="play()" (rewind)="rewind()" (rewindStart)="rewindStart()"-->
    <!--                           (onArchiveModalShowed)="onArchiveModalShow($event)"></app-player-controls>-->
    <!--      <app-playback-timeline #timeline (seekStart)="seekStart()" [timezone]="timezone" [currentTs]="time"-->
    <!--                             [videoCurrentTimeRaw]="webrtc ? webrtcPlayer?.currentTime * 1000 * speed || 0 : player?.video?.currentTime * 1000 * speed || 0"-->
    <!--                             (seek)="seek($event)"-->
    <!--                             (saveArchive)="saveToArchive($event)" (onArchiveReady)="onArchiveReady($event)"-->
    <!--                             class="mt-10"></app-playback-timeline>-->
    <!--    </div>-->
  </div>
</ng-container>
