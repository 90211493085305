<div class="flex-row start-center camera-actions-wrapper gap-8" (click)="$event.stopPropagation();">
  <span matTooltip="Zoom out"
        class="ui-icon-camera-control-zoomin ui-icon-button no-border"
        (click)="zoomOut.emit()"
  ></span>
  <span matTooltip="Reset zoom"
        class="ui-icon-camera-control-resetzoom ui-icon-button no-border"
        (click)="zoomReset.emit()"
  ></span>
  <span matTooltip="Zoom in"
        class="ui-icon-camera-control-zoomout ui-icon-button no-border"
        (click)="zoomIn.emit()"
  ></span>
  <div class="horizontal-divider"></div>
  <span *ngIf="isDeveloper"
        matTooltip="Show participants"
        class="ui-icon-eye ui-icon-button no-border"
        (click)="showParticipants.emit()"
  ></span>
  <!--  <span matToolTip="Change quality"-->
  <!--        class="ui-icon-camera-control-quality ui-icon-button no-border"-->
  <!--        (click)="resolutionChanged.emit()"-->
  <!--  ></span>-->
  <span matTooltip="Take snapshot"
        class="ui-icon-camera1 ui-icon-button no-border"
        (click)="_takeSnapshot()"
  ></span>
  <span matTooltip="Full screen"
        class="ui-icon-fullscreen-2 ui-icon-button no-border"
        (click)="fullScreen.emit()"
  ></span>

</div>
