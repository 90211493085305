import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { catchError, share, switchMap } from 'rxjs';
import { MfaService } from '../../services/mfa.service';
import { MfaActions } from '@states/mfa/mfa.action-types';
import { SharedActions } from '@states/shared/shared.action-types';
import { mergeMap } from 'rxjs/operators';

@Injectable()
export class MfaEffects {
  public getUserMfaMethods$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MfaActions.getUserMfaMethods),
      switchMap(() => {
        return this.mfaService
          .getAll()
          .pipe(
            switchMap(res => {
              return [
                MfaActions.checkUserMfaEnabledSuccess(res.deleteEnabled),
                MfaActions.getUserMfaMethodsSuccess({ documents: res.list }),
              ];
            }),
            catchError(response => {
              return [
                MfaActions.getUserMfaMethodsFail(),
              ];
            }),
            share(),
          );
      }),
    ),
  );

  public removeUserMfaMethod$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MfaActions.removeUserMfaMethod),
      mergeMap(({ id }) => {
        return this.mfaService
          .delete(id)
          .pipe(
            mergeMap(res => {
              return [
                MfaActions.checkUserMfaEnabledSuccess(res.deleteEnabled),
                MfaActions.removeUserMfaMethodSuccess({ id }),
                SharedActions.showMessage({ success: 'Mfa removed successfully' }),
              ];
            }),
            catchError(response => {
              return [
                SharedActions.showMessage({ error: response?.error?.message ?? 'Mfa removed failed' }),
                MfaActions.removeUserMfaMethodFail({ id }),
              ];
            }),
            share(),
          );
      }),
    ),
  );


  public createMfa = createEffect(() =>
    this.actions$.pipe(
      ofType(MfaActions.createMfa),
      switchMap(() => {
        return this.mfaService
          .create()
          .pipe(
            switchMap(res => {
              return [
                MfaActions.checkUserMfaEnabledSuccess(res.deleteEnabled),
                MfaActions.createMfaSuccess(res),
              ];
            }),
            catchError(response => {
              return [
                MfaActions.createMfaFail(),
              ];
            }),
            share(),
          );
      }),
    ),
  );

  constructor(private actions$: Actions,
              private store$: Store<AppState>,
              private mfaService: MfaService) {
  }


}
