/**
 * Needs for select activity for specific controller like org activity or camera activity
 */

export enum ActivityLogMethodEnum {
  removeUserOrg,
  updateOrg,
  blockUserOrg,
  updateUserRoleOrg,
  updateLogoOrg,
  deleteOrg,
  leaveOrg,
  createOrg,
  inviteUserOrg,
  startLiveView,
  stopLiveView,
  startLiveViewScale,
  stopLiveViewScale,
  startPlayback,
  stopPlayback,
  downloadPlayback,
  closePlayback,
  userLogin,
  addEdge,
  deleteEdge,
  addCamera,
  addCameraManually,
  deleteCamera,
  peerLiveViewWebRTC,
  viewSharedVideo,
  apiKeyGenerate,
  deleteDevice,
  viewSharedAlert,
  searchAnalytic,
  editEdge,
  editCamera,
  deleteLocation,
  updateDevice,
  editLocation,
  addLocation,
  editEdgeConfig,
  uploadCameraAsset,
  restartEdge,
  probeCamera,
  probeFile,
  probeSubStream,
  deviceConnectivity,
  edgeNtp,
  updateEdgeLookup,
  insertCustomEvent,
  searchCustomEvent,
  updatePersonGroup,
  mergePersonGroup,
  createAlert,
  updateAlert,
  removeAlert,
  enableAlert,
  disableAlert,
  coreRecovery,
  addWall,
  updateWall,
  deleteWall,
  updateUserSettings,
  deleteArchive,
  downloadArchive,
  createDashboard,
  updateDashboard,
  removeDashboard,
  createPerson,
  updatePerson,
  deletePerson,
  createManualPerson,
  updateManualPerson,
  editCameraPTZ,
  startLocalStream,
  stopLocalStream,
  extendLocalStream,
  startStream,
  stopStream,
  extendStream,
  enableCamera,
  disableCamera,
  rebootStream,
  editCameraDetails,
  editCameraAnalytics,
  editCameraStorage,
  editCameraSearchSettings,
  editCameraAudioAndVideo,
  addAppleTv,
  deleteAppleTv,
  startLiveKit,
  stopLiveKit,
  configureAppleTv,
  getAppleTv,
  createUserDevice,
  editCameraHlsConfig,
  startLocalStreamMultiple,
  insertGeneaEvent,
  createApiKey,
  startLiveKitLiveView,
  removeParticipants,
  startHlsPlayback,
  stopHlsPlayback,
  getHlsPlaybackSessions,
  patchCameraMetadata,
  startLocalStreamThirdParty,
  searchSensorsData,
  searchSensorsDataLast,
  searchSensorsDataStats,
  updateSensor,
  removeSensor,
  updateCameraCoords,
  startWebrtcPlayback,
  stopWebrtcPlayback,
  createCustomEvent,
  updateCustomEvent,
  deleteCustomEvent,
  countCustomEvent,


}

export enum ActivityLogFilterName {
  events = 'events',
  users = 'users',
  orgs = 'orgs',
}
