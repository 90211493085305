import { Component, Input, OnInit } from '@angular/core';
import { catchError, filter, map, Observable, timeout } from 'rxjs';
import { AlertEntry } from '../../../../development/alerts.service';
import { select, Store } from '@ngrx/store';
import * as GrantedAccessSelectors from '@states/granted-access/granted-access.selectors';
import { AppState } from '../../../../store/app.state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CameraThumbnailsData, ThumbOptions } from '../../../../cameras/camera-thumbnails/camera-thumbnails.model';
import { MultiPlaybackData } from '@models/multi-playback.model';
import { MultiPlaybackActions } from '@states/multi-playback/multi-playback.action-types';
import { ThumbnailDialogData, ThumbnailDialogType } from '../../../../shared/thumbnail/thumbnail-dialog/thumbnail-dialog.component';
import { AnalyticClasses } from '@enums/alert-events.enum';
import { Search } from '../../../../shared/search.model';
import { CamerasThumbnailsService } from '../../../../cameras/camera-thumbnails/camera-thumnails.service';
import { KeyValuePairs } from '../../../../core/interfaces';
import { Archive, ArchiveStatus } from '@models/archive.model';
import ArchiveDocument = Archive.ArchiveDocument;
import * as LocationModel from '../../../../locations/location.model';
import { GrantedAccessService } from '../../../../development/granted-access.service';
import { ofType } from '@ngrx/effects';
import * as EdgeEditActions from '@states/edge-edit/edge-edit.actions';
import { EdgeEditEffects } from '@effects/edge-edit.effects';
import { StatsService } from '../../../../development/stats.service';
import { withLatestFrom } from 'rxjs/operators';
import { CameraActions } from '@states/camera/camera.action-types';
import { EdgeCamera } from '../../../../cameras/camera.model';
import { GrantedAccessActions } from '@states/granted-access/granted-access.action-types';

@UntilDestroy()
@Component({
  selector: 'app-shared-alert',
  templateUrl: './shared-alert.component.html',
  styleUrls: ['./shared-alert.component.scss'],
})
export class SharedAlertComponent implements OnInit {
  @Input() sharedArchive: ArchiveDocument;
  @Input() archiveUrl: string;
  @Input() accessToken: string;

  public selectSharedAlert$: Observable<AlertEntry> = this.store$.pipe(select(GrantedAccessSelectors.selectSharedAlert));
  public selectSelectedLocation$: Observable<LocationModel.LocationModel.LocationItem> = this.store$.pipe(select(GrantedAccessSelectors.selectSelectedLocation));
  public selectIsRecordRemoved$: Observable<boolean> = this.store$.pipe(select(GrantedAccessSelectors.selectIsRecordRemoved));

  public dialogData: ThumbnailDialogData;
  public archiveStatuses = ArchiveStatus;

  private edgeId: string;
  private cameraId: string;
  private events: KeyValuePairs<number[]> = {};
  public online: boolean = false;


  constructor(
    private store$: Store<AppState>,
    private cameraThumbnailsService: CamerasThumbnailsService,
    private grantedAccessService: GrantedAccessService,
    private editEdgeEffects: EdgeEditEffects,
    private statsService: StatsService,
  ) {
  }

  ngOnInit(): void {
    this.selectSharedAlert$
      .pipe(
        untilDestroyed(this),
        filter(alert => !!alert),
      )
      .subscribe(alert => {
        this.edgeId = alert.edgeId;
        this.cameraId = alert.cameraId;
        this.store$.dispatch(EdgeEditActions.setSelectedEdgeId({ selectedEdgeId: this.edgeId }));
        this.store$.dispatch(EdgeEditActions.getEdgeHeartBeatStatus());
        this.editEdgeEffects.getEdgeHeartBeatStatus$
          .pipe(
            untilDestroyed(this),
            ofType(EdgeEditActions.getEdgeHeartBeatStatusSuccess),
            map(res => res.body),
          )
          .subscribe(edgeStatus => {
            const cameraItem = edgeStatus.find(item => item.component === 9 && item.edgeId === alert.edgeId);
            this.online = cameraItem?.status === 0;
          });
        this.loadPlayback(alert);

      });


  }

  public openMail() {
    window.open(`mailto:sales@lumana.ai`);
  }

  public openLink(): void {
    window.open(this.archiveUrl, '_blank');
  }


  /**
   * WARNING SPAGHETTI
   */

  public loadPlayback(alert: AlertEntry): void {
    this.grantedAccessService.getLocationTimezoneByEdgeId(this.edgeId)
      .subscribe(async res => {
        const timezone = res.timezone;
        const startTime = this.normalizeTimestamp(alert.timestamp - 32000);
        const endTime = this.normalizeTimestamp(Math.min(alert.timestamp + 32000, new Date().getTime()));
        const options: ThumbOptions = {
          clipInSeconds: 7200,
          offsetResInDurations: 1,
          startTime,
          endTime,
          duration: 2000,
        };
        const thumbsData: CameraThumbnailsData = {
          edgeId: alert.edgeId,
          cameraId: alert.cameraId,
          timezone: timezone,
          offsetResInDurations: 1,
        };

        const multiPlaybackData: MultiPlaybackData = {
          base: startTime,
          duration: 2000,
          endTime,
          offsetResInDurations: 1,
          startTime,
          timezone: timezone,
        };
        this.store$.dispatch(MultiPlaybackActions.setData({ data: multiPlaybackData }));

        this.cameraThumbnailsService.setThumbnailsData(thumbsData);

        this.dialogData = {
          options,
          events: null,
          edgeId: alert.edgeId,
          cameraId: alert.cameraId,
          selectedCamera: alert.selectedCamera,
          objects: alert?.thumbnails?.map(thumb => {
            return {
              url: thumb,
              idIndex: alert.idIndex,
              idBase: alert.idBase,
              descriptor: false,
            };
          }),
          type: ThumbnailDialogType.ALERT,
          rtl: false,
          defaultThumb: alert.mainThumbnail,
          showObjects: false,
          seconds: true,
          allowTrack: alert.idBase && alert.idIndex && alert.type === AnalyticClasses.person,
          alertName: alert.alertMessage,
          name: alert.eventName,
        };

        console.log(this.dialogData);
      });
  }


  normalizeTimestamp(timestamp: number) {
    return this.cameraThumbnailsService.normalizeTimestamp(timestamp);
  }

  getBaseInLocale(date: Date) {
    return this.cameraThumbnailsService.getBaseInLocale(date);
  }


  bestImageUri(object: string) {
    return `https://d32bjbwejsjjzu.cloudfront.net/alerts/${this.edgeId}/${this.cameraId}/${object}`;
  }

}
