import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MsgBoxType } from '../../msg-box/msg-box.model';

@Component({
  selector: 'ui-alert',
  templateUrl: './ui-alert.component.html',
  styleUrls: ['./ui-alert.component.scss'],
})
export class UiAlertComponent {
  @Input() title: string;
  @Input() closable = false;
  @Input() type: MsgBoxType;
  @Input() public visible: boolean;
  @Output() closeAction: EventEmitter<void> = new EventEmitter<void>();
  public alertTypes = MsgBoxType;

  constructor() {}

  public close(): void {
    this.visible = false;
    this.closeAction.emit();
  }
}
