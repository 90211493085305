import { Injectable } from '@angular/core';
import { filter, take } from 'rxjs';
import { environment } from '../../environments/environment';
import { select, Store } from '@ngrx/store';
import { AppState } from '../store/app.state';
import { OrganizationsActions } from '@states/organization/organization.actions-types';
import { OrganizationSelectors } from '@states/organization/organization.selector-types';
import { EdgeActions } from '@states/edge/edge.action-types';
import { AuthenticationSelectors } from '@states/authentication/authentication.selector-types';
import { SocketBaseService } from './socket-base.service';

const STATUS_FETCH_INTERVAL = 5 * 60 * 1000;

@Injectable({
  providedIn: 'root',
})
export class SocketMainService extends SocketBaseService {

  statusInterval: any;

  constructor(
    private store$: Store<AppState>,
  ) {
    super();
  }

  override isValid(): boolean {
    return !!this.socket && !!this.socket.connected;
  }


  override connect(): void {
    this.store$.pipe(
      select(AuthenticationSelectors.idToken),
      take(1),
    )
      .subscribe(res => {
        super.connect(environment.socketUrls.main, res);
      });
  }

  override onError(): void {
    this.store$.dispatch(EdgeActions.GetEdgesInitialPulsationStatus());
    this.statusInterval = setInterval(() => {
      this.store$.dispatch(EdgeActions.GetEdgesInitialPulsationStatus());
    }, STATUS_FETCH_INTERVAL);

  }

  override onConnect(): void {
    this.store$
      .select(OrganizationSelectors.selectActiveOrganization)
      .pipe(
        filter(res => !!res?.orgId),
      )
      .subscribe(res => {
        this.store$.dispatch(OrganizationsActions.emitJoinActiveOrganizationEvent({
          activeOrganization: {
            orgId: res.orgId,
            orgName: res.orgName,
            orgEmail: res.orgEmail,
            orgColor: res.orgColor,
            roles: res.roles,
            ipRules: res.ipRules,
          },
        }));
      });
    clearInterval(this.statusInterval);
    this.store$.dispatch(EdgeActions.GetEdgesInitialPulsationStatus());
  };

  override reconnect(): void {
    this.connect();
  }

}
