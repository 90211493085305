import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { catchError, Observable, of, Subscription, take } from 'rxjs';
import { Quality, QualitySelectorComponent } from '../ui-kit/ui-quality-selector/quality-selector.component';
import { MatDialog } from '@angular/material/dialog';
import { UiAreaSelection } from '../ui-kit/ui-area-selector/ui-area-selector.component';
import { Point } from '@angular/cdk/drag-drop';
import { UtilsV2Service } from '../../services/utils-v2.service';
import { CamerasService } from '../../cameras/cameras.service';
import { select, Store } from '@ngrx/store';
import { PreloaderColor } from '@enums/shared.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as _ from 'lodash';
import { FadeOutAnimation } from '../../framework/animations';
import { ActiveOrganization, LiveViewType } from '@models/organization.model';
import * as OrganizationSelectors from '@states/organization/organization.selectors';
import Hls from 'hls.js';
import { QualitySelectorV2Component } from '../ui-kit/ui-quality-selector-v2/quality-selector-v2.component';
import { WallSelectors } from '@states/wall/wall.selector-types';
import { UiParticipantsDialogComponent, UiParticipantsDialogData } from '../ui-kit/ui-participants-dialog/ui-participants-dialog.component';
import { LiveStreamModels } from '@models/live-stream.model';
import { VideoService } from '../../development/video.service';
import StreamResolution = LiveStreamModels.StreamResolution;
import { VideoComponent } from '../video/video.component';
import { CameraSelectors } from '@states/camera/camera.selector-types';

const DEFAULT_EXTEND_TIME = 120000;
const HEALTHCHECK_INTERVAL_TIME = 10000;

export interface LocalPlayerState {
  sessionId?: string;
  ws_protocol: string;
  ws_hostname: string;
  ws_port: number;

  queue: any;
  startTimeSet: boolean;
  playbackOptionSequence: boolean;
  sourceBuffer: SourceBuffer;
  streamingStarted: boolean;
  videoStarted: boolean;
  webSocket;
  enableVerboseMsgLog: boolean;
  mimeCodec: string;
  ms?: MediaSource;
  lastBound?: number;
  errorCounter?: number;
  resolution?: LiveStreamModels.StreamResolution;
}

//todo move to models.
export interface WebrtcPlayerState {
  stream?: MediaStream;
  configuration?: RTCConfiguration;
  hq: boolean;
  pc?: any;
  relay?: boolean;
  sessionSubscription?: Subscription;
  incomingIce?: Partial<RTCIceCandidate>[];
  receivedOffer?: boolean;
  sessionId?: string;
}

interface ZoomState {
  scale: number;
  initX: number;
  initY: number;
  moveX: number;
  moveY: number;
  boundryX: number;
  boundryY: number;
  dragging: boolean;
}

@UntilDestroy()
@Component({
  selector: 'app-webrtc-v2',
  templateUrl: './webrtc-v2.component.html',
  styleUrls: ['./webrtc-v2.component.scss'],
  animations: [FadeOutAnimation],
})
export class WebrtcV2Component implements OnInit, OnDestroy {

  public LiveViewType = LiveViewType;
  public selectHasSubstream$: Observable<boolean>;

  @ViewChild('wrapper') wrapper: ElementRef;
  @ViewChild('preview') previewWrapper: ElementRef;
  @ViewChild('previewCanvas') previewCanvas: ElementRef;
  @ViewChild('placeholder') placeholder: ElementRef;
  @ViewChild('placeholderCanvas') placeholderCanvas: ElementRef;
  @ViewChild('qualitySelector') qualitySelector: QualitySelectorComponent;
  @ViewChild('resolutionSelector') resolutionSelector: QualitySelectorV2Component;
  @ViewChild('videoWrapper') videoWrapper: ElementRef;
  @ViewChild('video') videoComponent: VideoComponent;

  @Output() onStreamError = new EventEmitter(null);
  @Output() resetError = new EventEmitter(null);
  @Output() playing: EventEmitter<string> = new EventEmitter<string>();
  @Output() isRelay: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() pause: EventEmitter<void> = new EventEmitter<void>();
  @Output() clickFullScreen = new EventEmitter<void>();
  @Output() clickTakeSnapshot = new EventEmitter<void>();

  @Input() public isRespectRatio: boolean = true;
  @Input() public cameraId;
  @Input() public edgeId;
  @Input() public locationId;
  @Input() public showZoomButtons = true;
  @Input() public allowZoom = true;
  @Input() public autostart = false;
  @Input() public forceRelay = false;
  @Input() public enableQualitySelection = true;
  @Input() public accessToken: string;
  @Input() public offline: boolean = false;
  @Input() public cameraName: string;
  /**
   * Show/hide made responsive some elements on tile to make better UX in small tile
   */
  @Input() public isSmallTile: boolean = false;

  @Input() public playback = false;
  @Input() public liveView = false;
  @Input() public playbackTS: number;
  @Input() public playbackSeek = false;
  @Input() public duration: number;
  @Input() public disableExtend = false;
  @Input() public enableHealthCheck = true;
  @Input() public isExternallyManaged = false;
  @Input() public cameraView = false;
  @Input() public zoomPreview = true;
  @Input() public hideQuality = false;
  @Input() public isWall = false;
  @Input() public showControls = true;

  public get playerObj(): ElementRef {
    if (!this.videoComponent?.playerObj) {
      console.log('videoComponent undefined');
    }
    return this.videoComponent?.playerObj;
  }

  public pauseTs: number;

  public selectActiveOrganization$: Observable<ActiveOrganization> = this.store$.pipe(
    select(OrganizationSelectors.selectActiveOrganization),
  );

  public isDeveloper$ = this.store$.pipe(select(OrganizationSelectors.isDeveloper));
  public isTileSelected$ = this.store$.pipe(select(WallSelectors.selectedIsTileSelected));

  public preloaderColor = PreloaderColor;
  public inactive = false;

  public zoomState: ZoomState = {
    scale: 1,
    initX: 0,
    initY: 0,
    moveX: 0,
    moveY: 0,
    boundryX: 0,
    boundryY: 0,
    dragging: false,
  };

  // Additional Flags and declarations
  origHeight = 0;
  origWidth = 0;
  ratio = 0;

  ctx: CanvasRenderingContext2D;
  placeholderCtx;

  public loader = true;

  recover = true;
  qualityChange = false;

  public disableInactivity = true;
  @Input() public developer = false;

  public isPlaceholder = true;

  placeholderInterval;

  public counters = {
    start: 0,
    stop: 0,
    sessionGenerate: 0,
  };

  public health = {
    prevTime: 0,
    interval: null,
  };

  @Output() noMoreFiles: EventEmitter<void> = new EventEmitter<void>();

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.origHeight = this.playerObj?.nativeElement.clientHeight;
    this.origWidth = this.origHeight * this.ratio;
    if (this.zoomState.scale > 1 && !!this.previewCanvas) {
      this.previewCanvas.nativeElement.width = this.origWidth * 0.25;
      this.previewCanvas.nativeElement.height = this.origHeight * 0.25;
    }
    // this.setPlaceholder(true);
    this.cd.detectChanges();
  }

  @HostListener('document:fullscreenchange', []) fullscreenChanged() {
    this.cd.detectChanges();
    this.placeholderCanvas.nativeElement.height = 100;
  }

  private isViewInitCompleted = false;
  private pendingChanges: SimpleChanges[] = [];

  private localStateInitialValue: LocalPlayerState = {

    ws_protocol: 'wss',
    ws_hostname: '192.168.100.116',
    ws_port: null,

    queue: [],
    startTimeSet: false,
    playbackOptionSequence: false,
    sourceBuffer: null,
    streamingStarted: false,
    videoStarted: false,
    webSocket: null,
    enableVerboseMsgLog: false,
    mimeCodec: '',
    ms: null,
    lastBound: null,
    errorCounter: 0,
    resolution: LiveStreamModels.StreamResolution.HQ,
  };

  public localState: LocalPlayerState = _.cloneDeep(this.localStateInitialValue);

  public isLocal = false;
  public localUrl = '';

  private windowHidden = false;
  private hideTs: number;
  private showTs: number;

  public localLiveStreamEnabled = false;
  public recoverCount = 0;
  public errorMsg: string;

  public first = true;

  public liveViewType: LiveViewType = LiveViewType.Webrtc;

  private hls: Hls | null = null;
  private wallCameraNum = 0;
  private userPause = false;
  private paused = false;

  private hlsForceSQ = false;

  private hlsPlaybackTs: number;
  public hlsPlaybackDuration: number;
  public hlsPlaybackSessionId: string;

  private extendInterval;

  public resolution = LiveStreamModels.StreamResolution.HQ;

  public showRetry = false;

  private stopTimeout;


  constructor(
    private renderer: Renderer2,
    private cd: ChangeDetectorRef,
    private dialog: MatDialog,
    private utilsV2Service: UtilsV2Service,
    private camerasService: CamerasService,
    private store$: Store,
    private elementRef: ElementRef,
    private videoService: VideoService,
  ) {
  }

  public get video() {
    return this.videoComponent?.player?.video;
  }

  public get playbackErrors() {
    return this.videoComponent?.player?.playbackErrors;
  }

  public get started() {
    return this.videoComponent?.player?.started;
  }

  public get currentTime(): number {
    return this.playback && !!this.hls ? 0 : this.video?.currentTime;
  }

  public getPreviewCanvas() {
    return this.previewCanvas;
  }

  public get isLiveKit() {
    return this.videoComponent?.liveViewType === LiveViewType.Livekit;
  }

  async ngOnInit(): Promise<void> {
    this.videoService.pause$.pipe(untilDestroyed(this))
      .subscribe(() => {
        this.pauseVideo();
      });
  }

  async ngAfterViewInit(): Promise<void> {
    const video = this.playerObj?.nativeElement;
    this.placeholderCtx = this.placeholderCanvas.nativeElement.getContext('2d');

    if (!this.cameraView) {


      this.selectHasSubstream$ = this.store$.pipe(select(CameraSelectors.selectCameraHasSubstreamsById(this.cameraId)));


      this.onResize();
      this.isViewInitCompleted = true;

      // Execute any accumulated changes
      for(const changes of this.pendingChanges) {
        this.executeChanges(changes);
      }
      // Clear the accumulated changes after processing
      this.pendingChanges = [];
    } else {

      if (this.autostart) {
        this.play();
      }
    }
    if (this.allowZoom) {
      if (this.zoomPreview) {
        this.ctx = this.previewCanvas?.nativeElement?.getContext('2d');
        video?.addEventListener('play', () => {
          this.timerCallback();
        });
      }
    }
    this.isViewInitCompleted = true;
  }

  public async executeChanges(changes: SimpleChanges) {
    if (this.isExternallyManaged) {
      this.isExternallyManaged = false;
    }
    if (changes['cameraId'] && this.autostart) {
      this.play();
    }
  }

  public async playbackStart(ts: number) {
    if (!ts) {
      console.log('Received null ts 897');
      return;
    }
    this.counters.start++;
    // console.log(`[WEBRTC-START] cameraId: ${this.cameraId}, sessionId: ${this.sessionId}`);
    const isHlsPlayback = await this.isHlsPlayback(ts);
    if (isHlsPlayback) {

      const end = ts + 5 * 60 * 1000;

      ts = Math.floor(ts);
      const request: LiveStreamModels.StartHlsPlaybackRequest = {
        cameraId: this.cameraId,
        edgeId: this.edgeId,
        locationId: this.locationId,
        smartStorage: false,
        start: ts,
        end: end < Date.now() ? end : 0,
      };

      if (this.hlsPlaybackSessionId) {
        request.sessionId = this.hlsPlaybackSessionId;
      }

      this.loader = true;
      this.camerasService.startHlsPlayback(request)
        .subscribe((res) => {
          const connectionData: LiveStreamModels.ConnectionData = {
            cameraStatus: true,
            url: res.url,
            resolution: StreamResolution.AUTO,
          };
          // this.setupHls(connectionData);
          this.hlsPlaybackSessionId = res.sessionId;
          console.log('start playback response', res);
          this.hlsPlaybackTs = ts;
        });
      return;
    }
  }


  public async seek(ts: number) {
    console.log('[SEEK]');
    const isHlsPlayback = await this.isHlsPlayback(ts);
    if (isHlsPlayback) {
      if (!!this.hlsPlaybackSessionId) {
        this.video.pause();
        const lastFiveMinutes = Date.now() - 5 * 60 * 1000;
        if (ts > this.hlsPlaybackTs && ts < this.hlsPlaybackTs + this.hlsPlaybackDuration * 1000 || this.hlsPlaybackTs > lastFiveMinutes && ts > lastFiveMinutes) {
          console.log('Seeking to ', new Date(ts));
          const video = this.playerObj?.nativeElement;
          const seekTime = (ts - this.hlsPlaybackTs) / 1000; // Convert milliseconds to seconds
          video.currentTime = seekTime; // Use the correct property
          this.health.prevTime = 0;
          this.playVideo();
        } else {
          await this.stop();
          if (!ts) {
            console.log('Received null ts 1090');
            return;
          }
          this.playbackStart(ts);
        }
      } else {
        if (!ts) {
          console.log('Received null ts 1096');
          return;
        }
        this.playbackStart(ts);
      }
    } else {
      if (this.hlsPlaybackSessionId) {
        delete this.hlsPlaybackSessionId;
      }

    }
  }

  public async stop(inactive?: boolean, placeholder = true, resetErrors = true) {
    console.log('[STOP]');
    if (placeholder) {
      this.setPlaceholder();
    }

    // if (this.isLiveKit) {
    //   this.liveKitDisconnect();
    //   delete this.liveKitSession;
    // }

    // Clear health check if enabled
    if (this.enableHealthCheck) {
      this.clearHealthCheck();
    }
    // Clear extend interval if enabled
    if (this.extendInterval) {
      clearInterval(this.extendInterval);
    }

    this.video?.removeEventListener('loadeddata', this.onVideoLoad);

    this.loader = false;
    this.counters.stop++;

    if (!this.playback) {
      /** Stop live view **/
      /* If it is a local live stream - stop local live stream */
      if (this.localLiveStreamEnabled && this.isLocal) {
        if (this.hls) {
          this.hls.removeAllListeners();
          this.hls.destroy();
          this.hls = null;
        }
        if (this.localState.sessionId) {
          this.localState.webSocket?.close();
          this.localState = _.cloneDeep(this.localStateInitialValue);
        }
        return;
      }
      /* Non local */
      switch (this.liveViewType) {
        case LiveViewType.Livekit:
          return;
      }
      this.camerasService.stopLiveView(this.edgeId, this.locationId, this.cameraId)
        .subscribe();
    } else {
      if (this.hls) {
        this.hls.removeAllListeners();
        this.hls.destroy();
        this.hls = null;
        delete this.hlsPlaybackTs;
        delete this.hlsPlaybackDuration;
      }
    }

    // console.log(`==> [WEBRTC-STOP] cameraId: ${this.cameraId}, sessionId: ${this.sessionId}`);

    // MEDIASOUP - deprecated
    // if (!this.playback && this.mediasoup) {
    //   this.mediasoupService.cleanup(this.mediasoupSession);
    // }
  }

  setPlaceholder(resize = false, playerObj?: ElementRef) {
    const ratio = this.video?.videoWidth / this.video?.videoHeight;
    const placeholderElem = this.placeholder?.nativeElement;

    if (!placeholderElem) {
      return;
    }

    placeholderElem.style.height = `100%`;
    const width = this.isRespectRatio ? placeholderElem.clientHeight * ratio + 'px' : `100%`;
    placeholderElem.style.width = width;

    this.placeholderCanvas.nativeElement.width = placeholderElem.clientWidth;
    this.placeholderCanvas.nativeElement.height = placeholderElem.clientHeight;
    if (!resize) {
      this.placeholderCtx.drawImage(this.playerObj.nativeElement, 0, 0, placeholderElem.clientWidth, placeholderElem.clientHeight);
    }
    this.isPlaceholder = true;
  }


  changeResolution(resolution: LiveStreamModels.StreamResolution) {
    return this.videoComponent?.player?.changeResolution(resolution);
  }

  changeQuality(resolution: LiveStreamModels.StreamResolution) {
    const quality = resolution === LiveStreamModels.StreamResolution.HQ ? Quality.HQ : Quality.SQ;
    this.qualityChange = true;
    this.loader = true;
    // set placeholder size to video size
    this.setPlaceholder();
    this.resetZoom();
    this.stop();
    if (quality === Quality.SQ) {
      this.resolution = StreamResolution.SQ;
    } else {
      this.resolution = StreamResolution.HQ;
    }
    this.play();
  }


  public async fallbackToSQ() {
    this.hlsForceSQ = true;
    if (this.hls) {
      this.hls.destroy();
      this.hls = null;
    }
    this.stop();
    this.play();
  }

  public async fallBackToWebrtc(changeType = false) {
    if (this.liveViewType !== LiveViewType.Webrtc && changeType) {
      await this.liveViewTypeChange(LiveViewType.Webrtc);
    }
    this.loader = false;
    this.isLocal = false;
    this.localState.webSocket?.close();
    const resolution = this.resolution;
    this.localState = _.cloneDeep(this.localStateInitialValue);
    this.resolution = resolution;
    this.play();
  }


  public async isHlsPlayback(ts?: number) {
    // [TODO] check if TS is hls playback
    return true;
  }

  public async play(data?: { ts?: number, start?: number, end?: number }) {
    if (document.hidden) {
      return;
    }
    this.videoService.play();

    return;

    console.log(`[PLAY]`, data);
    // Reset errors
    this.recover = true;
    delete this.errorMsg;
    if (data?.ts) {
      this.playback = true;
    }

    if (!this.playback) {
      /** Live view **/
      /* If local live stream is enabled and this is a local camera */
      if (this.localLiveStreamEnabled && this.isLocal) {
        if (!this.locationId) {
          return;
        }
        return;
      }

      switch (this.liveViewType) {
        case LiveViewType.Livekit:
          break;
        case LiveViewType.Webrtc:

          break;
      }
    } else {
      /** Playback **/
      /* Otherwise - WebRTC / HLS playback */

      // Reset user pause
      this.userPause = false;
      this.paused = false;

      const isHlsPlayback = await this.isHlsPlayback(data?.ts);
      if (isHlsPlayback) {
        if (!data?.ts) {
          console.log('Received null ts 1707');
          return;
        }
        if (this.playbackSeek && this.hlsPlaybackSessionId && !this.paused && !this.liveView) {
          await this.seek(data?.ts);
          return;
        }

        return this.playbackStart(data?.ts);
      }

    }


  }

  public zoomIn() {
    this.zoom({ deltaY: -1 });
    if (this.zoomPreview) {
      this.computeFrame();
    }
  }

  public zoomOut() {
    this.zoom({ deltaY: 1 });
  }

  public zoomArea(area: UiAreaSelection) {
    if (!area?.start?.x || !area?.start?.y || !area?.end?.x || !area?.end?.y) {
      return;
    }
    this.initZoom();
    const wrapper = this.wrapper.nativeElement;
    const topLeft: Point = {
      x: Math.min(area.start.x, area.end.x),
      y: Math.min(area.start.y, area.end.y),
    };
    const width = Math.abs(area.start.x - area.end.x);
    const height = this.ratio * width;
    if (width <= 0 || height <= 0) {
      return;
    }
    this.zoomState.scale = wrapper.clientWidth / width;
    const elem = this.playerObj?.nativeElement;

    this.renderer.setStyle(elem, 'transform', `scale(${this.zoomState.scale})`);
    if (this.zoomState.scale !== 1) {
      this.calcBoundaries();
    }
    this.zoomState.moveX = this.zoomState.boundryX - topLeft.x;
    this.zoomState.moveY = this.zoomState.boundryY - topLeft.y;

    this.zoom(undefined, true);
  }

  calcBoundaries() {
    const elem = this.playerObj?.nativeElement;
    const height = this.origHeight * this.zoomState.scale;
    const width = this.origWidth * this.zoomState.scale;
    this.zoomState.boundryX = width >= elem.clientWidth ? (width - elem.clientWidth) / 2 / this.zoomState.scale : 0;
    this.zoomState.boundryY = (height - elem.clientHeight) / 2 / this.zoomState.scale;
  }

  public initZoom() {
    const elem = this.playerObj?.nativeElement;
    this.ratio = elem.videoWidth / elem.videoHeight;
    this.origHeight = elem.clientHeight;
    this.origWidth = this.origHeight * this.ratio;
  }

  public zoom(event, renderOnly = false) {
    if (event?.type === 'mousewheel') {
      event.stopPropagation();
      event.preventDefault();
    }
    if (!this.allowZoom) {
      return;
    }
    if (!this.ratio) {
      this.initZoom();
    }
    const elem = this.playerObj?.nativeElement;
    this.renderer.setStyle(elem, 'transition', `0.3s`);
    if (!renderOnly) {
      if (event.deltaY < 0) {
        if (this.zoomState.scale < 5) {
          this.zoomState.scale += 0.2;
        } else {
          this.zoomState.scale = 5;
        }
        this.onResize();
        this.cd.detectChanges();
      } else {
        if (this.zoomState.scale >= 1.2) {
          this.zoomState.scale -= 0.2;
        } else {
          this.zoomState.scale = 1;
        }
      }
    }
    if (this.zoomState.scale === 1) {
      this.zoomState.moveX = 0;
      this.zoomState.moveY = 0;
      this.zoomState.boundryX = 0;
      this.zoomState.boundryY = 0;
    }

    this.renderer.setStyle(elem, 'transform', `scale(${this.zoomState.scale}) translate(${this.zoomState.moveX}px, ${this.zoomState.moveY}px)`);
    if (this.zoomState.scale !== 1) {
      this.calcBoundaries();
    }
    const moveXDir = Math.sign(this.zoomState.moveX);
    const moveYDir = Math.sign(this.zoomState.moveY);
    this.zoomState.moveX = Math.abs(this.zoomState.moveX) > this.zoomState.boundryX ? this.zoomState.boundryX * moveXDir : this.zoomState.moveX;
    this.zoomState.moveY = Math.abs(this.zoomState.moveY) > this.zoomState.boundryY ? this.zoomState.boundryY * moveYDir : this.zoomState.moveY;
    this.renderer.setStyle(elem, 'transform', `scale(${this.zoomState.scale}) translate(${this.zoomState.moveX}px, ${this.zoomState.moveY}px)`);

    // this.renderer.setStyle(elem, 'transition', `none`);

  }

  dragStart(event: MouseEvent) {
    this.zoomState.dragging = true;
    const elem = this.playerObj?.nativeElement;
    this.renderer.setStyle(elem, 'transition', `none`);
    this.zoomState.initX = event.clientX - this.zoomState.moveX;
    this.zoomState.initY = event.clientY - this.zoomState.moveY;

  }

  drag(event: MouseEvent) {
    if (this.zoomState.scale === 1 || !this.zoomState.dragging || (event.movementX === 0 && event.movementY === 0)) {
      return;
    }
    const elem = this.playerObj?.nativeElement;
    const rect = elem.getClientRects('2d')[0];

    const ratio = elem.videoWidth / elem.videoHeight;
    this.zoomState.moveX += Math.abs(this.zoomState.moveX + event.movementX) >= this.zoomState.boundryX ? 0 : event.movementX;
    this.zoomState.moveY += Math.abs(this.zoomState.moveY + event.movementY) >= this.zoomState.boundryY ? 0 : event.movementY;
    this.renderer.setStyle(elem, 'transform', `scale(${this.zoomState.scale}) translate(${this.zoomState.moveX}px, ${this.zoomState.moveY}px)`);
  }

  timerCallback() {
    if (this.playerObj?.nativeElement.paused || this.playerObj?.nativeElement.ended) {
      return;
    }
    this.computeFrame();
    setTimeout(() => {
      this.timerCallback();
    }, 1000);
  }

  computeFrame() {
    this.ctx.drawImage(this.playerObj?.nativeElement, 0, 0, this.previewCanvas?.nativeElement.width, this.previewCanvas?.nativeElement.height);
  }

  public inZoom() {
    return this.zoomState.scale > 1;
  }

  public resetZoom() {
    this.zoomState.scale = 1;
    this.zoom(undefined, true);
  }


  private playVideo() {
    const video = this.video;
    const TIMEOUT_DURATION = 5000;
    let canPlayTimeout: any;


    // Function to handle video play
    const handlePlay = () => {
      clearTimeout(canPlayTimeout);
      video.play()
        .then(() => {
          this.loader = false;
          this.cd.detectChanges();
          this.playing.emit();
          this.isPlaceholder = false;
        })
        .catch(error => {
          console.error('Error occurred while trying to play the video:', error);
        });
    };

    // Check if the video is already playing
    if (!video.paused) {
      console.log('Video is already playing.');
      this.loader = false;
      this.cd.detectChanges();
      this.playing.emit();
      this.isPlaceholder = false;
      return;
    }

    // Check if the video is already ready to play
    if (video.readyState >= video.HAVE_FUTURE_DATA) {
      handlePlay();
    } else {
      // Add an event listener to ensure the video is ready to play
      video.addEventListener('canplay', handlePlay, { once: true });

      // Set a timeout to fall back to WebRTC if canplay is not triggered
      canPlayTimeout = setTimeout(() => {
        video.removeEventListener('canplay', handlePlay);
        if (this.isLocal) {
          this.fallbackToSQ();
        } else {
          this.fallBackToWebrtc();
        }
      }, TIMEOUT_DURATION);
    }
  }

  private onVideoLoad = () => {
    this.playVideo();
  };

  async retry() {
    this.recoverCount = 0;
    this.resetError.emit();
    this.showRetry = false;
    this.play();
  }


  public takeSnapshot(cameraName: string, locationName: string, ts?: number) {
    this.utilsV2Service.takeCameraSnapshot(cameraName, locationName, this.playerObj, ts);
  }

  public maximize() {
    const video = this.video as any;
    if (!!video.requestFullscreen) {
      video.requestFullscreen();
    } else if (!!video.mozRequestFullScreen) {
      video.mozRequestFullScreen();
    } else if (!!video.webkitRequestFullscreen) {
      video.webkitRequestFullscreen();
    }
  }

  public async ngOnDestroy(): Promise<void> {
    if (this.isLiveKit) {
      this.stop();
    }

    if (this.hls) {
      this.hls.destroy();
      this.hls;
      this.hls = null;
    }


    if (this.placeholderInterval) {
      clearInterval(this.placeholderInterval);
    }
    if (this.enableHealthCheck) {
      this.clearHealthCheck();
    }


  }

  public get isPlaying(): boolean {
    const video = this.video;
    return this.inactive || !!(video && video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2) || (this.localState.videoStarted || this.started) && this.isLocal;
  }

  public cameraSnapshot(cameraId: string): Observable<any> {
    return this.camerasService.getCameraSnapshot(cameraId);
  }

  public healthCheck() {
    if (this.userPause) {
      return;
    }

    if (this.health?.prevTime >= this.videoService.ts) {
      console.log('[WEBRTC] health check failed');
      this.clearHealthCheck();
    }
    this.health.prevTime = this.videoService.ts;
  }

  public startHealthCheck() {
    if (this.health.interval) {
      clearInterval(this.health.interval);
    }
    this.health.prevTime = this.video?.currentTime;
    this.health.interval = setInterval(() => {
      this.healthCheck();
    }, HEALTHCHECK_INTERVAL_TIME);
  }

  public clearHealthCheck() {
    if (this.health?.interval) {
      clearInterval(this.health?.interval);
    }

    this.health = {
      prevTime: 0,
      interval: null,
    };
  }

  public async liveViewTypeChange(liveViewType: LiveViewType) {
    await this.stop();
    this.liveViewType = liveViewType;

    this.play();
  }

  public async pauseVideo(user = false, paused = false, currentTs?: number) {
    this.clearHealthCheck();
    this.paused = paused;
    this.userPause = user;
    this.pauseTs = currentTs;
  }

  public showParticipants(isDeveloper: boolean) {
    const data: UiParticipantsDialogData = {
      edgeId: this.edgeId,
      cameraId: this.cameraId,
      resolution: this.resolution,
      showPublisher: isDeveloper,
    };
    this.dialog.open(UiParticipantsDialogComponent, {
      panelClass: 'modal-no-padding',
      data,
    });
  }

  // Event handlers
  public onShowRetry(event: any) {
    this.showRetry = true;
  }

  public onPlaying(event: any) {
    this.playing.emit(event);
  }

  public onQualityChange(event: any) {
    this.qualityChange = event;
  }

  public onSetPlaceholder(data: { set: boolean, playerObj?: ElementRef }) {
    const { set, playerObj } = data;
    if (set) {
      this.setPlaceholder(false, playerObj);
    } else {
      this.isPlaceholder = false;
    }
  }

  public onSetErrorMsg(errorMsg: string) {
    this.errorMsg = errorMsg;
  }

  public onSetLoader(loader: boolean) {
    this.loader = loader;
  }

  public onSetInactive(inactive: boolean) {
    this.inactive = inactive;
  }

}
