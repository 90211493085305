import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { MfaModel } from '@models/mfa.model';
import { MfaActions } from '@states/mfa/mfa.action-types';
import { createMfaSuccess, removeUserMfaMethod, removeUserMfaMethodFail } from '@states/mfa/mfa.actions';


export interface UserMfaState extends EntityState<MfaModel.Document> {
  isInitialLoaded: boolean;
  removeEnabled: boolean;
  removeEnabledMsg: string;
  qr: string;
  deletingIds: string[];
  manualCode: string;
}

export const adapter: EntityAdapter<MfaModel.Document> = createEntityAdapter<MfaModel.Document>({
  selectId: (document: MfaModel.Document) => document.id,
});


const initialState: UserMfaState = adapter.getInitialState({
  isInitialLoaded: false,
  removeEnabled: false,
  removeEnabledMsg: null,
  qr: null,
  deletingIds: [],
  manualCode: null,
});

export const userMfaStateReducer = createReducer(
  initialState,
  on(MfaActions.resetToInitialState, state => {
    return {
      ...initialState,
    };
  }),
  on(MfaActions.getUserMfaMethodsSuccess, (state, { documents }) => {
    return adapter.setAll(documents, {
      ...state,
      isInitialLoaded: true,
    });
  }),
  on(MfaActions.getUserMfaMethodsFail, (state) => {
    return {
      ...state,
      isInitialLoaded: true,
    };
  }),
  on(MfaActions.checkUserMfaEnabledSuccess, (state, { enabled, msg }) => {
    return {
      ...state,
      removeEnabled: enabled,
      removeEnabledMsg: msg,
    };
  }),
  on(MfaActions.createMfa, (state) => {
    return {
      ...state,
      qr: initialState.qr,
      manualCode: initialState.manualCode,
    };
  }),
  on(MfaActions.createMfaSuccess, (state, { qr, mfa, code }) => {
    return adapter.setOne(mfa, {
      ...state,
      qr: qr,
      manualCode: code,
    });
  }),
  on(MfaActions.removeUserMfaMethod, (state, { id }) => {
    return {
      ...state,
      deletingIds: [...state.deletingIds, id],
    };
  }),
  on(MfaActions.removeUserMfaMethodSuccess, (state, { id }) => {
    const deletingIndex = state.deletingIds.findIndex(_id => _id === id);
    let newDeletingArray = [...state.deletingIds];
    newDeletingArray.splice(deletingIndex, 1);
    return adapter.removeOne(id, {
      ...state,
      newDeletingArray,
    });
  }),
  on(MfaActions.removeUserMfaMethodFail, (state, { id }) => {
    const deletingIndex = state.deletingIds.findIndex(_id => _id === id);
    let newDeletingArray = [...state.deletingIds];
    newDeletingArray.splice(deletingIndex, 1);
    return {
      ...state,
      deletingIds: newDeletingArray,
    };
  }),
);
