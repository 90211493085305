import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, Observable, of, Subject, switchMap, take } from 'rxjs';
import { environment } from '../../environments/environment';
import { select, Store } from '@ngrx/store';
import { AppState } from '../store/app.state';
import { io } from 'socket.io-client';
import { OrganizationSelectors } from '@states/organization/organization.selector-types';
import { AuthenticationSelectors } from '@states/authentication/authentication.selector-types';
import { SocketBaseService } from './socket-base.service';

@Injectable({
  providedIn: 'root',
})
export class SocketEdgePlaybackService extends SocketBaseService {

  public connectionSubject = new BehaviorSubject<boolean>(false);
  private sessionCount = 0;

  statusInterval: any;

  constructor(
    private store$: Store<AppState>,
  ) {
    super();
  }

  override isValid(): boolean {
    return !!this.socket && !!this.socket.connected;
  }

  override consume<T>(eventName: string): Observable<T> {
    this.sessionCount++;
    return super.consume<T>(eventName);
  }

  override connect(): void {
    if (this.sessionCount > 0) {
      return;
    }
    this.store$.pipe(
        select(AuthenticationSelectors.idToken),
        take(1),
      )
      .subscribe(res => {
        super.connect(environment.socketUrls.edgePlayback, res);
      });
  }

  override onError(): void {
  }

  override onConnect(): void {
    this.store$
      .select(OrganizationSelectors.selectActiveOrganization)
      .pipe(
        filter(res => !!res?.orgId),
      )
      .subscribe(res => {
        console.log('edge playback socket connected');
        this.connectionSubject.next(true);
      });

  };

  override reconnect(): void {
    this.connect();
  }

  override disconnect() {
    if (this.sessionCount > 0) {
      this.sessionCount--;
      if (this.sessionCount === 0) {
        super.disconnect();
        this.connectionSubject.next(false);
      }
    }
  }
}
