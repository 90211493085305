import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LiveStreamModels } from '@models/live-stream.model';

@Component({
  selector: 'app-camera-controls',
  templateUrl: './camera-controls.component.html',
  styleUrl: './camera-controls.component.scss',
})
export class CameraControlsComponent implements OnInit {

  @Input() isDeveloper = false;
  @Input() isLiveKit = false;
  @Input() resolution: LiveStreamModels.StreamResolution = LiveStreamModels.StreamResolution.HQ;

  @Output() zoomIn = new EventEmitter<void>();
  @Output() zoomOut = new EventEmitter<void>();
  @Output() zoomReset = new EventEmitter<void>();
  @Output() showParticipants = new EventEmitter<void>();
  @Output() resolutionChanged = new EventEmitter<LiveStreamModels.StreamResolution>();
  @Output() takeSnapshot = new EventEmitter<void>();
  @Output() fullScreen = new EventEmitter<void>();


  constructor() {
  }

  ngOnInit(): void {
  }

  _takeSnapshot() {
    console.log('Take snapshot');
    this.takeSnapshot.emit();
  }

}
