import { Injectable } from '@angular/core';
import { HttpService } from '../../core/http.service';
import { environment } from '../../../environments/environment';
import {
  BehaviorSubject, catchError,
  delayWhen,
  filter,
  interval, lastValueFrom,
  map,
  Observable,
  retryWhen,
  startWith,
  Subject,
  switchMap,
  take,
  tap,
  timeout,
  timer,
} from 'rxjs';
import { CameraThumbnailsData, ThumbnailEntity } from './camera-thumbnails.model';
import * as moment from 'moment-timezone';
import { DateTime } from 'luxon';
import { Search } from '../../shared/search.model';
import SearchCamera = Search.SearchCamera;
import { ThumbnailModel } from '@models/thumbnail.model';
import { ThumbnailsSelectors } from '@states/thumbnails/thumbnails.selector-types';
import { select, Store } from '@ngrx/store';
import { ThumbnailsActions } from '@states/thumbnails/thumbnails.action-types';
import { KeyValuePairs } from '../../core/interfaces';
import { SnapshotEntry } from '../../development/thumbnails.service';
import { MediaCacheService } from '../../shared/media-cache/media-cache.service';
import _ from 'lodash';

const FREQUENCY = 2000;
const BITS_IN_VALUE = 4;
const HOURS = 24;
const INT_SIZE_IN_BITS = 32;
const TOTAL_NUMBER_OF_BITS_PER_HOURS = (BITS_IN_VALUE * HOURS * 60 * 60 * 1000) / FREQUENCY;
const ARRAY_LENGTH = Math.ceil(TOTAL_NUMBER_OF_BITS_PER_HOURS / INT_SIZE_IN_BITS);

@Injectable({
  providedIn: 'root',
})
export class CamerasThumbnailsService {
  refreshSubject = new Subject();
  refresh$: Observable<unknown> = this.refreshSubject.asObservable();

  closeDialogSubject = new Subject();
  closeDialog$: Observable<unknown> = this.closeDialogSubject.asObservable();

  // TODO: Remove default
  thumbnailsDataSubject = new BehaviorSubject<CameraThumbnailsData | undefined>(undefined);
  thumbnailsData$: Observable<CameraThumbnailsData | undefined> = this.thumbnailsDataSubject
    .asObservable()
    .pipe(filter(location => !!location));

  constructor(private httpService: HttpService, private store$: Store) {

  }

  setThumbnailsData(data: CameraThumbnailsData) {
    this.thumbnailsDataSubject.next(data);
  }

  refreshThumbnails() {
    this.refreshSubject.next(null);
  }

  getLastSnapshots(cameraId: string) {
    let url = `${environment.thumbnailsV2}/thumbnails/v2/last`;
    return this.httpService.http.post<{
      bestThumbnails: string[]
    }>(url, { cameraId });
  }

  getLastAllSnapshots() {
    let url = `${environment.thumbnailsV2}/thumbnails/v2/last/all`;
    return this.httpService.http.post<SnapshotEntry[]>(url, {});
  }

  getThumbnailsByRange(start: number | string, end: number | string, cameras: SearchCamera[], page?: number, size?: number): Observable<ThumbnailModel.ThumbnailDocument[]> {
    const baseStart = this.getBaseInLocale(new Date(start));
    const baseEnd = this.getBaseInLocale(new Date(end)) + 24 * 60 * 60 * 1000;
    let url = `${environment.thumbnailsV2}/thumbnails/v2/range/${baseStart}/${baseEnd}`;
    if (page && size) {
      url += `?page=${page}&size=${size}`;
    }
    return this.httpService.http.post<{
        count: number;
        result: ThumbnailModel.ThumbnailDocument[]
      }>(url, { cameras }, {
        params: {
          sharedToken: true,
        },
      })
      .pipe(
        map(res => {
            return res.result.map(item => {
              return {
                ...item,
                cacheId: `${item.edgeId}:${item.cameraId}:${item.base}`,
              };
            });
          },
          catchError(err => {
            console.log(`failed to fetch range ${start} - ${end}`);
            return [];
          })),
        tap(res => {
          // this.setAlertReplicas(res);
          for(let item of res) {
            const cacheId = `${item.edgeId}:${item.cameraId}:${item.base}`;
            item.cacheId = cacheId;
            item.alertReplicas = {};
            if (item?.alerts?.length) {
              for(let alert of item.alerts) {
                const offset = this.normalizeTimestamp(alert.timestamp, 2000);
                const replica = this.getReplicaFromMainThumbnail(alert.mainThumbnail);
                const filename = alert?.mainThumbnail?.replace('thumbnail-', '')
                  .replace('.jpg', '');
                if (item.alertReplicas[offset]) {
                  item.alertReplicas[offset].push(filename);
                } else {
                  item.alertReplicas[offset] = [filename];
                }
              }
            }
          }
          this.store$.dispatch(ThumbnailsActions.setThumbnailsCache({ thumbnails: _.cloneDeep(res) }));
        }),
      );
  }

  getThumbnailsByDateFromDb(edgeId: string, cameraId: string, start: number, end: number, page?: number, size?: number) {
    return this.getThumbnailsByRange(start, end, [{ edgeId, cameraId }], page, size);
    // let url = `${environment.apiUrl}/thumbnails/v2/range/${edgeId}/${cameraId}/${start}/${end}`;
    // if (page && size) {
    //   url += `?page=${page}&size=${size}`;
    // }
    // return this.httpService.http.get<ThumbnailEntity[]>(url);
  }

  getThumbnailsByOffset(start: number, end: number, percentage: number, duration: number) {
    const delta = end - start;
    const numThumbs = Math.ceil(delta / duration);
    const index = Math.floor(numThumbs * percentage);
    return start + index * duration;
  }

  getClipThumbnail(baseUrl: string, start: number, duration: number) {
    const retries = 10;
    let timestamp = start;
    // let url = `${baseUrl}/${timestamp}.jpg`;
    let url = 'http://d3tvpyeoj1ar6p.cloudfront.net/test/thumb1.jpg';

    return interval(2000)
      .pipe(
        startWith(0),
        switchMap(() => {
          return this.httpService.http.get(url);
        }),
        timeout(3000),
        retryWhen(errors =>
          errors.pipe(
            //log error message
            tap(val => {
              timestamp += duration;
              url = `${baseUrl}/${timestamp}.jpg`;
            }),
            //restart in 6 seconds
            delayWhen(val => timer(val * 1000)),
            take(retries),
          ),
        ),
      );
  }

  getBaseInLocale(dateRaw: Date, tz: string = 'GMT') {
    /**
     * dateRaw is given in browser timezone, and should be 'converted' to edge timezone
     * conversion is done by calculating the offset between the browser timezone and the edge timezone
     * then the offset is added to the original date, so it will be aligned with the edge time.
     * Then, we calculate the GMT timestamp using a conversion with moment and the original timezone
     * (which is the browser tz)
     */
    const timezone = 'GMT';
    const dateInEdgeTz = moment.tz(dateRaw, timezone)
      .toString();
    const startOfDay = DateTime.fromJSDate(new Date(dateInEdgeTz))
      .setZone(timezone)
      .startOf('day')
      .toJSDate();
    const currentLocale = moment.tz(startOfDay, timezone)
      .format('YYYY-MM-DD HH:mm');
    return moment.tz(currentLocale, 'GMT')
      .unix() * 1000;
  }

  computeTsInLocale(ts: number, timezone: string) {
    const offsetInMinutes = moment.tz(moment.tz.guess())
      .utcOffset() - moment.tz(timezone)
      .utcOffset();
    return ts + offsetInMinutes * 60 * 1000;
  }

  convertTsToZone(ts: number, fromZone: string, toZone: string): number {
    const offsetInMinutes = moment.tz(fromZone)
      .utcOffset() - moment.tz(toZone)
      .utcOffset();
    return ts + offsetInMinutes * 60 * 1000;
  }

  // getBaseInLocale(date: Date, timezone: string) {
  //     const hour = Math.floor(date.getUTCHours() / 24) * 24
  //     const dateT = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), hour, 0, 0, 0)
  //     // TODO: Need to be changed calculated according to user <--> location locale user is Israel but locale is GMT
  //     var tz = timezone // moment.tz.guess();
  //     const currentLocale = moment.tz(dateT, tz).format('YYYY-MM-DD HH:mm');
  //     return moment.tz(currentLocale, 'GMT').unix() * 1000;
  // }

  getTimestampInLocale(date: Date, timezone: string) {
    // TODO: Need to be changed calculated according to user <--> location locale user is Israel but locale is GMT
    var tz = timezone; // moment.tz.guess();
    const currentLocale = moment.tz(date, tz)
      .format('YYYY-MM-DD HH:mm');
    return moment.tz(currentLocale, 'GMT')
      .unix() * 1000;
  }

  getEventLocation(timestamp: number, base: number, freq: number = FREQUENCY) {
    return Math.floor((timestamp - base) / freq);
  }

  getBitLocation(timestamp: number, base: number, freq: number = FREQUENCY, bitsInValue: number = BITS_IN_VALUE): number {
    const diff = bitsInValue * Math.floor((timestamp - base) / freq);
    return diff;
  }

  getArrayIndex(diff: number, intSizeInBits = INT_SIZE_IN_BITS) {
    return Math.floor(diff / intSizeInBits);
  }

  getBitStartingIndex(diff: number, intSizeInBits = INT_SIZE_IN_BITS) {
    return diff % intSizeInBits;
  }

  normalizeTimestamp(timestamp: number, thumbnailsDuration: number = 20000, ceil = false) {
    return (ceil ? Math.ceil(timestamp / thumbnailsDuration) : Math.round(timestamp / thumbnailsDuration)) * thumbnailsDuration;
  }

  changeResolution(resolution: number) {
    const data = this.thumbnailsDataSubject.value;
    data!.offsetResInDurations = resolution;
    this.setThumbnailsData(data!);
  }

  // units in y, M, w, d, h, m, s, ms
  durationFormat(input, units = 'ms') {
    let duration = moment()
        .startOf('day')
        .add(units, input),
      format = '';

    if (duration.hour() > 0) {
      format += 'H [hours] ';
    }

    if (duration.minute() > 0) {
      format += 'm [minutes] ';
    }

    format += ' s [seconds]';

    return duration.format(format);
  }

  closeDialog() {
    this.closeDialogSubject.next(null);
  }

  getThumbnailsRaw(data: CameraThumbnailsData, start: number, end: number) {
    data.offsetResInDurations = 45;

    // const start = this.getBaseInLocale(new Date(startTime));
    // const end = this.getBaseInLocale(new Date(endTime));
    return this.getThumbnailsByDateFromDb(data.edgeId, data.cameraId, start, end);
  }

  getEvents(edgeId: string, cameraId: string, base: number) {
    return this.store$.select(
        ThumbnailsSelectors.selectEventsByEdgeIdCameraIdAndBase({
          edgeId,
          cameraId,
          base,
        }),
      )
      .pipe(take(1));
  }

  getReplicaFromMainThumbnail(thumbnailName: string) {
    return +thumbnailName?.split('-')[2];
  }

  async getThumbnailBits(cameras: {
    edgeId: string;
    cameraId: string
  }[], start: number, end: number) {
    if (!cameras?.length) {
      return;
    }
    const camerasStr = JSON.stringify(cameras);
    const url = `${environment.thumbnailsV2}/thumbnails/v2/day/${start}/${end}?cameras=${camerasStr}`;
    this.httpService.http.get<ThumbnailModel.ThumbnailBitsResponse[]>(url, {
        params: {
          sharedToken: true,
        },
      })
      .subscribe((res) => {
          for(let item of res) {
            const { edgeId, cameraId, base } = item;
            const cacheId = `${edgeId}:${cameraId}:${base}`;
            const bits = item.bits;
            this.store$.dispatch(ThumbnailsActions.setBitsCache({ edgeId, cameraId, cacheId, bits }));
          }
        },
      );
  }


  async getThumbnails(thumbsData: CameraThumbnailsData, startTime: number, endTime: number) {
    const result = {};
    const baseToday = this.getBaseInLocale(new Date());
    const start = this.getBaseInLocale(new Date(startTime));
    const end = this.getBaseInLocale(new Date(endTime));
    const storeEventStart = await lastValueFrom(this.getEvents(thumbsData.edgeId, thumbsData.cameraId, start));
    const storeEventEnd = await lastValueFrom(this.getEvents(thumbsData.edgeId, thumbsData.cameraId, end));
    let thumbnailsRaw: ThumbnailModel.ThumbnailDocument[];
    if (!storeEventStart || !storeEventEnd || start === baseToday || end === baseToday) {
      thumbnailsRaw = await lastValueFrom(this.getThumbnailsRaw(thumbsData, startTime, endTime));
      for(let item of thumbnailsRaw) {
        if (item.base === baseToday) {
          const offline = item?.offlineThumbnails;
          const len = offline?.length;
          if (len) {
            const last = offline[len - 1];
            const lastStart = item.base + last[0];
            const lastEnd = item.base + last[1];
            const now = Date.now();
            if (now > lastStart && (now - lastStart < 1000 * 60 * 2)) {
              offline[len - 1][0] += now - lastStart + 1 * 1000 * 60;
            }
          }
        }
        const cacheId = `${item.edgeId}:${item.cameraId}:${item.base}`;
        item.cacheId = cacheId;
        item.alertReplicas = {};
        if (item?.alerts?.length) {
          for(let alert of item.alerts) {
            const offset = this.normalizeTimestamp(alert.timestamp, 2000);
            const replica = this.getReplicaFromMainThumbnail(alert.mainThumbnail);
            const filename = alert?.mainThumbnail?.replace('thumbnail-', '')
              .replace('.jpg', '');
            if (item.alertReplicas[offset]) {
              item.alertReplicas[offset].push(filename);
            } else {
              item.alertReplicas[offset] = [filename];
            }

          }
        }
      }
      this.store$.dispatch(ThumbnailsActions.setThumbnailsCache({ thumbnails: thumbnailsRaw }));
    } else {
      thumbnailsRaw = [storeEventStart, storeEventEnd];
    }
    // for(let thumb of thumbnailsRaw) {
    //   const events: number[] = [];
    //   for(let index = 0; index < 5400; index++) {
    //     let val = thumb.events[index];
    //     if (val) {
    //     }
    //     events.push(val & 0x0000000f);
    //     events.push((val & 0x000000f0) >> (4 * 1));
    //     events.push((val & 0x00000f00) >> (4 * 2));
    //     events.push((val & 0x0000f000) >> (4 * 3));
    //     events.push((val & 0x000f0000) >> (4 * 4));
    //     events.push((val & 0x00f00000) >> (4 * 5));
    //     events.push((val & 0x0f000000) >> (4 * 6));
    //     events.push((val & 0xf0000000) >> (4 * 7));
    //   }
    //   result[thumb.base] = events;
    // }
    return result;
  }

}
