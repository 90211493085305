<div class="ui-modal" *ngIf="{
  isUserExists:isUserExists$|async,
  isCheckLoading:isCheckLoading$|async,
  selectPermissionGroups:selectPermissionGroups$|async,
  selectPermissionGroupsMap:selectPermissionGroupsMap$|async
  } as conditions"
>
  <div class="header flex-row space-between-center">Invite users
    <span class="ui-icon-close c-p" mat-dialog-close></span>
  </div>
  <div class="body">

    <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="8px" class="narrow-input w-100 c-100">
      <div class="flex-row space-between-center">
        <div class="input-wrapper email-wrapper">

          <mat-label>Email address</mat-label>

          <div class="input-icon-container">
            <span class="ui-icon-plus add-email-button c-p"
                  *ngIf="validateEmail(emailControl.value) && !conditions.isUserExists && !conditions.isCheckLoading"
                  (click)="addEmail()"
            >
            </span>
            <span *ngIf="emailControl.valid && conditions.isUserExists"
                  class="ui-icon-info  add-email-button"
                  matTooltip="This user is already a member in the organization"
                  matTooltipClass="sidebar-tooltip"
            >
              </span>
            <span *ngIf="emailControl.invalid && emailControl.touched"
                  class="ui-icon-info  add-email-button"
                  matTooltip="Email is invalid"
                  matTooltipClass="sidebar-tooltip"
            >
              </span>
            <span *ngIf="conditions.isCheckLoading" class="add-email-button preloader-wrapper">
                <ui-preloader [color]="loaderColor.blue"
                ></ui-preloader>
            </span>
            <input class="ui-input"
                   placeholder="Add email"
                   [formControl]="emailControl"
                   [class.valid-email]="validateEmail(emailControl.value)"
                   [class.user-exists-error]="conditions.isUserExists || (emailControl.invalid && emailControl.touched)"
                   (keyup.enter)="(!conditions.isUserExists && !conditions.isCheckLoading)?addEmail():null"
            />
          </div>

        </div>
        <div class="input-wrapper" style="width: 30%">
          <mat-label>Group</mat-label>
          <div class="input-icon-container">
            <mat-select class="ui-selector"
                        [formControl]="permissionsControl"
                        [multiple]="true"
                        placeholder="Select group"
            >
              <mat-option *ngFor="let group of conditions.selectPermissionGroups"
                          [value]="group._id"
              >{{ group.name }}
              </mat-option>
            </mat-select>
          </div>
        </div>

      </div>
      <div class="chips-container">
        <ui-filter-chip *ngFor="let email of emails"
                        class="filter-chip"
                        [title]="email+ ' ('+(conditions.selectPermissionGroupsMap[invitedEmailGroups[email]].name|titlecase)+')'"
                        (onRemoveClicked)="removeEmail(email)"
        ></ui-filter-chip>
      </div>
    </div>
  </div>
  <div class="footer flex-row end-center">
    <ui-button
      [disabled]="
      conditions.isUserExists  ||
      conditions.isCheckLoading ||
      ((emailControl.value && !validateEmail(emailControl.value) ||  (!emailControl.value && !emails.length)) ||
      permissionsControl.invalid)
"
      type="primary"
      text="Send invite"
      (clickAction)="sendInvite()"
    ></ui-button>
  </div>
</div>

