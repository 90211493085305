import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ui-camera-snapshot',
  templateUrl: './ui-camera-snapshot.component.html',
  styleUrls: ['./ui-camera-snapshot.component.scss'],
})
export class UiCameraSnapshotComponent implements OnInit {
  @Input() src: string;

  public errorLoaded = false;
  constructor() {}

  ngOnInit(): void {}

  public onImageLoadedError() {
    this.errorLoaded = true;
  }
}
