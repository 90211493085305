import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap, withLatestFrom } from 'rxjs';
import { PricingActions } from '@states/pricing/pricing.action-types';
import { DeviceOptions, Pricing, PricingCount, ResolutionOptions, StorageOptions } from '@models/pricing.model';
import { select, Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { PriceCalculatorService } from '../../price-calaulator/price-calculator.service';
import { PricingTermToYears } from '@consts/pricing.const';


@Injectable()
export class PricingEffect {

  public calculatePricing$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PricingActions.calculatePricing),
      withLatestFrom(this.store$.pipe(select(state => state.pricingState))),
      switchMap(([, {locations, term, count}]) => {
        const _count: PricingCount = {
          box2tb: 0,
          cameras5mp: 0,
          cameras8mp: 0,
          license: 0,
          box4tb: 0,
          cloudStorage: count.cloudStorage ?? 0,
          cloudSmartStorage: count.cloudSmartStorage ?? 0,
          cloudFlowTagging: count.cloudFlowTagging ?? 0,
        };
        const pricing: Pricing = {
          boxes: 0,
          license: 0,
          cameras: 0,
          cloud: 0,
          total: 0,
        };
        for (let location of locations) {
          const calc = this.priceCalculatorService.getLocationPricing(location, term);
          pricing.boxes += calc.pricing.boxes;
          pricing.license += calc.pricing.license;
          pricing.cameras += calc.pricing.cameras;
          pricing.total += calc.pricing.total;
          _count.box2tb += 0; //calc.count.box2tb;
          _count.box4tb += calc?.count?.box4tb + calc?.count?.box2tb;
          _count.cameras5mp += calc.count.cameras5mp;
          _count.cameras8mp += calc.count.cameras8mp;
          _count.license += calc.count.license;
        }
        const cloudPrice = this.priceCalculatorService.getCloudAddonsPrice(_count.cloudStorage, _count.cloudSmartStorage, _count.cloudFlowTagging);
        pricing.total += cloudPrice * PricingTermToYears[term];

        return of(PricingActions.setPricing({pricing, count: _count}));
      }),
    ),
  );

  public calculateCountPricing$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PricingActions.calculateCountPricing),
      withLatestFrom(this.store$.pipe(select(state => state.pricingState))),
      switchMap(([, {count, term}]) => {
        const pricing: Pricing = {
          boxes: 0,
          license: 0,
          cameras: 0,
          cloud: 0,
          total: 0,
        };
        const box2bPrice = this.priceCalculatorService.getBoxPrice(StorageOptions.Storage2TB, DeviceOptions.OrinNX16GB, term);
        const box4bPrice = this.priceCalculatorService.getBoxPrice(StorageOptions.Storage4TB, DeviceOptions.OrinNX16GB, term);
        const licensePrice = this.priceCalculatorService.getSWLicensePrice(term);
        const camera5mpPrice = this.priceCalculatorService.getCameraPrice(ResolutionOptions.Resolution5MP, term);
        const camera8mpPrice = this.priceCalculatorService.getCameraPrice(ResolutionOptions.Resolution8MP, term);
        const cloudPricing = this.priceCalculatorService.getCloudAddonsPrice(count.cloudStorage, count.cloudSmartStorage, count.cloudFlowTagging);

        pricing.boxes += box2bPrice * count.box2tb + box4bPrice * count.box4tb;
        pricing.license += licensePrice * count.license;
        pricing.cameras += camera5mpPrice * count.cameras5mp + camera8mpPrice * count.cameras8mp;
        pricing.total += pricing.boxes + pricing.license + pricing.cameras;

        pricing.boxes *= PricingTermToYears[term];
        pricing.license *= PricingTermToYears[term];
        pricing.cameras *= PricingTermToYears[term];
        pricing.total *= PricingTermToYears[term];

        pricing.total += cloudPricing * PricingTermToYears[term];


        return of(PricingActions.setPricing({pricing, count}));
      }),
    ),
  );


  constructor(private actions$: Actions, private store$: Store<AppState>, private priceCalculatorService: PriceCalculatorService) {
  }
}
