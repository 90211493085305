import { Component, OnInit, ViewChild } from '@angular/core';
import { UiBreadCrumbItem } from '../../shared/ui-kit/ui-header/ui-header.component';
import { AddLocationStep } from '../../locations/add-location/add-location.component';
import { LocationsService } from '../../locations/locations.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, take } from 'rxjs';
import { select, Store } from '@ngrx/store';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PermissionSelectors } from '@states/permissions/permissions.selector-types';
import { PermissionModel } from '@models/permission.model';
import { MatSelect } from '@angular/material/select';
import { LocationModel } from '../../locations/location.model';
import { LocationSelectors } from '@states/location/location.selector-types';
import { LocationActions } from '@states/location/location.action-types';
import { routerSegments } from '@consts/routes';

@UntilDestroy()
@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss'],
})
export class LocationComponent implements OnInit {

  public ViewType = LocationModel.ViewType;

  public viewType$: Observable<LocationModel.ViewType> = this.store$.pipe(select(LocationSelectors.selectViewType));

  @ViewChild('viewSelector') viewSelector: MatSelect;


  public breadCrumb: UiBreadCrumbItem[] = [
    { name: 'Cameras' },
  ];

  constructor(
    private locationsService: LocationsService,
    private route: ActivatedRoute,
    private router: Router,
    private store$: Store,
  ) {
  }

  ngOnInit(): void {
    this.viewType$.pipe(untilDestroyed(this))
      .subscribe((viewType) => {
        switch (viewType) {
          case LocationModel.ViewType.Floors:
            this.breadCrumb = [
              { name: 'Cameras floor plan view' },
            ];
            break;
          case LocationModel.ViewType.List:
            this.breadCrumb = [
              { name: 'Cameras' },
            ];
            break;
        }
      });
  }

  addLocation() {
    this.locationsService.setAddLocationOptions({
      step: AddLocationStep.ADD_LOCATION,
    });
    this.router.navigateByUrl('locations/add-location');
  }

  public get isLocationCreatePermission() {
    return this.store$.pipe(select(PermissionSelectors.checkAccessPermissions([PermissionModel.Permissions.LocationCreate], [])),
      take(1));
  }

  public setView(viewType: LocationModel.ViewType) {
    this.store$.dispatch(LocationActions.setViewType({ viewType }));
    this.router.navigateByUrl(routerSegments.location);
  }

}
