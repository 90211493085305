import { HttpClient, HttpContext, HttpEvent, HttpEventType, HttpProgressEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { LocationModel } from './location.model';
import { BehaviorSubject, filter, map, Observable, scan, take, tap, throwError } from 'rxjs';
import { Edge, NTPConfigAction } from '../edge/edge.model';
import { SQSMsgInfo } from '../core/interfaces';
import { Store } from '@ngrx/store';
import { EdgeSelectors } from '@states/edge/edge.selector-types';
import { HttpService } from '../core/http.service';
import { api } from '../consts/url.const';
import { EdgeCamera } from '../cameras/camera.model';
import { BYPASS_AUTHENTICAION_TOKEN } from '../core/authentication-interceptor.service';
import { UiUploadService } from '../shared/ui-kit/ui-upload/ui-upload.service';
import { Upload } from '../shared/ui-kit/ui-upload/ui-upload.models';
import { EdgeDocumentType } from '@enums/edge.enum';
import { AppState } from '../store/app.state';
import { EdgeConfigFileType } from '@models/edge.model';

@Injectable({
  providedIn: 'root',
})
export class LocationsService {
  private locationsListSubject = new BehaviorSubject<LocationModel.LocationItem[] | undefined>(undefined);
  private currentLocationSubject = new BehaviorSubject<LocationModel.LocationItem | undefined>(undefined);
  private currentEdgesSubject = new BehaviorSubject<Edge.EdgeDocument[] | undefined>(undefined);
  private addLocationOptionsSubject = new BehaviorSubject<LocationModel.AddLocationOptions | undefined>({
    location: null,
    edgeId: null,
    edgeName: null,
    cameras: null,
    step: null,
  });

  private deletLocationSubject = new BehaviorSubject<LocationModel.LocationDeleteResponse | undefined>(undefined);
  public deletLocation$: Observable<LocationModel.LocationDeleteResponse | undefined> = this.deletLocationSubject.asObservable();

  public currentLocation$: Observable<LocationModel.LocationItem | undefined> = this.currentLocationSubject.asObservable()
    .pipe(
      filter(location => !!location),
      take(1),
    );

  public locationList$: Observable<LocationModel.LocationItem[] | undefined> = this.locationsListSubject.asObservable()
    .pipe(
      filter(locations => !!locations),
      take(1),
    );

  public edgesList$: Observable<Edge.EdgeDocument[] | undefined> = this.currentEdgesSubject.asObservable()
    .pipe(filter(edges => !!edges));

  public addLocationOptions$: Observable<LocationModel.AddLocationOptions | undefined> = this.addLocationOptionsSubject.asObservable()
    .pipe(
      filter(options => !!options),
      take(1),
    );

  constructor(
    private store: Store<AppState>,
    private http: HttpClient,
    private httpService: HttpService,
    private uiUploadService: UiUploadService,
  ) {
  }

  get currentLocation() {
    return this.currentLocationSubject.value;
  }

  get currentEdges() {
    return this.currentEdgesSubject.value;
  }

  deleteLocationAllowed(locationId: string): Observable<boolean> {
    return this.store.select(EdgeSelectors.selectLocationEdgesByLocationId(locationId))
      .pipe(map(edges => !edges || edges.length === 0));
  }

  createLocation(locationCreateRequest: LocationModel.LocationCreateRequest) {
    const url = `${environment.apiUrl}/locations`;
    return this.http.post<LocationModel.LocationCreateResponse>(url, locationCreateRequest);
  }

  updateLocation(locationUpdateRequest: LocationModel.LocationCreateRequest) {
    const url = `${environment.apiUrl}/locations`;
    return this.http.patch<LocationModel.LocationCreateResponse>(url, locationUpdateRequest);
  }

  addEdgeToLocation(addEdgeToLocationRequest: LocationModel.AddEdgeToLocationRequest) {
    const url = `${environment.apiUrl}/locations/add-edge`;
    return this.http.post<SQSMsgInfo>(url, addEdgeToLocationRequest);
  }

  getLocations() {
    const url = `${environment.apiUrl}/locations`;
    this.http.get<LocationModel.LocationItem[]>(url)
      .subscribe(locations => {
        this.locationsListSubject.next(locations);
      });
  }

  getAllLocations(): Observable<LocationModel.LocationItem[]> {
    const url = `${environment.apiUrl}/locations`;
    return this.http.get<LocationModel.LocationItem[]>(url);
  }

  getEdges() {
    const url = `${environment.apiUrl}/locations/${this.currentLocationSubject.value?._id}/edges`;
    this.http.get<Edge.EdgeDocument[]>(url)
      .subscribe(edges => {
        this.currentEdgesSubject.next(edges);
      });
  }

  setLocation(location) {
    this.currentLocationSubject.next(location);
  }

  setAddLocationOptions(options: LocationModel.AddLocationOptions) {
    this.addLocationOptionsSubject.next(options);
  }

  deleteLocation(locationId: string, httpToStore = true, notifyObserver = false): Observable<boolean> {
    try {
      const url = `${environment.apiUrl}/locations/location/${locationId}`;
      return this.http.delete<boolean>(url)
        .pipe(this.httpService.setCacheCompleted(url, httpToStore));
    } catch (error) {
      return throwError(() => error);
    }
  }

  deleteLocationSuccess(locationId: string, intervalRate = 1000, msTimeout = 5000): Observable<boolean> {
    const key = `${environment.apiUrl}/locations/location/${locationId}`;
    return this.httpService.storeNotification(key);
  }

  deleteEdge(locationId, edgeId, force = false) {
    const url = `${environment.apiUrl}/locations/edge/${locationId}/${edgeId}/?force=${force}`;
    return this.http.delete<SQSMsgInfo>(url);
  }

  restartEdge(locationId: string, edgeId: string) {
    const url = `${environment.apiUrl}/edge-management/Reboot/`;
    return this.http.post<SQSMsgInfo>(url, { locationId, edgeId });
  }

  updateEdgeInLocation(updateEdgeInLocationRequest: LocationModel.UpdateEdgeInLocationRequest) {
    const url = `${environment.apiUrl}/locations/update-edge`;
    return this.http.post<SQSMsgInfo>(url, updateEdgeInLocationRequest);
  }

  public uploadEdgeConfig(locationId: string, edgeId: string, file: Blob): Observable<{ token: { session: string } }> {
    const formData = new FormData();
    formData.set('file', file);
    formData.set('locationId', locationId);
    formData.set('edgeId', edgeId);
    return this.http.post<{ token: { session: string } }>(api.location.updateEdgeConfig, formData);
  }

  public uploadEdgeConfigJson(config: string, locationId: string, edgeId: string, configFileType: EdgeConfigFileType): Observable<{ token: { session: string } }> {
    return this.http.post<{ token: { session: string } }>(api.location.updateEdgeConfigJson, {
      config,
      locationId,
      edgeId,
      configFileType,
    });
  }

  public getEdgeConfig(locationId: string, edgeId: string, configFileType?: EdgeConfigFileType): Observable<{ token: { session: string } }> {
    return this.http.post<{ token: { session: string } }>(api.location.getEdgeConfig, {
      locationId,
      edgeId,
      configFileType,
    });
  }

  public getEdgeDocument(locationId: string, edgeId: string, docType: EdgeDocumentType): Observable<{ token: { session: string } }> {
    return this.http.post<{ token: { session: string } }>(api.location.getEdgeDocument, {
      locationId,
      edgeId,
      docType,
    });
  }

  public getEdgeInfo(locationId: string, edgeId: string): Observable<{ token: { session: string } }> {
    return this.http.post<{ token: { session: string } }>(api.location.getEdgeInfo, {
      locationId,
      edgeId,
    });
  }

  public sendApproveEdgeConfig(locationId: string, edgeId: string): Observable<{ token: { session: string } }> {
    return this.http.post<{ token: { session: string } }>(api.location.approveEdgeConfig, {
      locationId,
      edgeId,
    });
  }

  public uploadCameraAsset(locationId: string, edgeId: string, cameraId: string, file: Blob, asset: EdgeCamera.CameraAsset) {
    const formData = new FormData();
    formData.set('file', file);
    formData.set('locationId', locationId);
    formData.set('edgeId', edgeId);
    formData.set('cameraId', cameraId);
    formData.set('asset', JSON.stringify(asset));
    return this.http.post(api.location.uploadCameraAsset, formData);
  }

  public getCameraAssetPreSignedUrl(request: LocationModel.GetCameraAssetPresignedUrlRequest) {
    return this.http.post<{ url: string; filename: string }>(api.location.getCameraAssetPreSignedUrl, request);
  }

  isHttpResponse<T>(event: HttpEvent<T>): event is HttpResponse<T> {
    return event.type === HttpEventType.Response;
  }

  isHttpProgressEvent(event: HttpEvent<unknown>): event is HttpProgressEvent {
    return event.type === HttpEventType.DownloadProgress || event.type === HttpEventType.UploadProgress;
  }

  uploadCameraAssetPresignedUrl(request: LocationModel.UploadCameraAssetPresignedUrlRequest) {
    const data = new FormData();
    data.append('file', request.file);
    const initialState: Upload = { state: 'PENDING', progress: 0 };
    const calculateState = (upload: Upload, event: HttpEvent<unknown>): Upload => {
      if (this.isHttpProgressEvent(event)) {
        return {
          progress: event.total ? Math.round((100 * event.loaded) / event.total) : upload.progress,
          state: 'IN_PROGRESS',
        };
      }
      if (this.isHttpResponse(event)) {
        return {
          progress: 100,
          state: 'DONE',
        };
      }
      return upload;
    };

    return this.http
      .put(request.url, request.file, {
        context: new HttpContext().set(BYPASS_AUTHENTICAION_TOKEN, true),
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        scan(calculateState, initialState),
        tap((state: Upload) => {
          this.uiUploadService.setProgress(state);
        }),
        filter(val => val.state === 'DONE'),
      );
  }

  notifyCameraAssetUploaded(request: LocationModel.NotifyCameraAssetUploadedRequest): Observable<{ token: { session: string } }> {
    return this.http.post<{ token: { session: string } }>(api.location.notifyCameraAssetUploaded, request);
  }

  public getEdgeLogs(dto: {
    locationId: string;
    edgeId: string;
    daysBack: number;
  }): Observable<{ token: { session: string } }> {
    return this.http.post<{ token: { session: string } }>(api.location.getEdgeLogs, dto);
  }

  public getEdgeAwsLogs(dto: { edgeId: string }): Observable<string[]> {
    return this.http.post<string[]>(api.location.getAwsEdgeLogs, dto);
  }

  public uploadVideoStorage(dto: {
    locationId: string;
    edgeId: string;
    cameraId: string;
    dirPattern: string;
    filePattern: string;
  }): Observable<{ token: { session: string } }> {
    return this.http.post<{ token: { session: string } }>(api.location.uploadVideoStorage, dto);
  }

  public listVideoStorage(dto: {
    locationId: string;
    edgeId: string;
    cameraId: string;
    dirPattern: string;
  }): Observable<{ token: { session: string } }> {
    return this.http.post<{ token: { session: string } }>(api.edge.listVideoStorage, dto);
  }

  public rmEdgeAWSLog(dto: { link: string }): Observable<{ token: { session: string } }> {
    return this.http.post<{ token: { session: string } }>(api.location.rmLog, dto);
  }

  public rmCamera(dto: { locationId: string; edgeId: string; cameraId: string }): Observable<{ token: { session: string } }> {
    return this.http.delete<{ token: { session: string } }>(api.location.rmCamera(dto.locationId, dto.edgeId, dto.cameraId));
  }

  public updateAnalyticConfigJson(dto: {
    locationId: string;
    edgeId: string;
    cameraId: string;
    config: string;
  }): Observable<{ token: { session: string } }> {
    if (!dto.cameraId) {
      delete dto.cameraId;
    }
    return this.http.post<{ token: { session: string } }>(api.location.updateAnalyticConfigJson, dto);
  }

  public getAnalyticConfigJson(dto: { locationId: string; edgeId: string; cameraId: string }): Observable<{ token: { session: string } }> {
    if (!dto.cameraId) {
      delete dto.cameraId;
    }
    return this.http.post<{ token: { session: string } }>(api.location.getAnalyticConfigJson, dto);
  }

  public probFile(dto: { locationId: string; edgeId: string; filename: string }): Observable<{ token: { session: string } }> {
    return this.http.post<{ token: { session: string } }>(api.location.probFile, dto);
  }

  public getEdgeNtp(locationId: string, edgeId: string): Observable<{ token: { session: string } }> {
    return this.http.post<{ token: { session: string } }>(api.location.ntp, {
      locationId,
      edgeId,
      action: NTPConfigAction.Load,
    });
  }

  public setEdgeNtp(locationId: string, edgeId: string, servers: string[]): Observable<{ token: { session: string } }> {
    return this.http.post<{ token: { session: string } }>(api.location.ntp, {
      locationId,
      edgeId,
      action: NTPConfigAction.Update,
      servers,
    });
  }

  public getEdgeLookup(locationId: string, edgeId: string): Observable<LocationModel.EdgeLookupParams> {
    return this.http.get<LocationModel.EdgeLookupParams>(api.location.getEdgeLookup(locationId, edgeId));
  }

  public updateEdgeLookup(locationId: string, edgeId: string): Observable<any> {
    return this.http.patch<any>(api.location.updateEdgeLookup(locationId, edgeId), {});
  }

  public getCameraById(locationId: string, edgeId: string, cameraId: string): Observable<EdgeCamera.CameraItem> {
    return this.http.get<EdgeCamera.CameraItem>(api.location.getCameraById(locationId, edgeId, cameraId));
  }

  public getCoreRecoveryDocument(edgeId: string): Observable<any> {
    return this.http.get<any>(api.edgeManagement.recovery(edgeId));
  }

  public saveCoreRecoveryDocument(edgeId: string, body: any): Observable<any> {
    return this.http.post<any>(api.edgeManagement.recovery(edgeId), { data: body });
  }
}
