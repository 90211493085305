export namespace IntegrationsModel {
  export interface SlackInstallCallback {
    code: string;
    state: string;
  }

  export interface IntegrationCategory {
    name: string;
    options: Inegration[];
  }

  export interface Inegration {
    id: Providers;
    name: string;
    description: string;
    icon: string;
    installed?: boolean;
    beta?: boolean;
    defaultParams?: any;
  }

  export enum Providers {
    Slack = 'slack',
    RapidSOS = 'rapidSos',
    Webhook = 'webhook'
  }

  export type ProviderActions = RapidSOS.Actions | Slack.Actions;
  export type ProviderActionsParams = RapidSOS.ActionParams | Slack.ActionParams;

  export namespace RapidSOS {
    export enum Actions {
      callFlow = 'callFlow',
    }

    export type ActionParams = {
      [RapidSOS.Actions.callFlow]: {
        orgId: string;
      };
    };
  }

  export namespace Slack {
    export enum Actions {
      listChannels = 'listChannels',
      sendMessage = 'sendMessage',
    }

    export type ActionParams = {
      [Slack.Actions.listChannels]: {
        orgId: string;
      };
      [Slack.Actions.sendMessage]: {
        orgId: string;
        channel: string;
        text: string;
      };
    };
  }

  export interface SlackChannel {
    id: string;
    name: string;
  }

  export interface IntegrationListSlackChannelsResponse {
    channels: SlackChannel[];
  }
}
