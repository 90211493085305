<ng-container *ngrxLet="selectAllCameras$;let cameraLookup">
  <div #playerWrapper
       fxLayoutAlign="center center"
       class="camera-player-wrapper"
       *ngIf="camera; else noCamera"
       [class.c-p]="edit"
       fxFlexFill
  >
    <ui-quality-selector
      #qualitySelector
      *ngIf="!webrtc && !edit && !loadvid && !camera.err && allowQuality"
      [scale]="scale"
      (qualityChanged)="changeQuality($event)"
    ></ui-quality-selector>
    <div fxLayout="row" *ngIf="edit" fxLayoutAlign="start center" class="camera-change-label" fxLayoutGap="15px">
      <div>Click to change camera</div>
      <div class="divider"></div>
      <div matTooltip="Remove Camera" (click)="deleteCamera(); $event.stopPropagation()">
        <mat-icon>delete</mat-icon>
      </div>
    </div>

    <img
      #editSnap
      class="snapshot"
      *ngIf="edit"
      [style.maxHeight.px]="(editSnap.clientWidth * 9) / 16"
      [lazyLoad]=" {edgeId:camera.edgeId, cameraId: camera.cameraId } | cameraBestImagePipe | async"
      [defaultImage]="cameraSnapshot(camera.cameraId) | async"
    />
    <img
      #snap
      class="snapshot"
      [style.maxHeight.px]="(snap.clientWidth * 9) / 16"
      *ngIf="!edit && (loadvid || camera.err || inactive)"
      [lazyLoad]=" {edgeId:camera.edgeId, cameraId: camera.cameraId } | cameraBestImagePipe | async"
      [defaultImage]="cameraSnapshot(camera.cameraId) | async"
    />

    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px" class="top-left-actions">
      <ng-container>
        <div *ngIf="!edit && !loadvid && !camera.err"
             class="maximize-button flex-row center-center"
             matTooltip="Fullscreen"
             (click)="maximize()"
        >
          <!--          <mat-icon>{{ isFullscreen ? 'fullscreen_exit' : 'fullscreen' }}</mat-icon>-->
          <span class="ui-icon-fullscreen-2"></span>

        </div>
      </ng-container>
    </div>
    <div
      *ngIf="!edit && (loadvid || camera.err) && canPlay(getCameraStatus() | async)"
      class="wall-camera-loader-wrapper"
      fxLayoutAlign="center center"
    >
      <!--      <mat-spinner diameter="45" *ngIf="!camera.err"></mat-spinner>-->
      <div *ngIf="camera.err">
        <mat-icon class="c-p c-white" matTooltip="Retry" style="font-size: 4em; height: auto; width: auto"
                  (click)="retry()"
        >refresh
        </mat-icon>
      </div>
    </div>
    <div
      *ngIf="!canPlay(getCameraStatus() | async)"
      class="wall-camera-loader-wrapper"
      fxLayoutAlign="center center"
      style="color: #fff; font-size: 14px"
    >
      Camera offline
    </div>
    <webrtc-player
      [class.hidden]="!webrtc"
      (onStreamError)="error()"
      (onError)="resetError()"
      #webrtcPlayer
      [edgeId]="camera.edgeId"
      [cameraId]="camera.cameraId"
      [locationId]="camera.locationId"
      [allowZoom]="isFullscreen"
      class="w-100"
      (playing)="playing()"
      [forceRelay]="webrtcRelay"
      [enableQualitySelection]="false"
      (onIsRelay)="setRelayStatus($event)"
      (onPlaying)="onPlaying()"
      [hideQuality]="true"
      [cameraName]="cameraName"
      [isWall]="true"
      [autostart]="true"
      [showControls]="false"
    >
    </webrtc-player>
  </div>
</ng-container>

<ng-template #noCamera>
  <div *ngIf="edit; else noEdit" fxLayoutAlign="center center" class="c-p svg-fill-primary no-camera-wrapper"
       fxFlexFill
  >
    <mat-icon svgIcon="videocam"></mat-icon>
    <div class="ml-10">Select Camera</div>
  </div>
  <ng-template #noEdit>
    <div fxLayoutAlign="center center" class="no-camera-wrapper" fxFlexFill>No Camera</div>
  </ng-template>
</ng-template>
