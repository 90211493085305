import { createAction, props } from '@ngrx/store';
import { EdgeCamera } from '../../../cameras/camera.model';
import { MultiPlaybackData, MultiPlaybackMove } from '@models/multi-playback.model';
import { WallModelV2 } from '@models/wall.model';
import WallLayout = WallModelV2.WallLayout;

export const resetToInitialState = createAction('[MULTI PLAYBACK] Reset to Initial state');

export const setSelectedCameras = createAction('[MULTI PLAYBACK] Set Selected Cameras', props<{ selectedCameras: EdgeCamera.CameraItem[] }>());
export const setData = createAction('[MULTI PLAYBACK] Set Data', props<{ data: MultiPlaybackData }>());
export const setMove = createAction('[MULTI PLAYBACK] Set Move', props<{ move: MultiPlaybackMove }>());
export const setDefault = createAction('[MULTI PLAYBACK] Set Default');
export const setLayout = createAction('[MULTI PLAYBACK] Set Layout', props<{ layout: WallLayout }>());
export const setCameraEvents = createAction('[MULTI PLAYBACK] Set Camera Events', props<{ idx: number, events: number[] }>());
export const setCameraStorageStats = createAction('[MULTI PLAYBACK] Set Camera Storage Stats', props<{ cameraId: string, stats: number[] }>());
export const swapCamerasStart = createAction('[MULTI PLAYBACK] Swap Cameras Start');
export const swapPositions = createAction('[MULTI PLAYBACK] Swap Cameras', props<{ idx1: number, idx2: number }>());
export const setRange = createAction('[MULTI PLAYBACK] Set Range', props<{ startTime: number, endTime: number }>());


