import { Injectable } from '@angular/core';
import * as DevTeamActions from '@states/dev-team/dev-team.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { UserService } from '../../user/user.service';
import { catchError, debounceTime, share, switchMap, withLatestFrom } from 'rxjs';
import * as SharedActions from '@states/shared/shared.actions';
import { UtilsV2Service } from '../../services/utils-v2.service';
import * as moment from 'moment';
import { IntegrationsService } from '../../development/integrations.service';
import { IntegrationsActions } from '@states/integrations/integrations.action-types';

@Injectable()
export class IntegrationsEffect {
  public getIntegrations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IntegrationsActions.getIntegrations),
      withLatestFrom(this.store$.pipe(select(state => state.organizationState))),
      switchMap(([, { activeOrganization }]) => {

        return this.integrationsService
          .getIntegrations(activeOrganization.orgId)
          .pipe(
            switchMap(res => {
              return [
                IntegrationsActions.getIntegrationsSuccess({ integrations: res }),
              ];
            }),
            catchError(err => [SharedActions.showMessage({ error: JSON.stringify(err) })]),
          );
      }),
      share(),
    ),
  );

  public deactivateSlack$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IntegrationsActions.deactivateSlack),
      withLatestFrom(this.store$.pipe(select(state => state.organizationState))),
      switchMap(([, { activeOrganization }]) => {

        return this.integrationsService
          .deactivateSlack(activeOrganization.orgId)
          .pipe(
            switchMap(res => {
              return [
                IntegrationsActions.getIntegrations(),
                SharedActions.showMessage({ success: 'Slack uninstalled successfully' }),
              ];
            }),
            catchError(err => [SharedActions.showMessage({ error: err })]),
          );
      }),
      share(),
    ),
  );

  constructor(private actions$: Actions, private store$: Store<AppState>, private integrationsService: IntegrationsService,
  ) {
  }
}
