// mediasoup.service.ts
import { EventEmitter, Injectable } from '@angular/core';
import * as mediasoupClient from 'mediasoup-client';
import * as protooClient from 'protoo-client';
import { Device } from 'mediasoup-client';
import { Peer } from 'protoo-client';
import { AuthenticationService } from '../../authentication/authentication.service';
import { Transport } from 'mediasoup-client/lib/Transport';
import { AppData, Consumer } from 'mediasoup-client/lib/types';
import * as uuid from 'uuid';
import { HttpService } from '../../core/http.service';
import { api } from '@consts/url.const';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

export interface MediasoupSession {
  peer?: Peer;
  recvTransport?: Transport<AppData>;
  consumer?: Consumer<AppData>;
  device?: Device;
  playingSubject?: BehaviorSubject<boolean>;
  playingSubscription?: Subscription;
}

@Injectable({
  providedIn: 'root',
})
export class MediasoupService {

  debug = true;
  logger = this.debug ? console.log : () => {
  };

  constructor(
    private authService: AuthenticationService,
    private httpService: HttpService) {

  }

  public isPlaying(video: HTMLVideoElement): boolean {
    return !!(video && video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2);
  }

  public cleanup(session: MediasoupSession) {
    // Close the consumer, if exists
    if (session?.consumer) {
      session.consumer?.removeAllListeners('transportclose');
      session.consumer?.close();
      session.consumer = null;
    }

    // Close the receiving transport, if exists
    if (session?.recvTransport) {
      session.recvTransport?.removeAllListeners('connect');
      session.recvTransport?.close();
      session.recvTransport = null;
    }

    // Close the peer connection, if exists
    if (session?.peer) {
      session.peer?.removeAllListeners('request');
      session.peer?.removeAllListeners('notification');
      session.peer.close(); // This will also close the underlying WebSocket connection
      session.peer = null;
    }

    // Clear other properties
    if (session?.device) {
      session.device = null;
    }

    if (session?.playingSubject) {
      session.playingSubject = null;
    }
    if (session?.playingSubscription) {
      session?.playingSubscription.unsubscribe();
    }
    // If you have any other properties or listeners that need cleanup, handle them here.
  }

  start(edgeId: string, cameraId: string, hq: boolean = false) {
    const url = `${api.mediasoup.start}/${edgeId}/${cameraId}/${hq}`;
    return this.httpService.http.get(url);
  }

  async connect(roomId: string, videoElement: HTMLVideoElement): Promise<MediasoupSession> {
    const session: MediasoupSession = {};
    const sessionId = uuid.v4();
    const playingSubject = new BehaviorSubject<boolean>(false);
    const peerId = `${this.authService.getAuthProviderIdFromLocalStorage()}-${sessionId}`;
    const url = `${environment.mediasoupUrl}/?roomId=${roomId}&peerId=${peerId}&consumerReplicas=undefined`;
    // const url = `wss://v3demo.mediasoup.org:4443/?roomId=${roomId}&peerId=${peerId}&consumerReplicas=undefined`; // `${environment.mediasoupUrl}/?roomId=${roomId}&peerId=${peerId}&consumerReplicas=undefined`;
    this.logger('Connecting to server:', url);

    session.playingSubject = playingSubject;
    const transport = new protooClient.WebSocketTransport(url);
    session.peer = new protooClient.Peer(transport);

    // Wait for 'open' event
    await new Promise((resolve) => {
      session.peer.once('open', resolve);
    });
    this.logger('Connection established');
    session.device = new mediasoupClient.Device();
    const routerRtpCapabilities =
      await session.peer.request('getRouterRtpCapabilities');

    await session.device.load({routerRtpCapabilities});

    const transportInfo = await session.peer.request(
      'createWebRtcTransport',
      {
        forceTcp: false,
        producing: false,
        consuming: true,
        sctpCapabilities: undefined,
      });

    const {
      id,
      iceParameters,
      iceCandidates,
      dtlsParameters,
      sctpParameters,
    } = transportInfo;

    session.recvTransport = session.device.createRecvTransport(
      {
        id,
        iceParameters,
        iceCandidates,
        dtlsParameters:
          {
            ...dtlsParameters,
            // Remote DTLS role. We know it's always 'auto' by default so, if
            // we want, we can force local WebRTC transport to be 'client' by
            // indicating 'server' here and vice-versa.
            role: 'auto',
          },
        sctpParameters,
        iceServers: [],
        additionalSettings:
          {encodedInsertableStreams: undefined},
      });

    session.recvTransport.on(
      'connect', ({dtlsParameters}, callback, errback) => // eslint-disable-line no-shadow
      {
        session.peer.request(
          'connectWebRtcTransport',
          {
            transportId: session.recvTransport.id,
            dtlsParameters,
          })
          .then(callback)
          .catch(errback);
      });

    // Join room
    await session.peer.request('join', {
      displayName: 'User',
      device: session.device,
      rtpCapabilities: session.device.rtpCapabilities,
      sctpCapabilities: undefined,
    });

    // Handle 'notification' events
    session.peer.on('request', async (request, accept, reject) => {
      this.logger('Received request:', request);
      const {
        peerId,
        producerId,
        id,
        kind,
        rtpParameters,
        type,
        appData,
        producerPaused,
      } = request.data;
      const consumer = await session.recvTransport.consume(
        {
          id,
          producerId,
          kind,
          rtpParameters,
          // NOTE: Force streamId to be same in mic and webcam and different
          // in screen sharing so libwebrtc will just try to sync mic and
          // webcam streams from the same remote session.peer.
          streamId: `${peerId}-${appData.share ? 'share' : 'mic-webcam'}`,
          appData: {...appData, peerId}, // Trick.
        });

      consumer.on('transportclose', () => {
        // I want to know if consumer transport is closed
        // consumers.delete(consumer.id);
      });

      const {spatialLayers, temporalLayers} =
        mediasoupClient.parseScalabilityMode(
          consumer.rtpParameters.encodings[0].scalabilityMode);

      accept();
      if (!this.isPlaying(videoElement)) {
        await this.consume(consumer, videoElement, playingSubject);
      }

    });

    session.peer.on('notification', async (notification) => {
      // this.logger('Received notification:', notification);
      if (notification.method === 'newProducer') {
        // this.logger('New producer:', notification.data);
        // Here you can handle new producers
      }
      if (notification.method === 'activeSpeaker') {
        // this.logger('Active speaker:', notification.data);
      }
    });
    return session;
  }


  async consume(consumer, videoElement: HTMLVideoElement, playingSubject: BehaviorSubject<boolean>) {

    // Attach media stream to video element and play it
    videoElement.srcObject = new MediaStream([consumer.track]);
    await videoElement.play();
    this.logger('Video playing');
    playingSubject.next(true);

    // Handle 'close' event
    consumer.on('close', () => {
      this.logger('Consumer closed');
      videoElement.srcObject = null;
    });
  }
}
