import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import * as moment from 'moment-timezone';
import { CdkDragMove, CdkDragStart } from '@angular/cdk/drag-drop';
import { CamerasThumbnailsService } from '../../../cameras/camera-thumbnails/camera-thumnails.service';
import { debounceTime, mergeMap, of, Subject, switchMap } from 'rxjs';

export interface TimelineSerie {
  time: string;
  percentage: number;
}

export interface TimelineDragEvent {
  start: number;
  end: number;
  ts?: number;
}

const MINUTE = 1000 * 60;
const HOUR = MINUTE * 60;

@Component({
  selector: 'timeline-series',
  templateUrl: './timeline-series.component.html',
  styleUrls: ['./timeline-series.component.scss'],
})
export class TimelineSeriesComponent implements OnInit, AfterViewInit, OnChanges {

  @ViewChild('wrapper') wrapper: ElementRef;

  @Output() timelineDragStart = new EventEmitter<void>();
  @Output() timelineDrag = new EventEmitter<TimelineDragEvent>();
  @Output() timelineDragEnd = new EventEmitter<TimelineDragEvent>();

  @Input() start = Date.now() - 1000 * 60 * 60;
  @Input() end = Date.now() + 1000 * 60 * 60;
  @Input() timezone = 'GMT';
  @Input() timestamp: number;

  @Input() percent = 0.5;
  @Input() zoomEnabled = true;

  @Input() fixedRange = false;

  @Input() isCameraView = false;


  public series: TimelineSerie[] = [];
  public resolution = 60;

  public dragStartPos: { x: number, y: number } = { x: 0, y: 0 };

  public dragStartTs = this.start;
  public dragEndTs = this.end;

  public dragging = false;

  dragDebouncer: Subject<string> = new Subject<string>();
  zoomDebouncer: Subject<string> = new Subject<string>();

  constructor(private camerasThumbnailsService: CamerasThumbnailsService) {
  }

  ngAfterViewInit(): void {
    this.series = this.getRoundedTimes(this.timezone, this.start, this.end, this.resolution);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['start'] || changes['end']) {
      const delta = this.end - this.start;
      if (delta > 12 * HOUR) {
        this.resolution = 120;
      } else if (delta > 4 * HOUR) {
        this.resolution = 60;
      } else if (delta > HOUR) {
        this.resolution = 30;
      } else if (delta > 30 * MINUTE) {
        this.resolution = 10;
      } else if (delta > 10 * MINUTE) {
        this.resolution = 5;
      } else {
        this.resolution = 1;
      }
      this.series = this.getRoundedTimes(this.timezone, this.start, this.end, this.resolution);
    }
  }

  ngOnInit(): void {
    this.dragDebouncer.pipe(debounceTime(0))
      .subscribe(() => {
        this.timelineDrag.emit({ start: this.start, end: this.end });
      });
    this.zoomDebouncer.pipe(debounceTime(0))
      .subscribe(() => {
        this.timelineDrag.emit({ start: this.start, end: this.end });
      });
  }

  dragStart(event: CdkDragStart) {
    if (this.fixedRange) {
      return;
    }
    this.dragging = true;
    this.dragStartTs = this.start;
    this.dragEndTs = this.end;
    this.timelineDragStart.emit();
  }

  dragMove(drag: CdkDragMove) {
    if (this.fixedRange) {
      return;
    }
    const delta = (drag.event as MouseEvent)?.clientX - drag.pointerPosition?.x;
    const deltaPercentage = delta / this.wrapper.nativeElement.clientWidth;
    const start = this.normalizeTimestamp(this.dragStartTs - deltaPercentage * (this.dragEndTs - this.dragStartTs));
    const end = this.normalizeTimestamp(this.dragEndTs - deltaPercentage * (this.dragEndTs - this.dragStartTs));

    const timestamp = start + (end - start) * this.percent;
    if (delta < 0 && timestamp > Date.now()) {
      drag.source.reset();
      return;
    }

    this.start = start;
    this.end = end;
    this.series = this.getRoundedTimes(this.timezone, this.start, this.end, this.resolution);
    this.dragDebouncer.next(null);
  }

  normalizeTimestamp(timestamp: number) {
    return this.camerasThumbnailsService.normalizeTimestamp(timestamp, 2000);
  }

  dragEnd(event: any) {
    if (this.fixedRange) {
      return;
    }
    this.dragging = false;
    delete this.dragStartPos;
    this.timelineDragEnd.emit({ start: this.start, end: this.end });
  }

  getRoundedTimes(timezone: string, start: number, end: number, resolutionMinutes: number): TimelineSerie[] {
    // Convert timestamps to Moment objects with specified timezone
    let startMoment = moment.tz(Math.floor(start), timezone);
    let endMoment = moment.tz(Math.floor(end), timezone);
    const width = this.wrapper?.nativeElement?.clientWidth;

    const durationSeconds = moment.duration(endMoment.diff(startMoment))
      .asSeconds();
    let resolutionSeconds = resolutionMinutes * 60; // default resolution in seconds

    // Adjust resolution based on the duration
    if (durationSeconds >= 10 * 3600) {        // 10 hours or more
      resolutionSeconds = 2 * 3600;           // 2 hours in seconds
    } else if (durationSeconds >= 2 * 3600) {  // 2 hours or more but less than 10 hours
      resolutionSeconds = 3600;               // 1 hour in seconds
    } else if (durationSeconds >= 1 * 3600) {  // 2 hours or more but less than 10 hours
      resolutionSeconds = 1800;               // 1 hour in seconds
    } else if (durationSeconds > 10 * 60) {
      resolutionSeconds = 5 * 60;           // 5 minutes in seconds
    } else if (durationSeconds > 2 * 60) {     // less than 2 minutes
      resolutionSeconds = 60;           // 1 minute in seconds
    } else {     // less than 2 minutes
      resolutionSeconds = 10;                  // 10 seconds
    }

    // Adjust starting point based on resolution
    if (resolutionSeconds === 2 * 3600) {
      if (startMoment.hour() % 2 === 1 || startMoment.minute() > 0 || startMoment.second() > 0) {
        startMoment = startMoment.hour(startMoment.hour() + (2 - (startMoment.hour() % 2)))
          .startOf('hour');
      }
    } else {
      const intoResolution = startMoment.seconds() + (60 * startMoment.minutes());
      const offset = intoResolution % resolutionSeconds;
      if (offset > 0) {
        startMoment = startMoment.add(resolutionSeconds - offset, 'seconds');
      }
    }

    let results = [];
    const format = width > 600 ? 'hh:mm A' : 'h:mm A';
    const formatSecs = width > 600 ? 'hh:mm:ss A' : 'h:mm:ss A';
    while (startMoment.isBefore(endMoment) || startMoment.isSame(endMoment)) {
      // Calculate the percentage location
      const percentage = ((startMoment.valueOf() - start) / (end - start)) * 100;
      results.push({
        time: resolutionSeconds < 60 ? startMoment.format(formatSecs) : startMoment.format(format),
        percentage: parseFloat(percentage.toFixed(4)),  // Ensure precision
      });

      // Move to the next resolution
      startMoment.add(resolutionSeconds, 'seconds');
    }

    const maxResults = width / 66;
    if (results.length > maxResults) {
      // Remove some of the results to fit the screen
      const step = Math.ceil(results.length / maxResults);
      const newResults = [];
      for(let i = 0; i < results.length; i++) {
        if (i % step === 0) {
          newResults.push('');
          continue;
        }
        newResults.push(results[i]);
      }
      results = newResults;
    }

    return results;
  }

  // public zoom(event: WheelEvent) {
  //   const delta = event.deltaY;
  //   const deltaPercentage = delta / this.wrapper.nativeElement.clientWidth;
  //   //calculate new times around the center of mouse pointer area
  //   const left = this.wrapper.nativeElement.getBoundingClientRect().left;
  //   const fromCenter = (event.clientX - left) / this.wrapper.nativeElement.clientWidth;
  //   const addPercentage = fromCenter - 0.5;
  //   const deltaTs = deltaPercentage * (this.end - this.start);
  //   if ((this.end - this.start > 24 * HOUR && deltaTs > 0) || (this.end - this.start < MINUTE && deltaTs < 0)) {
  //     return;
  //   }
  //   this.start = this.normalizeTimestamp(this.start - deltaTs - deltaTs * addPercentage);
  //   this.end = this.normalizeTimestamp(this.end + deltaTs - deltaTs * addPercentage);
  //   this.series = this.getRoundedTimes(this.timezone, this.start, this.end, this.resolution);
  //   // this.timelineDrag.emit({start: this.start, end: this.end});
  //   this.zoomDebouncer.next(null);
  // }

  public zoom(event: WheelEvent) {
    event.stopPropagation();
    event.preventDefault();
    if (!this.zoomEnabled || this.fixedRange) {
      return;
    }
    const delta = event.deltaY;
    const deltaPercentage = Math.min(delta / this.wrapper.nativeElement.clientWidth, 0.8);

    // Calculate how much to adjust based on the needle's position (this.percent)
    const leftPercent = this.percent; // percentage distance from start to needle
    const rightPercent = 1 - this.percent; // percentage distance from needle to end

    const deltaTs = deltaPercentage * (this.end - this.start);

    if ((this.end - this.start >= 24 * HOUR && deltaTs > 0) || (this.end - this.start <= MINUTE && deltaTs < 0)) {
      return;
    }

    // Adjust the start and end timestamps based on the needle's position
    this.start = this.normalizeTimestamp(this.start - deltaTs * leftPercent);
    this.end = this.normalizeTimestamp(this.end + deltaTs * rightPercent);

    this.series = this.getRoundedTimes(this.timezone, this.start, this.end, this.resolution);

    // this.timelineDrag.emit({start: this.start, end: this.end});
    this.zoomDebouncer.next(null);
  }

}
