import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { EdgeStatusService } from '../../edge/edge-status.service';
import { BehaviorSubject, catchError, filter, interval, Observable, of, take, timeout } from 'rxjs';
import { CamerasService } from '../../cameras/cameras.service';
import { WebrtcV2Component } from '../webrtc-v2/webrtc-v2.component';
import { UiAreaSelection } from '../ui-kit/ui-area-selector/ui-area-selector.component';
import { PulsationModels } from '@models/pulsation.model';
import { select, Store } from '@ngrx/store';
import { LocationSelectors } from '@states/location/location.selector-types';
import { WebRtcSelectors } from '@states/webrtc/webrtc.selector-types';
import { PermissionSelectors } from '@states/permissions/permissions.selector-types';
import { PermissionModel } from '@models/permission.model';
import Permissions = PermissionModel.Permissions;

@Component({
  selector: 'webrtc-player',
  templateUrl: './webrtc-player-v2.component.html',
  styleUrls: ['./webrtc-player-v2.component.scss'],
})
export class WebrtcPlayerV2Component implements OnInit {

  public selectMediasoup$: Observable<boolean> = this.store$.pipe(select(WebRtcSelectors.selectMediasoup));

  @Output() onStreamError = new EventEmitter(null);
  @Output() onErrorReset = new EventEmitter(null);
  @Output() onPlaying: EventEmitter<string> = new EventEmitter<string>();
  @Output() onIsRelay: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onError: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() noMoreFiles: EventEmitter<void> = new EventEmitter<void>();
  @Output() pause: EventEmitter<void> = new EventEmitter<void>();

  @Input() public isRespectRatio: boolean = true;
  @Input() public cameraId;
  @Input() public edgeId;
  @Input() public locationId;
  @Input() public showZoomButtons = true;
  @Input() public allowZoom = true;
  @Input() public autostart = false;
  @Input() public forceRelay = false;
  @Input() public enableQualitySelection = true;
  @Input() public accessToken: string;
  @Input() public cameraName: string;
  @Input() public isSmallTile: boolean = false;
  @Input() public showTime = false;
  @Input() public offlineBorder = true;
  @Input() public showMiddleOfflineBadge = true;
  @Input() public extraRight = false;

  @Input() public playback = false;
  @Input() public liveView = false;
  @Input() public playbackTS: number;
  @Input() public playbackSeek = false;
  @Input() public duration: number;
  @Input() public isMediasoup = false;
  @Input() public disableExtend = false;
  @Input() public enableHealthCheck = true;
  @Input() public isExternallyManaged = false;

  @Input() public showCameraName = true;
  @Input() public isLocalStreamEnabled = false;
  @Input() public permissionCheck = true;
  @Input() public localIpAddress: string;
  @Input() public isDragAllowed: boolean = true;
  @Input() public cameraView: boolean = false;
  @Input() public zoomPreview = true;
  @Input() public hideQuality = false;

  timezone = 'GMT';

  @ViewChild('player')
  public player: WebrtcV2Component;

  public cameraStatuses = PulsationModels.ComponentStatus;

  constructor(
    private edgeStatusService: EdgeStatusService,
    private camerasService: CamerasService,
    private store$: Store,
    private cd: ChangeDetectorRef,
  ) {
  }

  public get mediasoup(): boolean {
    return this.player?.mediasoup;
  }

  public get videoLoader() {
    return this.player?.loader;
  }

  public get video() {
    return this.player?.video;
  }

  public get currentTime(): number {
    return this.player?.video?.currentTime;
  }

  public pauseVideo(user = false, paused = false, currentTs?: number) {
    return this.player?.pauseVideo(user, paused, currentTs);
  }

  public toggleMediaSoup() {
    this.player.mediasoup = !this.player.mediasoup;
  }

  public get playbackErrors() {
    return this.player?.playbackErrors;
  }

  ngOnInit(): void {
    this.store$.select(LocationSelectors.selectLocationTimezone(this.locationId))
      .subscribe(timezone => {
        this.timezone = timezone;
      });

    this.selectMediasoup$.subscribe((mediasoup) => {

      if (this.player?.isPlaying) {
        this.player.stop();
        this.player.mediasoup = mediasoup;
        this.player.play();
      } else {
        this.isMediasoup = mediasoup;
      }
    });
  }

  public get disableInactivity(): boolean {
    return this.player?.disableInactivity;
  }

  public get isPlaying(): boolean {
    return this.player?.isPlaying;
  }

  public get inactive(): boolean {
    return this.player?.inactive;
  }

  public inZoom() {
    return this.player?.inZoom();
  }

  public playing() {
    interval(1000)
      .pipe(
        filter(() => !!this.isPlaying),
        timeout(10000),
        catchError(error => {
          if (error.name === 'TimeoutError') {
            return of('Timeout reached');
          }
          throw error;
        }))
      .subscribe(
        () => {
          this.cd.detectChanges();
          this.onPlaying.emit();
        },
      );

  }

  public getCameraStatus(cameraId: string): Observable<PulsationModels.ComponentStatus> {
    if (this.accessToken) {
      return of(PulsationModels.ComponentStatus.Online);
    }
    return this.edgeStatusService.getCameraPulsationStatus(cameraId);
  }

  public cameraSnapshot(cameraId: string): Observable<any> {
    return this.camerasService.getCameraSnapshot(cameraId);
  }

  public takeSnapshot(cameraName: string, locationName: string, ts?: number) {
    this.player?.takeSnapshot(cameraName, locationName, ts);
  }

  public zoomIn() {
    return this.player?.zoomIn();
  }

  public zoomOut() {
    return this.player?.zoomOut();
  }

  public resetZoom() {
    return this.player?.resetZoom();
  }

  public getStorage(start: number, end: number) {
    return this.player?.getStorage(start, end);
  }

  public play(data?: { ts?: number, start?: number, end?: number }) {
    return this.player?.play(data);
  }

  public getPreviewCanvas() {
    return this.player?.getPreviewCanvas();
  }

  public zoomArea(area: UiAreaSelection) {
    return this.player?.zoomArea(area);
  }

  public stop(inactive?: boolean, placeholder = false) {
    return this.player?.stop(inactive, placeholder);
  }

  public dragStart(event: MouseEvent) {
    return this.player?.dragStart(event);
  }

  public dragUp() {
    if (this.player?.zoomState?.dragging) {
      this.player.zoomState.dragging = false;
    }
  }

  public drag(event: MouseEvent) {
    return this.player?.drag(event);
  }

  public zoom(event: any) {
    return this.player?.zoom(event);
  }

  public setPlaceholder() {
    return this.player?.setPlaceholder();
  }

  public get isCameraLiveViewPermission(): Observable<boolean> {
    if (this.permissionCheck) {
      return this.store$.pipe(select(PermissionSelectors.checkAccessPermissions([Permissions.CameraLiveView], [this.cameraId, this.edgeId, this.locationId])),
        take(1));
    } else {
      return of(true);
    }

  }
}
