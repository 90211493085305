import { createReducer, on } from '@ngrx/store';
import { PricingActions } from '@states/pricing/pricing.action-types';
import { LicenseTerms, Pricing, PricingCount, PricingLocation, ResolutionOptions, RetentionOptions } from '@models/pricing.model';

export declare interface PricingState {
  locations: PricingLocation[];
  pricing: Pricing;
  count: PricingCount;
  term: LicenseTerms;
}

export const initialState: PricingState = {
  locations: [],
  pricing: {
    boxes: 0,
    license: 0,
    cameras: 0,
    cloud: 0,
    total: 0,
  },
  count: {
    box2tb: 0,
    box4tb: 0,
    cameras5mp: 0,
    cameras8mp: 0,
    license: 0,
    cloudStorage: 0,
    cloudSmartStorage: 0,
    cloudFlowTagging: 0,
  },
  term: LicenseTerms.Years3,
};

export const pricingStateReducer = createReducer(
  initialState,
  on(PricingActions.resetToInitialState, state => {
    return {
      ...initialState,
    };
  }),
  on(PricingActions.setTerm, (state, {term}) => {
    return {
      ...state,
      term,
    };
  }),
  on(PricingActions.addLocation, (state, {location}) => {
    const locations = [...state.locations, location];
    return {
      ...state,
      locations,
    };
  }),
  on(PricingActions.editLocation, (state, {index, location}) => {
    const locations = [...state.locations];
    locations[index] = location;
    return {
      ...state,
      locations,
    };
  }),
  on(PricingActions.removeLocation, (state, {index}) => {
    const locations = [...state.locations];
    locations.splice(index, 1);
    return {
      ...state,
      locations,
    };
  }),
  on(PricingActions.setPricing, (state, {pricing, count}) => {
    return {
      ...state,
      pricing,
      count,
    };
  }),
  on(PricingActions.incrementCount, (state, {field}) => {
    const count = state.count;
    const update = count[field] + 1;
    return {
      ...state,
      count: {
        ...count,
        [field]: update,
      },
    };
  }),
  on(PricingActions.decrementCount, (state, {field}) => {
    const count = state.count;
    const update = count[field] - 1;
    return {
      ...state,
      count: {
        ...count,
        [field]: update,
      },
    };
  }),
  // on(PricingActions.setPricing, (state, { pricingState }) => {
  //   return {
  //     ...state,
  //     ...pricingState,
  //   };
  // }),
);
