<div class="location-module">
  <ui-header [breadCrumbs]="breadCrumb" *ngrxLet="viewType$; let viewType">
    <div class="flex-row gap-8 header-actions">
      @switch (viewType) {
        @case (ViewType.List) {
          <span class="ui-icon-floor-view ui-mat-icon-button-16"
                (click)="setView(ViewType.Floors)"
                matTooltip="Floor plan view"
                matTooltipClass="sidebar-tooltip"
          ></span>
        }
        @case (ViewType.Floors) {
          <span class="ui-icon-list-view ui-mat-icon-button-16"
                (click)="setView(ViewType.List)"
                matTooltip="List view"
                matTooltipClass="sidebar-tooltip"
          ></span>
        }
      }

      <span class="ui-icon-add-location ui-mat-icon-button-16" *ngIf="isLocationCreatePermission | async"
            (click)="addLocation()"
            matTooltip="Add location"
            matTooltipClass="sidebar-tooltip"
      ></span>
    </div>
  </ui-header>
  <div class="w-100 h-100 router-container">
    <router-outlet></router-outlet>
  </div>
</div>
