import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MultiPlaybackState } from './multi-playback.reducer';
import { MultiPlaybackData, MultiPlaybackMove } from '@models/multi-playback.model';

export const selectMultiPlaybackState = createFeatureSelector<MultiPlaybackState>('multiPlaybackState');
export const selectCameraEvents = createSelector(selectMultiPlaybackState, ({cameraEvents}: MultiPlaybackState) => cameraEvents);
export const selectCameraStorageStats = createSelector(selectMultiPlaybackState, ({cameraStorageStats}: MultiPlaybackState) => cameraStorageStats);
export const selectSelectedCameras = createSelector(selectMultiPlaybackState, ({selectedCameras}: MultiPlaybackState) => selectedCameras);
export const selectPositions = createSelector(selectMultiPlaybackState, ({positions}: MultiPlaybackState) => positions);
export const selectSelectedCamerasCount = createSelector(selectMultiPlaybackState, ({selectedCameras}: MultiPlaybackState) => {
  return selectedCameras.filter((camera) => !!camera).length;
});


export const selectData = createSelector(selectMultiPlaybackState, (
  {startTime, endTime, timezone, offsetResInDurations, duration, base}: MultiPlaybackState) => {
  const data: MultiPlaybackData = {
    duration,
    base,
    timezone,
    startTime,
    endTime,
    offsetResInDurations,
  };
  return data;
});

export const selectMove = createSelector(selectMultiPlaybackState, ({timestamp, percentage}: MultiPlaybackState) => {
  const move: MultiPlaybackMove = {
    timestamp,
    percentage,
  };
  return move;
});

