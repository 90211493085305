<!--<mat-card class="mat-elevation-z4" (mouseenter)="loadThumbnails()">-->
<ng-container *ngrxLet="selectIsDeveloper$; let isDeveloper">
  <ng-container *ngIf="isDialog; else default">
    <ng-container *ngrxLet="selectCameraEvents$; let cameraEvents">
      <ng-container *ngrxLet="selectedCamerasCount$; let camerasCount">
        <ng-container *ngrxLet="selectedPositions$; let positions">
          <!------- DIALOG ----->
          <div fxLayout="column" class="dialog" fxFlexFill>
            <ng-container *ngIf="!isLiveView">
              <div *ngIf="!isAlertPlayback"
                   fxLayout="row"
                   fxLayoutAlign="space-between center"
                   class="w-100 top-options"
              >
                <label>{{ name ?? (cameraName$ | async) }} preview</label>
                <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="30px">
                  <div fxLayout="column" fxLayoutAlign="start start">
                    <!--                <div>{{ date }}</div>-->
                  </div>
                  <div fxLayout="column" fxLayoutAlign="start start">
                    <label>{{ period }} {{ timezoneAbbreviation() }}</label>
                  </div>
                </div>
              </div>
              <div *ngIf="!isAlertPlayback" class="thumbnail-top-menu flex-row space-between-center w-100">
                <div class="video-toggle flex-row start-center gap-10">
                  <div
                    fxLayoutAlign="center center"
                    class="video-toggle-button"
                    fxLayoutGap="8px"
                    (click)="selectImagesSection()"
                    fxFlex=""
                    [ngClass]="{
            'selected svg-fill-primary': selectedSection === ThumbnailSection.IMAGES
          }"
                  >
                    <label>Images</label>
                  </div>
                  <div
                    fxLayoutAlign="center center"
                    [class.disabled]="!(displayVideo && isCameraPlaybackPermission|ngrxPush)"
                    class="video-toggle-button"
                    fxLayoutGap="8px"
                    fxFlex=""
                    (click)="play()"
                    [ngClass]="{
            'selected svg-fill-primary': selectedSection === ThumbnailSection.VIDEO
          }"
                  >
                    <label>Video</label>
                  </div>
                  <div
                    *ngIf="!cameraView"
                    [class.section-disabled]="!(!!_objects?.length && isOneDisplayed(_objects))"
                    [class.disabled]="!(!!alertId || !!searchEvents)"
                    matTooltip="No objects detected"
                    [matTooltipDisabled]="!!_objects?.length && isOneDisplayed(_objects)"
                    matTooltipClass="sidebar-tooltip"
                    fxLayoutAlign="center center"
                    class="video-toggle-button"
                    fxLayoutGap="8px"
                    (click)="selectBestImageSection()"
                    fxFlex=""
                    [ngClass]="{
            'selected svg-fill-primary': selectedSection === ThumbnailSection.BEST_IMAGE
          }"
                  >
                    <label>Objects</label>
                  </div>
                  <div *ngIf="!cameraView" [class.disabled]="!(!!searchEvents?.length)"
                       [class.section-disabled]="!(!!_objects?.length && isFaces(_objects))"
                       matTooltip="No faces detected"
                       [matTooltipDisabled]="!!_objects?.length && isFaces(_objects)"
                       matTooltipClass="sidebar-tooltip"
                       fxLayoutAlign="center center"
                       class="video-toggle-button"
                       fxLayoutGap="8px"
                       (click)="selectFacesSection()"
                       fxFlex=""
                       [ngClass]="{
            'selected svg-fill-primary': selectedSection === ThumbnailSection.FACES
          }"
                  >
                    <label>Faces</label>
                  </div>
                  <div *ngIf="!cameraView" [class.disabled]="!(!!alertId && checkPermissionEnabled)"
                       fxLayoutAlign="center center"
                       class="video-toggle-button"
                       fxLayoutGap="8px"
                       (click)="selectAlertActivitySection()"
                       fxFlex=""
                       [ngClass]="{
            'selected svg-fill-primary': selectedSection === ThumbnailSection.ALERT_ACTIVITY
          }"
                  >
                    <div>Activity</div>
                  </div>
                  <!-- Deprecated developer debug -->
                  <div
                    *ngIf="false && isDeveloper"
                    [class.disabled]="!(selectedSection === ThumbnailSection.VIDEO && displayVideo)"
                    fxLayout="row"
                    fxLayoutGap="8px"
                  >
                    <!--                  <ui-toggle [(ngModel)]="playbackWebrtc"></ui-toggle>-->
                    <!--                  <div>WebRTC</div>-->
                    <ui-toggle [(ngModel)]="playbackWebrtcSeek"></ui-toggle>
                    <label>WebRTC Seek</label>
                  </div>
                </div>
                <div class="flex-row start-center gap-10">
                <span
                  *ngIf="!isSharedAlert && isCreateArchivePermission|ngrxPush"
                  class="ui-icon-archive ui-mat-icon-button-16 c-p"
                  matTooltip="Create archive"
                  matTooltipClass="sidebar-tooltip"
                  (click)="showCreateArchiveModal()"
                ></span>
                  <span
                    *ngIf="isShareVisible && isCameraPlaybackSharePermission|ngrxPush"
                    class="ui-icon-share ui-mat-icon-button-16 c-p"
                    matTooltip="Share alert"
                    matTooltipClass="sidebar-tooltip"
                    (click)="share()"
                  ></span>
                  <span
                    *ngIf="!isSharedAlert"
                    class="ui-icon-add-camera ui-mat-icon-button-16 c-p"
                    [matTooltip]="camerasCount === 1 ? 'Add cameras' : 'Edit cameras'"
                    matTooltipClass="sidebar-tooltip"
                    (click)="selectCameras()"
                  ></span>
                  <ng-container
                    *ngIf="selectedSection === ThumbnailSection.IMAGES || selectedSection === ThumbnailSection.VIDEO"
                  >
                  <span
                    *ngIf="camerasCount > 0"
                    class="ui-icon-fullscreen-2 ui-mat-icon-button-16 c-p"
                    matTooltipClass="sidebar-tooltip"
                    matTooltip="Fullscreen"
                    (click)="maximize()"
                  ></span>
                    <span
                      *ngIf="!isSharedAlert && (camerasCount > 0 && selectedSection === ThumbnailSection.IMAGES)"
                      class="ui-icon-layout ui-mat-icon-button-16 c-p"
                      matTooltipClass="sidebar-tooltip"
                      matTooltip="Change layout"
                      [matMenuTriggerFor]="layoutSelector"
                    ></span>
                    <mat-menu #layoutSelector="matMenu" xPosition="before" yPosition="below">
                      <app-thumbnail-layouts-selector [thumbnail]="true"
                                                      (selectedLayout)="changeLayout($event)"
                      ></app-thumbnail-layouts-selector>
                    </mat-menu>

                  </ng-container>
                </div>
              </div>
            </ng-container>
            <!--        <ng-container *ngIf="!isVideo; else video">-->
            <div #mainWrapper fxLayout="column"
                 fxLayoutAlign="start center" fxFlex=""
            >
              <div *ngIf="selectedSection === ThumbnailSection.LIVE_VIEW">
                <app-live-view-camera *ngIf="!!liveViewCamera"
                                      [allowQuality]="true"
                                      #wallCamera
                                      [camera]="liveViewCamera"
                ></app-live-view-camera>
              </div>
              <div
                fxLayout="column"
                fxLayoutAlign="start center"
                class="view-container-wrapper"
                [class.fullscreen]="isFullscreen"
                [class]="!isAlertPlayback?'':''"
                [class.hidden]="selectedSection !== ThumbnailSection.IMAGES"
                fxFlex=""
              >
                <div
                  #container
                  class="thumbnail-container c-p"
                  (mousemove)="false && camerasCount === 1 ? move($event) : ''"
                  (mouseleave)="false && camerasCount === 1 ? reset() : ''"
                  (click)="false && camerasCount === 1 ? play() : ''"
                  [class.maximized]="isFullscreen"
                  fxFlexFill
                  [style.height.px]="(container.clientWidth * 9) / 16"
                  [style]="'max-height: calc(100vh - ' + (timelineWrapper?.nativeElement?.clientHeight + 200) + 'px) !important'"

                >
                  <div *ngIf="isFullscreen" fxLayoutAlign="center center" class="maximize-button">
                    <div matTooltip="Go to fullscreen" (click)="maximize(); $event.stopPropagation();">
                      <mat-icon>{{ isFullscreen ? 'fullscreen_exit' : 'fullscreen' }}</mat-icon>
                    </div>
                  </div>
                  <!--      <div class="thumbnail-overlay" fxFlexFill></div>-->
                  <div class="thumbnail-time-overlay" *ngIf="false">{{ time }}</div>
                  <ng-container *ngIf="false">
                    <div #eventParent *ngIf="!isAlertPlayback" class="thumbnail-events" style="z-index: 99"
                    >
                      <app-events-histogram *ngIf="false"
                                            [rtl]="rtl"
                                            [events]="events"
                                            [searchEvents]="searchEvents"
                                            [startTs]="optionsInput.startTime"
                                            [endTs]="optionsInput.endTime"
                                            [parent]="eventParent"
                      ></app-events-histogram>
                    </div>
                    <div #timeline class="thumbnail-timeline-overlay" [class.multiple]="camerasCount > 0"></div>
                    <div class="thumbnail-offline-overlay">Offline</div>
                    <div #scroller class="thumbnail-timeline-scroller" *ngIf="scrollerPosition"
                         [ngStyle]="{ 'left.px': scrollerPosition }"
                         [class.multiple]="camerasCount > 0"
                    >
                      <div
                        class="thumbnail-timeline-scroller-time"
                        fxLayoutAlign="center center"
                        *ngIf="camerasCount > 0"
                        [style.left]="scroller.offsetLeft < 40 ? - 20 - scroller.offsetLeft + 'px'  :
                (timeline.clientWidth - scroller.offsetLeft < 40 ? - 100 + (timeline.clientWidth - scroller.offsetLeft) + 'px' : '-60px')"
                      >
                        <div>{{ time }} {{ timezoneAbbreviation() }}</div>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container>
                    <div #layoutsWrapper
                         fxLayout="column"
                         fxLayoutAlign="start center"
                         [fxLayoutGap]=" displayCount > 1 ? '30px' : ''"
                         class="p-r w-100 h-100"
                    >
                      <div class="w-100 h-100 p-r">
                        <div *ngIf="displayCount === 1 && !noStorage && !playbackError"
                             class="play-button-wrapper"
                             fxFlexFill
                             fxLayoutAlign="center center"
                             (click)="play()"
                        >
                          <mat-icon svgIcon="playback-play"></mat-icon>
                        </div>

                        <thumbnail-layouts [layout]="layout"
                                           [selectedCameras]="selectedCameras"
                                           (selectCamera)="selectCameras($event)"
                                           (deleteCamera)="deleteCamera($event);"
                                           [imageSection]="selectedSection === ThumbnailSection.IMAGES"
                                           [fixedRange]="isSharedAlert"
                        ></thumbnail-layouts>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
              <div fxLayout="column" fxLayoutAlign="start center" class="w-100 p-r" #playbackWrapper>
                <app-playback-player
                  class="view-container-wrapper w-100" [class.camera-view]="isLiveView"
                  [class.fullscreen]="isFullscreen"
                  [permissionCheck]="checkPermissionEnabled"
                  #playback
                  [cameraName]="cameraName$ | async"
                  [cameraId]="cameraId"
                  [startTime]="options.startTime"
                  [endTime]="options.endTime"
                  [edgeId]="edgeId"
                  [time]="currentTs"
                  [duration]="playerDuration"
                  [webrtc]="playbackWebrtc"
                  [webrtcSeek]="playbackWebrtcSeek"
                  [videoSection]="selectedSection === ThumbnailSection.VIDEO"
                  [processing]="alertProcessing"
                  [processingTime]="roundToNext5Seconds(alertProcessingTime / 1000)"
                  fxFlex="100"
                  [aspectRatio]="aspectRatio"
                  [ngClass]="{
          hidden: selectedSection !== ThumbnailSection.VIDEO
        }"
                  (emulate)="emulate()"
                  (noMoreEvent)="noMore($event)"
                  (clickPlay)="play()"
                  (clickPause)="pause(true, true)"
                  [isLiveView]="streamLiveView"
                  [isCameraView]="isLiveView"
                  (clickFullScreen)="maximize()"
                  (toggleLiveView)="toggleToLiveView()"
                  [playerWidth]="container?.clientWidth"
                  (pauseEmit)="pause(false, true)"
                ></app-playback-player>
                <div #timelineWrapper
                     class="timeline-wrapper w-100"
                     fxFlex=""
                     [class.fullscreen]="isFullscreen"
                     [class.camera-view]="isLiveView"
                     [class.absolute]="isFullscreen && selectedSection === ThumbnailSection.VIDEO"
                     *ngIf="selectedSection === ThumbnailSection.IMAGES || selectedSection === ThumbnailSection.VIDEO || selectedSection === ThumbnailSection.LIVE_VIEW"
                >
                  <timeline appVisibilityChange (visibilityChanged)="checkHiddenDocument($event)"
                            #timeline
                            class="w-100"
                            [startTime]="optionsInput.startTime"
                            *ngIf="!_reset"
                            [endTime]="optionsInput.endTime"
                            [layout]="layout"
                            [rtl]="rtl"
                            [searchEvents]="searchEvents"
                            [timezone]="thumbnailsData.timezone"
                            [videoCurrentTime]="(playback?.webrtcPlayer?.currentTime ?? 0) * 1000"
                            [zoomEnabled]="true"
                            (seekStart)="seekStart()"
                            (timeChanged)="timelineDrag($event)"
                            (timelineDragEnd)="timelineDragEnd($event)"
                            (timelineDragStart)="timelineDragStart()"
                            (seekEnd)="seekEnd($event)"
                            [events]="events"
                            [videoSection]="selectedSection === ThumbnailSection.VIDEO"
                            [fixedRange]="isSharedAlert"
                            (pause)="pause(true, true)"
                            (play)="play()"
                            [isPlaying]="!playback.paused"
                            [isAlert]="!!alertName"
                            [initTs]="initialTs"
                            [isCameraView]="isLiveView"
                            [streamLiveView]="streamLiveView"
                            (toggleToLiveView)="toggleToLiveView()"
                            (showCreateArchiveModal)="showCreateArchiveModal()"
                  >

                  </timeline>
                </div>
              </div>
            </div>
            <div fxLayout="column"
                 *ngIf="!!_objects?.length && selectedSection === ThumbnailSection.BEST_IMAGE || selectedSection === ThumbnailSection.FACES"
                 fxLayoutGap="0px"
                 class="objects-wrapper p-25"
            >
              <ng-container *ngIf="selectedSection === ThumbnailSection.FACES ? _faces : _objects as objArray">
                <div *ngFor="let object of objArray; let i = index"
                     fxLayout="row wrap"
                     fxLayoutAlign="space-around center"
                     fxLayoutGap="8px grid"
                >
                  <ng-container *ngIf="i % 4 === 0">
                    <ng-container *ngFor="let idx of [0, 1, 2, 3]">
                      <ng-container
                        *ngIf="selectedSection === ThumbnailSection.BEST_IMAGE && objArray[i+idx]?.type === SearchObjectTypes.VEHICLE; else notVehicle"
                      >
                        <div fxLayout="row" fxLayoutAlign="start start" class="w-100" fxLayoutGap="6px">
                          <div
                            *ngIf="_objects[i + idx]?.url"
                            fxLayoutAlign="center center"
                            class="best-image-wrapper"
                            fxFlex=""
                            style="height: 40vh; max-height: 40vh; background: #70908e12"
                          >
                            <img #best
                                 style="height: 100%; width: auto"
                                 *ngIf="bestImageUri(_objects[i + idx]?.url)"
                                 [src]="bestImageUri(_objects[i + idx]?.url)"
                            />
                            <div *ngIf="isDeveloper && !!_objects[i + idx]?.score" class="object-score">
                              {{ +_objects[i + idx]?.score | number : '1.0-4' }}
                            </div>
                            <div fxLayout="row"
                                 fxLayoutAlign="start center"
                                 class="best-image-actions"
                                 fxLayoutGap="16px"
                            >
                              <div
                                *ngIf="!isSharedAlert && !!_objects[i + idx].zoomImage"
                                (click)="track(_objects[i + idx], true)" matTooltip="Track face"
                                matTooltipClass="sidebar-tooltip"
                                fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px"
                                class="button svg-fill-white"
                              >
                                <mat-icon svgIcon="object-icon-person"></mat-icon>
                              </div>
                              <div
                                *ngIf="!isSharedAlert && ((allowTrack && type === ThumbnailDialogType.ALERT) || !!_objects[i + idx].descriptor)"
                                (click)="track(_objects[i + idx])"
                                matTooltip="Track object"
                                matTooltipClass="sidebar-tooltip"
                                fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px"
                                class="button svg-fill-white"
                              >
                                <mat-icon svgIcon="track"></mat-icon>
                              </div>
                            </div>
                          </div>
                          <div
                            *ngIf="_objects[i + idx]?.zoomImage"
                            fxLayoutAlign="center center"
                            class="best-image-wrapper"
                            fxFlex="25"
                            style="height: 40vh; max-height: 40vh; min-width: 50%; background: #70908e12"
                          >
                            <ui-img-ratio *ngIf="_objects[i + idx]?.zoomImage"
                                          [src]="bestImageUri(_objects[i + idx]?.zoomImage)"
                            ></ui-img-ratio>
                            <!--                            <img #best-->
                            <!--                                 style="height: auto; width: auto; max-width: 100%; max-height: 100%;"-->
                            <!--                                 *ngIf="_objects[i + idx]?.zoomImage"-->
                            <!--                                 [src]="bestImageUri(_objects[i + idx]?.zoomImage)"-->
                            <!--                            />-->
                          </div>
                        </div>
                      </ng-container>
                      <ng-template #notVehicle>
                        <div fxLayout="row" fxLayoutAlign="start start"
                             [style.width]="'50%'"
                             fxLayoutGap="6px"
                        >
                          <div
                            *ngIf="(selectedSection === ThumbnailSection.BEST_IMAGE && objArray[i + idx]?.url) ||
                          (selectedSection === ThumbnailSection.FACES && objArray[i + idx]?.zoomImage)"
                            fxLayoutAlign="center center"
                            class="best-image-wrapper"
                            fxFlex=""
                            style="height: 40vh; max-height: 40vh; background: #70908e12"
                          >
                            <ui-img-ratio #best
                                          *ngIf="selectedSection === ThumbnailSection.BEST_IMAGE && bestImageUri(objArray[i + idx]?.url)"
                                          [src]="bestImageUri(objArray[i + idx]?.url)"
                            />

                            <ui-img-ratio #faceBest
                                          *ngIf="selectedSection === ThumbnailSection.FACES && bestImageUri(objArray[i + idx]?.zoomImage)"
                                          [src]="bestImageUri(objArray[i + idx]?.zoomImage)"
                            />
                            <div *ngIf="isDeveloper && !!objArray[i + idx]?.score" class="object-score">
                              {{ +objArray[i + idx]?.score | number : '1.0-4' }}
                            </div>
                            <div fxLayout="row"
                                 fxLayoutAlign="start center"
                                 class="best-image-actions"
                                 fxLayoutGap="8px"
                                 *ngrxLet="getPerson(objArray[i + idx]?.groupId); let person"
                            >
                              <div *ngIf="!isSharedAlert && (selectedSection === ThumbnailSection.FACES && !!objArray[i + idx]?.zoomImage && !!objArray[i + idx]?.id) && objArray[i + idx]?.groupId > 0"
                                   class="button"
                                   [matTooltip]="(getPersonName(objArray[i+idx]?.groupId) | async) + ' is not shown here, remove'"
                                   matTooltipClass="sidebar-tooltip"
                                   fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px"
                                   class="button svg-fill-white"
                                   (click)="deleteFace(objArray[i + idx])"
                              >
                                <span class="ui-icon-dislike"></span>
                              </div>
                              <div
                                *ngIf="!isSharedAlert && (selectedSection === ThumbnailSection.FACES && !!objArray[i + idx]?.zoomImage && !!objArray[i + idx]?.id) && (!objArray[i + idx]?.groupId || objArray[i + idx]?.groupId === -1)"

                                class="button"
                                matTooltip="Add person"
                                matTooltipClass="sidebar-tooltip"
                                fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px"
                                class="button svg-fill-white"
                                (click)="addFace(objArray[i + idx])"
                              >
                                <span class="ui-icon-add-person"></span>
                              </div>
                              <div
                                *ngIf="!isSharedAlert && (selectedSection === ThumbnailSection.FACES && !!objArray[i + idx].zoomImage)"
                                (click)="track(objArray[i + idx], true)"
                                matTooltip="Track face"
                                matTooltipClass="sidebar-tooltip"
                                fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px"
                                class="button svg-fill-white"
                              >
                                <!--                                <mat-icon svgIcon="object-icon-person"></mat-icon>-->
                                <mat-icon svgIcon="track"></mat-icon>
                              </div>

                              <div
                                *ngIf="!isSharedAlert && ((allowTrack && type === ThumbnailDialogType.ALERT) || !!objArray[i + idx].descriptor && selectedSection === ThumbnailSection.BEST_IMAGE)"
                                (click)="track(objArray[i + idx])"
                                matTooltip="Track object"
                                matTooltipClass="sidebar-tooltip"
                                fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px"
                                class="button svg-fill-white"
                              >
                                <mat-icon svgIcon="track"></mat-icon>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-template>
                      <!--                        <div-->
                      <!--                          *ngIf="objArray[i + idx]?.zoomImage && !(selectedSection === ThumbnailSection.FACES)"-->
                      <!--                          fxLayoutAlign="center center"-->
                      <!--                          class="best-image-wrapper"-->
                      <!--                          fxFlex="25"-->
                      <!--                          style="height: 40vh; max-height: 40vh; min-width: 50%; background: #70908e12"-->
                      <!--                        >-->
                      <!--                          <img-->
                      <!--                            style="height: auto; width: auto; max-width: 100%; max-height: 100%;"-->
                      <!--                            *ngIf="objArray[i + idx]?.zoomImage"-->
                      <!--                            [src]="bestImageUri(objArray[i + idx]?.zoomImage)"-->
                      <!--                          />-->
                      <!--                        </div>-->

                    </ng-container>
                  </ng-container>
                </div>
              </ng-container>
            </div>
            <!-- DEPRECATED -->
            <div *ngIf="false && containsPeople && !!people?.length"
                 class="detected-faces"
                 fxLayout="column"
                 fxLayoutAlign="start start"
                 fxLayoutGap="8px"
                 fxFlex=""
            >
              <div class="detected-faces-title">Detected faces</div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
                <ng-container *ngFor="let person of people">
                  <ui-person-icon (add)="addPerson(person)"
                                  (addExisting)="addExisting(person)"
                                  [person]="person"
                                  [small]="true"
                                  [allowDelete]="true"
                                  (delete)="deleteFromTracker(person)"
                  ></ui-person-icon>
                </ng-container>
              </div>
            </div>
            <div fxLayout="column"
                 *ngIf="!!alertId && selectedSection === ThumbnailSection.ALERT_ACTIVITY"
                 fxLayoutGap="0px"
                 class="objects-wrapper p-25"
            >
              <div #scrollContainer
                   class="virtual-scroll-container"
                   fxFlex
                   appElementLoaded
                   (onLoaded)="onLoaded($event)"
              >

                <section class="virtual-scroll-table w-100">
                  <header>
                    <div class="col flex">
                      <div class="inner">
                        Action
                      </div>
                    </div>
                    <div class="col flex">
                      <div class="inner">
                        Detail
                      </div>
                    </div>
                    <div class="col flex">
                      <div class="inner">
                        User
                      </div>
                    </div>
                    <div class="col flex">
                      <div class="inner">Time
                      </div>
                    </div>
                  </header>
                  <ng-container *ngIf="!loadingAlertActivity; else loader">
                    <div class="body row c-p" *ngFor="let row of alertActivity">
                      <div class="col flex ">
                        <div class="inner">
                          <ui-truncated-html>
                            {{ alertActivityTypeMap[row.action] }}
                          </ui-truncated-html>
                        </div>
                      </div>
                      <div class="col flex">
                        <div class="inner">
                          <ui-truncated-html>
                            {{ row.details }}
                          </ui-truncated-html>
                        </div>
                      </div>
                      <div class="col flex">
                        <div class="inner">
                          {{ userEmailByUserId(row.userId) | ngrxPush }}
                        </div>
                      </div>
                      <div class="col flex">
                        <div class="inner">
                          <ui-user-settings-date [date]="row.timestamp"></ui-user-settings-date>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="alertInstanceId" class="body row c-p">
                      <div class="col flex ">
                        <div class="inner">
                          Alert
                        </div>
                      </div>
                      <div class="col flex">
                        <div class="inner">
                          <ui-truncated-html>
                            {{ alertInstanceId }}
                          </ui-truncated-html>
                        </div>
                      </div>
                      <div class="col flex">
                        <div class="inner">
                          -
                        </div>
                      </div>
                      <div class="col flex">
                        <div class="inner">
                          {{ alertTs | date: 'yyyy MMM dd HH:mm a' }} {{ timezoneAbbreviation() }}
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <!--                  <ng-template #noDataResult>-->
                  <!--                    <ui-no-data class="alert-activity-placeholder-wrapper"-->
                  <!--                                [title]="noResults.title"-->
                  <!--                                [description]="noResults.description"-->
                  <!--                                [createButtonText]="noResults.createButtonText"-->
                  <!--                    >-->
                  <!--                    </ui-no-data>-->
                  <!--                  </ng-template>-->
                  <ng-template #loader>
                    <ui-preloader class="alert-activity-placeholder-wrapper"
                                  [color]="PreloaderColor.blue"
                                  [diameter]="35"
                    ></ui-preloader>
                  </ng-template>
                </section>

              </div>

            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
  <!------- DEFAULT ----->
  <ng-template #default>
    <ng-container *ngIf="thumbnailTemplate === ThumbnailTemplate.TEMPLATE1">
      <mat-card class="mat-elevation-z4" (click)="allowDialogOpen?openDialog():null">
        <mat-card-content>
          <div
            #smallThumb
            [style.height.px]="(240 * 9) / 16"
            [class.isFullscreen]="isFullscreen"
            class="thumbnail-container c-p p-r"
            (mousemove)="move($event)"
            (mouseleave)="reset()"
          >
            <mat-icon class="fullscreen-icon" (click)="maximize(smallThumb);$event.stopPropagation()">fullscreen
            </mat-icon>
            <div class="thumbnail-overlay" fxFlexFill></div>
            <div *ngIf="showEventsCounter" class="thumbnail-total-events-overlay" fxLayoutAlign="center center">
              {{ thumbHistogram.eventsCount }}
            </div>
            <div class="thumbnail-time-overlay">{{ time }} {{ timezoneAbbreviation() }}</div>
            <div #histWrapper class="thumbnail-events">
              <!--              <app-events-histogram-->
              <!--                [rtl]="rtl"-->
              <!--                [events]="events"-->
              <!--                [searchEvents]="searchEvents"-->
              <!--                [startTs]="optionsInput.startTime"-->
              <!--                [endTs]="optionsInput.endTime"-->
              <!--                [vertical]="false"-->
              <!--                [parentWidth]="histWrapper.clientWidth"-->
              <!--              ></app-events-histogram>-->
              <app-thumbnail-histogram #thumbHistogram
                                       [parent]="histWrapper"
                                       [start]="optionsInput.startTime"
                                       [end]="optionsInput.endTime"
                                       [cameraId]="cameraId"
                                       [edgeId]="edgeId"
                                       [preview]="true"
                                       [isCameraView]="isLiveView"
                                       class="w-100"
              ></app-thumbnail-histogram>
            </div>
            <div class="thumbnail-timeline-overlay"></div>
            <div class="thumbnail-timeline-scroller" [ngStyle]="{ 'left.px': scrollerPosition }"></div>
            <div fxLayout="column" fxLayoutAlign="center center" class="thumbnail-offline-overlay"
                 [ngClass]="{ show: offline }"
            >
              <span class="ui-icon-camera-off"></span>
            </div>
            <img #image class="card-image" [lazyLoad]="img"
                 (onStateChange)="imgStateChange($event)"
            />
          </div>
          <div fxLayout="column" style="color: #151f2b">
            <div mat-ripple
                 class="period thumbnail-details"
                 matTooltip="Focus on this time range"
                 matTooltipClass="sidebar-tooltip"
            >
              <!--            <ng-container *ngIf="displayName">-->
              <!--              <div class="date-label">Camera:</div>-->
              <!--              <div class="bold">{{ cameraName$ | async }}</div>-->
              <!--            </ng-container>-->
              <!--            <div class="date-label">Time:</div>-->
              <!--            <div>{{ date }}</div>-->
              <!--            <div class="date-label">Period</div>-->
              <!--            <div>{{ period }} {{ timezoneAbbreviation() }}</div>-->
              <div (click)="onPeriodClick($event)" fxLayout="column" fxLayoutGap="14px">
                <div fxLayout="row" fxLayoutGap="10px">
                  <div class="date-label">Time:</div>
                  <div class="date-value">{{ date }}</div>
                </div>
                <div fxLayout="row" fxLayoutGap="10px" class="clickable">
                  <div class="date-label period-details">Period:</div>
                  <div class="period-details">{{ period }} {{ timezoneAbbreviation() }}</div>
                </div>
              </div>
            </div>
            <div fxLayout="column" class="thumbnail-debug" *ngIf="false">
              <div>Name: {{ cameraName$ | async }}</div>
              <div>Base: {{ this.base24 }}</div>
              <div>Start: {{ this.base | date : 'EEE, MMM dd, yyyy HH:mm:ss' }}</div>
              <div>EventsLength: {{ this.events.length }}</div>
              <div>Total Events > 1: {{ this.countEvents() }}</div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </ng-container>
    <ng-container *ngIf="thumbnailTemplate === ThumbnailTemplate.TEMPLATE2">
      <mat-card class="mat-elevation-z4 template2" (click)="allowDialogOpen?openDialog():false">
        <mat-card-content>
          <div
            #smallThumb
            [style.height.px]="(smallThumb.clientWidth * 9) / 16"
            class="thumbnail-container c-p"
            (mousemove)="move($event)"
            (mouseleave)="reset()"
          >
            <ng-container *ngIf="showObjects && !!_objects?.length && isOneDisplayed(_objects)">

              <div fxLayout="column"
                   fxLayoutAlign="start center"
                   fxLayoutGap="3px"
                   fxFlexFill
                   style="background: #eee"
                   class="h-100 w-100"
              >
                <div class="more-objects"
                     *ngIf="_objects?.length > 4"
                >{{ _objects?.length - 4 }} More
                </div>
                <!--              <div *ngIf="containsPeople">CONTAINS PEOPLE</div>-->
                <div fxLayout="row" fxLayoutAlign="space-around center" fxFlex="" class="w-100 h-100" fxLayoutGap="3px">
                  <div *ngIf="_objects[0]?.url"
                       fxFlex=""
                       class="w-100 h-100 thumb-object"
                       [style.background-image]="'url('+ bestImageUri(_objects[0].url) + ')'"
                  >
                  </div>
                  <div *ngIf="_objects[1]?.url"
                       fxFlex=""
                       class="w-100 h-100 thumb-object"
                       [style.background-image]="'url('+ bestImageUri(_objects[1].url) + ')'"
                  ></div>
                </div>
                <div *ngIf="_objects.length > 2"
                     fxLayout="row"
                     fxLayoutAlign="space-around center"
                     fxFlex=""
                     class="w-100 h-100"
                     fxLayoutGap="3px"
                >
                  <div *ngIf="_objects[2]?.url"
                       fxFlex=""
                       class="w-100 h-100 thumb-object"
                       [style.background-image]="'url('+ bestImageUri(_objects[2].url) + ')'"
                  ></div>
                  <div *ngIf="_objects[3]?.url"
                       fxFlex=""
                       class="w-100 h-100 thumb-object"
                       [style.background-image]="'url('+ bestImageUri(_objects[3].url) + ')'"
                  ></div>
                </div>

              </div>

            </ng-container>
            <ng-container *ngIf="showObjects && (!_objects?.length || !isOneDisplayed(_objects))">
              <div fxLayout="column"
                   fxLayoutAlign="center center"
                   fxLayoutGap="3px"
                   fxFlexFill
                   style="background: #FCFCFD"
                   class="h-100 w-100"
              >
                <img src="assets/no-object.png" style="width: 60px;">
              </div>
            </ng-container>
            <ng-container *ngIf="!showObjects">
              <div class="thumbnail-overlay" fxFlexFill></div>
              <div *ngIf="showEventsCounter" class="thumbnail-total-events-overlay" fxLayoutAlign="center center">
                <!--                {{ this.countEvents() }}-->
              </div>
              <div class="thumbnail-time-overlay">{{ time }} {{ timezoneAbbreviation() }}</div>
              <div #searchEventsParent *ngIf="!isAlertPlayback" class="thumbnail-events">
                <app-events-histogram
                  [rtl]="rtl"
                  [events]="events"
                  [searchEvents]="searchEvents"
                  [startTs]="optionsInput.startTime"
                  [endTs]="optionsInput.endTime"
                  [vertical]="false"
                  [parent]="searchEventsParent"
                ></app-events-histogram>
              </div>
              <div class="thumbnail-timeline-overlay"></div>
              <div class="thumbnail-timeline-scroller" [ngStyle]="{ 'left.px': scrollerPosition }"></div>
              <div fxLayout="column" fxLayoutAlign="center center" class="thumbnail-offline-overlay"
                   [ngClass]="{ show: offline }"
              >
                <span class="ui-icon-camera-off"></span>
              </div>
              <ui-preloader *ngIf="imageLoader"
                            class="loader"
                            [diameter]="25"
                            [color]="PreloaderColor.blue"
              ></ui-preloader>
              <img #image
                   class="card-image"
                   [lazyLoad]="img"
                   (onStateChange)="imgStateChange($event)"
              />
            </ng-container>
            <div *ngIf="displayName" class="camera-name flex-row start-center gap-4">
              <ui-camera-status [cameraId]="cameraId" [iconSizePx]="16"></ui-camera-status>
              <ui-truncated-html>{{ cameraName$ | async }}</ui-truncated-html>
            </div>
          </div>
          <div fxLayout="column">
            <ng-container *ngrxLet="selectSearchType$; let searchType">
              <div #thumbDetails fxLayout="column"
                   class="thumbnail-details"
                   [class.small]="searchType === SearchType.MOTION"
                   fxLayoutGap="10px"
              >

                <div fxLayout="row"
                     fxLayoutAlign="start center"
                     fxLayoutGap="10px"
                >

                  <div fxLayout="row"
                       fxLayoutAlign="start center"
                       fxLayoutGap="10px"
                       class="period icon-16 p-r"
                       [ngClass]="{ timeIsClickable: timeIsClickable }"
                  >

                    <div (click)="onPeriodClick($event)" fxLayout="column" fxLayoutGap="14px">
                      <div fxLayout="row" fxLayoutGap="10px">
                        <div class="flex-row center-center">
                          <mat-icon svgIcon="clock"></mat-icon>
                        </div>
                        <ui-truncated-html [style.width.px]="thumbDetails?.clientWidth - 50">
                          <ui-user-settings-date [date]="this.options.startTime"
                                                 [showTimeZone]="false"
                          ></ui-user-settings-date>
                          -
                          <ui-user-settings-date [date]="this.options.endTime"
                                                 [timezone]="this.thumbnailsData.timezone"
                          ></ui-user-settings-date>
                        </ui-truncated-html>
                      </div>
                      <div *ngIf="searchType !== SearchType.MOTION"
                           #objectsWrapper
                           fxLayout="row"
                           fxLayoutGap="24px"
                           class="p-r w-100 object-details"
                      >
                        <ng-container *ngIf="objects[0]?.eventMessage">
                          <div class="flex-row gap-8 start-center">
                            @switch (objects[0]?.eventTypeId) {
                              @case (2000002) {
                                <span class="ui-icon-object-loitering"></span>
                              }
                              @case (2000004) {
                                <span class="ui-icon-object-occupancy"></span>
                              }
                              @case (2000000) {
                                <span class="ui-icon-eye"></span>
                              }
                              @case (7) {
                                <span class="ui-icon-object-speed-control"></span>
                              }
                            }
                            <ui-truncated-html [style.max-width.px]="thumbDetails?.clientWidth - 50">{{ objects[0]?.eventMessage }}</ui-truncated-html>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="searchType === SearchType.AI; else customEventsTemplate">
                          <div fxLayout="row" fxLayoutGap="10px" class="p-r"
                               (mouseenter)="peopleTooltip = true" (mouseleave)="peopleTooltip = false"
                               *ngIf="savedPeople?.length || objectAttributes?.personCount"
                          >
                            <div class="flex-col vehicle-tooltip person"
                                 *ngIf="peopleTooltip"
                            >
                              <div class="flex-col start-start gap-8">

                                <ng-container *ngIf="savedPeople?.length">
                                  <div class="flex-row start-center gap-8" *ngFor="let person of savedPeople">
                                    <div class="flex-row center-center svg-fill-white mr-10">
                                      <mat-icon svgIcon="object-icon-person"></mat-icon>
                                    </div>
                                    <div>{{ person?.name | titlecase }}</div>
                                  </div>
                                </ng-container>
                                <div *ngIf="!savedPeople?.length">{{ objectAttributes?.personCount }}
                                  Appearance{{ objectAttributes?.personCount > 1 ? 's' : '' }}
                                </div>
                              </div>
                            </div>
                            <ng-container *ngIf="savedPeople?.length || objectAttributes?.personCount">
                              <mat-icon svgIcon="object-icon-person"></mat-icon>
                              <ui-truncated-html class="flex">
                                <div>{{ (savedPeople?.length ? savedPeople[0]?.name : '' + objectAttributes?.personCount) | titlecase }}</div>
                              </ui-truncated-html>
                              <span class="ui-icon-expand-arrow" *ngIf="savedPeople?.length > 1">
                          </span>
                            </ng-container>

                          </div>
                          <div #vehicleLabel fxLayout="row" fxLayoutGap="10px"
                               class="p-r" (mouseenter)="vehicleTooltip = true"
                               (mouseleave)="vehicleTooltip = false"
                          >
                            <div class="flex-col start-start vehicle-tooltip"
                                 [class.vehicle-no-data]="objectAttributes.vehicles?.length === 0"
                                 *ngIf="vehicleTooltip"
                            >
                              <div class="flex-row start-center svg-fill-white mr-10 gap-8">
                                <mat-icon svgIcon="object-vehicle"></mat-icon>
                                <div>{{ objectAttributes?.vehicleCount }}
                                  Appearance{{ objectAttributes?.vehicleCount > 1 ? 's' : '' }}
                                </div>
                              </div>

                              <div *ngFor="let vehicle of objectAttributes.vehicles" class="flex-row">
                                <div class="flex-row start-center svg-fill-white mr-10">
                                  <mat-icon svgIcon="object-vehicle"></mat-icon>
                                </div>
                                <div
                                  *ngIf="vehicle?.color || vehicle?.make"
                                >{{ vehicle?.color | titlecase }} {{ vehicle?.make | titlecase }}
                                </div>
                                <div *ngIf="vehicle?.plate">, {{ vehicle?.plate | uppercase }}</div>
                                <div *ngIf="vehicle?.region">, {{ vehicle?.region | uppercase }}</div>
                              </div>
                            </div>
                            <ng-container *ngIf="objectAttributes?.vehicleCount">
                              <mat-icon svgIcon="object-vehicle"></mat-icon>
                              <ui-truncated-html class="flex">
                                <div>{{
                                    objectAttributes?.vehicles[0] ?
                                      (objectAttributes?.vehicles[0]?.color ?? '' | titlecase) + ' ' +
                                      (objectAttributes?.vehicles[0]?.make ?? '' | titlecase) + ' ' +
                                      (objectAttributes?.vehicles[0]?.plate ?? '' | uppercase) + ' ' +
                                      (objectAttributes?.vehicles[0]?.region ?? '' | uppercase)
                                      : objectAttributes?.vehicleCount
                                  }}
                                </div>
                              </ui-truncated-html>
                              <span class="ui-icon-expand-arrow" *ngIf="objectAttributes.vehicles?.length > 1">
                        </span>
                            </ng-container>
                          </div>

                        </ng-container>
                        <ng-template #customEventsTemplate>
                          <div fxLayout="row"
                               fxLayoutGap="10px"
                               fxLayoutAlign="start center"
                               class="p-r svg-fill-gray-600 w-100"
                               *ngIf="searchType === SearchType.EVENT_TYPE"
                          >
                            <mat-icon svgIcon="tag"></mat-icon>
                            <ui-truncated-html *ngIf="afterViewInit" class="custom-events-str">
                              {{ customEventsDataStr }}
                            </ui-truncated-html>
                          </div>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <div fxLayout="column" class="thumbnail-debug" *ngIf="false">
              <div>Name: {{ cameraName$ | async }}</div>
              <div>Base: {{ this.base24 }}</div>
              <div>Start: {{ this.base | date : 'EEE, MMM dd, yyyy HH:mm:ss' }}</div>
              <div>EventsLength: {{ this.events.length }}</div>
              <div>Total Events > 1: {{ this.countEvents() }}</div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </ng-container>
    <ng-container *ngIf="thumbnailTemplate === ThumbnailTemplate.TEMPLATE3">
      <div
        #smallThumb
        [style.height.px]="(smallThumb.clientWidth * 9) / 16"
        class="thumbnail-container c-p h-100 w-100"
        (mousemove)="move($event)"
        (mouseleave)="reset()"
      >
        <ng-container *ngIf="showObjects && !!_objects?.length && isOneDisplayed(_objects)">

          <div fxLayout="column"
               fxLayoutAlign="start center"
               fxLayoutGap="3px"
               fxFlexFill
               style="background: #eee"
               class="h-100 w-100"
          >
            <div class="more-objects"
                 *ngIf="_objects?.length > 4"
            >{{ _objects?.length - 4 }} More
            </div>
            <!--              <div *ngIf="containsPeople">CONTAINS PEOPLE</div>-->
            <div fxLayout="row" fxLayoutAlign="space-around center" fxFlex="" class="w-100 h-100" fxLayoutGap="3px">
              <div *ngIf="_objects[0]?.url"
                   fxFlex=""
                   class="w-100 h-100 thumb-object"
                   [style.background-image]="'url('+ bestImageUri(_objects[0].url) + ')'"
              >
              </div>
              <div *ngIf="_objects[1]?.url"
                   fxFlex=""
                   class="w-100 h-100 thumb-object"
                   [style.background-image]="'url('+ bestImageUri(_objects[1].url) + ')'"
              ></div>
            </div>
            <div *ngIf="_objects.length > 2"
                 fxLayout="row"
                 fxLayoutAlign="space-around center"
                 fxFlex=""
                 class="w-100 h-100"
                 fxLayoutGap="3px"
            >
              <div *ngIf="_objects[2]?.url"
                   fxFlex=""
                   class="w-100 h-100 thumb-object"
                   [style.background-image]="'url('+ bestImageUri(_objects[2].url) + ')'"
              ></div>
              <div *ngIf="_objects[3]?.url"
                   fxFlex=""
                   class="w-100 h-100 thumb-object"
                   [style.background-image]="'url('+ bestImageUri(_objects[3].url) + ')'"
              ></div>
            </div>

          </div>

        </ng-container>
        <ng-container *ngIf="showObjects && (!_objects?.length || !isOneDisplayed(_objects))">
          <div fxLayout="column"
               fxLayoutAlign="center center"
               fxLayoutGap="3px"
               fxFlexFill
               style="background: #FCFCFD"
               class="h-100 w-100"
          >
            <img src="assets/no-object.png" style="width: 60px;">
          </div>
        </ng-container>
        <ng-container *ngIf="!showObjects">
          <div class="thumbnail-overlay" fxFlexFill></div>
          <div *ngIf="showEventsCounter" class="thumbnail-total-events-overlay" fxLayoutAlign="center center">
            <!--                {{ this.countEvents() }}-->
          </div>
          <div class="thumbnail-time-overlay">{{ time }} {{ timezoneAbbreviation() }}</div>
          <div #histWrapper *ngIf="!isAlertPlayback" class="thumbnail-events">
            <app-thumbnail-histogram #thumbHistogram
                                     [parent]="histWrapper"
                                     [start]="optionsInput.startTime"
                                     [end]="optionsInput.endTime"
                                     [cameraId]="cameraId"
                                     [edgeId]="edgeId"
                                     [preview]="true"
                                     [isAlertNow]="true"
                                     [isCameraView]="isLiveView"
                                     class="w-100"
            ></app-thumbnail-histogram>
          </div>
          <div class="thumbnail-timeline-overlay"></div>
          <div class="thumbnail-timeline-scroller" [ngStyle]="{ 'left.px': scrollerPosition }"></div>
          <div fxLayout="column" fxLayoutAlign="center center" class="thumbnail-offline-overlay"
               [ngClass]="{ show: offline }"
          >
            <span class="ui-icon-camera-off"></span>
          </div>
          <ui-preloader *ngIf="imageLoader" class="loader" [diameter]="25" [color]="PreloaderColor.blue"></ui-preloader>
          <img #image
               class="card-image"
               [lazyLoad]="img"
               (onStateChange)="imgStateChange($event)"
          />
        </ng-container>
        <ng-container *ngIf="displayName">
          <ui-truncated-html class="camera-name">{{ cameraName$ | async }}</ui-truncated-html>
        </ng-container>
      </div>
    </ng-container>

  </ng-template>
</ng-container>
