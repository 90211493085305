import { createReducer, on } from '@ngrx/store';
import * as ArchiveActions from '@states/archive/archive.actions';
import { AddArchiveModalConfig, ArchiveModel, ArchiveResponseFilter } from '@models/archive.model';
import { initialArchive, initialArchiveFilter } from '@consts/archive.conts';
import { SortDirection } from '@angular/material/sort';
import { Archive } from '@models/archive.model';
import { UserOrganizationDropDown } from '@models/organization.model';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export interface ArchiveState extends EntityState<Archive.ArchiveDocument> {
  selectedArchive: ArchiveModel;
  isArchiveValid: boolean;
  page: number;
  perPage: number;
  orderBy: string;
  orderDirection: SortDirection;
  query: string;
  filters: ArchiveResponseFilter;
  userAutocomplete: UserOrganizationDropDown[];
  addArchiveModalConfig: AddArchiveModalConfig;
  isLastPage: boolean;
  isInitialLoaded: boolean;
  isLoading: boolean;
}

export const archiveAdapter: EntityAdapter<Archive.ArchiveDocument> = createEntityAdapter<Archive.ArchiveDocument>({
  selectId: (archiveDocument: Archive.ArchiveDocument) => archiveDocument.sessionId,
});

const initialState = archiveAdapter.getInitialState({
  selectedArchive: initialArchive,
  isArchiveValid: false,
  page: 0,
  perPage: 100,
  orderBy: 'name',
  orderDirection: 'asc',
  query: null,
  filters: initialArchiveFilter,
  userAutocomplete: [],
  addArchiveModalConfig: {
    disableSelectedCamera: false,
    disableSelectDateRange: false,
    hasSmartStorage: false,
    smartStorageInterval: null,
  },
  isLastPage: false,
  isInitialLoaded: true,
});

export const archiveStateReducer = createReducer(
  initialState,
  on(ArchiveActions.resetToInitialState, state => {
    return {
      ...initialState,
    };
  }),
  on(ArchiveActions.resetSelectedArchive, state => {
    return {
      ...state,
      selectedArchive: initialState.selectedArchive,
      isArchiveValid: initialState.isArchiveValid,
    };
  }),
  on(ArchiveActions.changeArchiveProperty, (state, { property, value }) => {
    return {
      ...state,
      selectedArchive: {
        ...state.selectedArchive,
        [property]: value,
      },
    };
  }),
  on(ArchiveActions.changeGrantedAccessParams, (state, { property, value }) => {
    return {
      ...state,
      selectedArchive: {
        ...state.selectedArchive,
        grantedAccessParams: {
          [property]: value,
        },
      },
    };
  }),
  on(ArchiveActions.setValid, (state, { isArchiveValid }) => {
    return {
      ...state,
      isArchiveValid,
    };
  }),
  on(ArchiveActions.removeTags, (state, { value }) => {
    const index = state.selectedArchive.tags.findIndex(item => item === value);
    const tags = [...state.selectedArchive.tags];
    if (index > -1) {
      tags.splice(index, 1);
    }
    return {
      ...state,
      selectedArchive: {
        ...state.selectedArchive,
        tags,
      },
    };
  }),
  on(ArchiveActions.getArchiveListSuccess, (state, { archives }) => {
    return archiveAdapter.addMany(archives, {
      ...state,
      isLastPage: archives.length < state.perPage,
      page: state.page + 1,
    });
  }),
  on(ArchiveActions.setPaginationParams, (state, { page, perPage }) => {
    return {
      ...state,
      page,
      perPage,
    };
  }),
  on(ArchiveActions.setFilter, (state, { filter, value }) => {
    return {
      ...state,
      filters: {
        ...state.filters,
        [filter]: value,
      },
    };
  }),
  on(ArchiveActions.rmFilter, (state, { filter, value }) => {
    return {
      ...state,
      filters: {
        ...state.filters,
        [filter]: removeFilter(state.filters, value, filter),
      },
    };
  }),
  on(ArchiveActions.updateArchiveProgress, (state, { archive }) => {
    return archiveAdapter.updateOne({
        id: archive.sessionId,
        changes: {
          progress: archive.progress,
          status: archive.status,
        },
      },
      state);
  }),
  on(ArchiveActions.setUserAutocomplete, (state, { users }) => {
    return {
      ...state,
      userAutocomplete: Object.values(users),
    };
  }),
  on(ArchiveActions.updateAddArchiveModalConfig, (state, { property, disable }) => {
    return {
      ...state,
      addArchiveModalConfig: {
        ...state.addArchiveModalConfig,
        [property]: disable,
      },
    };
  }),
  on(ArchiveActions.resetArchives, (state) => {
    return archiveAdapter.removeAll({
      ...state,
      isLastPage: initialState.isLastPage,
      page: initialState.page,
      perPage: initialState.perPage,
    });
  }),
  on(ArchiveActions.deleteArchiveSuccess, (state, { sessionId }) => {
    return archiveAdapter.removeOne(sessionId, {
      ...state,
    });
  }),
  on(ArchiveActions.setIsLoading, (state, { isLoading }) => {
    return {
      ...state,
      isLoading,
    };
  }),
  on(ArchiveActions.retrySuccess, (state, { archiveUpdate }) => {
    return archiveAdapter.updateOne({
      id: archiveUpdate.sessionId, changes: {
        progress: archiveUpdate.progress,
        status: archiveUpdate.status,
      },
    }, state);
  }),
);

const removeFilter = (filters: ArchiveResponseFilter, value: any, field: string): any => {
  const result = [...filters[field]];
  switch (field) {
    case 'tags':
      const indexDetectionTypesFilters = result.findIndex(item => item === value);
      if (indexDetectionTypesFilters !== -1) {
        result.splice(indexDetectionTypesFilters, 1);
      }
      break;
    case 'selectedCameras':
      const indexCamera = result.findIndex(item => item['edgeOnly'].cameraId === value);
      if (indexCamera !== -1) {
        result.splice(indexCamera, 1);
      }
      break;
    case 'status':
      const indexStatusFilters = result.findIndex(item => item === value);
      if (indexStatusFilters !== -1) {
        result.splice(indexStatusFilters, 1);
      }
      break;
  }
  return result;
};
