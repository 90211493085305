<div class="wrapper" fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="20px grid">
  <app-thumbnail
    [style.min-width.px]="240"
    [rtl]="rtl"
    *ngFor="let thumb of rendered"
    [optionsInput]="thumb.options"
    [events]="thumb.thumbnails"
    [edgeId]="edgeId"
    [cameraId]="cameraId"
    [isShareVisible]="isShareVisible"
    (onTimePeriodChangeEvent)="onTimePeriodChange($event)"
    [timeIsClickable]="true"
    [thumbnailTemplate]="ThumbnailTemplate.TEMPLATE1"
    (onOpen)="thumbOpened.emit()"
    (onClosed)="thumbClosed.emit()"
    [cameraView]="true"
  ></app-thumbnail>
</div>
