import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { api } from '@consts/url.const';
import { MfaModel } from '@models/mfa.model';

@Injectable()
export class MfaService {

  constructor(private http: HttpClient) {
  }

  public getAll(): Observable<{ list: MfaModel.Document[], deleteEnabled: MfaModel.DeleteEnabledResponse }> {
    return this.http.get<{ list: MfaModel.Document[], deleteEnabled: MfaModel.DeleteEnabledResponse }>(api.users.mfaList);
  }

  public delete(id: string): Observable<{ deleted: boolean, deleteEnabled: MfaModel.DeleteEnabledResponse }> {
    return this.http.delete<{ deleted: boolean, deleteEnabled: MfaModel.DeleteEnabledResponse }>(api.users.mfaDelete(id));
  }

  public create(): Observable<{ qr: string; mfa: MfaModel.Document, deleteEnabled: MfaModel.DeleteEnabledResponse, code: string }> {
    return this.http.post<{ qr: string; mfa: MfaModel.Document, deleteEnabled: MfaModel.DeleteEnabledResponse, code: string }>(api.users.mfaCreate, {});
  }
}
