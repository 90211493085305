import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { GetEdgeIpAddressService } from '../../core/api/get-edge-ip-address.service';
import * as EdgeEditActions from '@states/edge-edit/edge-edit.actions';
import { withLatestFrom } from 'rxjs/operators';
import { catchError, exhaustMap, share, switchMap, takeUntil } from 'rxjs';
import { GetEdgeIpAddressToken } from '../../core/sessions/get-edge-ip-address-session';
import { Edge } from '../../edge/edge.model';
import * as SharedActions from '@states/shared/shared.actions';
import * as AlertEventsActions from '@states/alert-events/alert-events.actions';
import { LocationsService } from '../../locations/locations.service';
import { SessionDataAction } from '@enums/session-data.enum';
import { EdgeStatusService } from '../../edge/edge-status.service';
import { LocationModel } from '../../locations/location.model';
import { EdgeActions } from '@states/edge/edge.action-types';
import { EdgeManagementService, EdgeManagerMsgType } from '../../locations/edge-management.service';
import { HeartbeatService } from '../../development/heartbeat.service';
import { CamerasThumbnailsService } from '../../cameras/camera-thumbnails/camera-thumnails.service';
import { PulsationModels } from '@models/pulsation.model';
import { EdgeService } from '../../edge/edge.service';
import { EdgeSelectors } from '@states/edge/edge.selector-types';
import { CertificationActions } from '@states/certifications/certifications.action-types';
import { startValidateExistsCert } from '@states/certifications/certifications.actions';

@Injectable()
export class EdgeEditEffects {
  public pressSave$ = createEffect(() => this.actions$.pipe(ofType(EdgeEditActions.pressSave), share()), {
    dispatch: false,
    useEffectsErrorHandler: false,
  });

  public pressRefresh$ = createEffect(() => this.actions$.pipe(ofType(EdgeEditActions.pressRefresh), share()), {
    dispatch: false,
    useEffectsErrorHandler: false,
  });

  public getLocalIpAddresses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.getLocalIpAddresses),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([, { selectedEdgeId, selectedLocationId }]) => {
        return this.getEdgeIpAddressService
          .subscribeToGetEdgeIpAddressInfo({
            edgeId: selectedEdgeId,
            locationId: selectedLocationId,
          })
          .pipe(
            switchMap((session: GetEdgeIpAddressToken.AllSessionData) => {
              const ipAddresses: Edge.EdgeIpAddressInfo = session.result?.edgeIpAddressInfo;
              const actions: Action[] = [];
              if (ipAddresses['eth0']) {
                const ip = ipAddresses['eth0'][0].address;
                actions.push(EdgeEditActions.setEth0({ eth0: ip }));
              }
              if (ipAddresses['eth1']) {
                const ip = ipAddresses['eth1'][0].address;
                actions.push(EdgeEditActions.setEth1({ eth1: ip }));
              }
              return actions;
            }),
            catchError(response => {
              console.error(response);
              return [SharedActions.doNothing()];
            }),
          );
      }),
      share(),
    ),
  );

  public getEdgeSwUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.getEdgeSwUpdate),
      exhaustMap(() => [
        SharedActions.setSomethingWentWrong({ somethingWentWrong: false }),
        EdgeEditActions.loadEdgeSwUpdate(),
      ]),
    ),
  );

  public getEdgeConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.getEdgeConfig),
      exhaustMap(({ configType }) => [
        SharedActions.setSomethingWentWrong({ somethingWentWrong: false }),
        EdgeEditActions.loadEdgeConfig({ configType }),
      ]),
    ),
  );

  public loadEdgeSwUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.loadEdgeSwUpdate),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([, { selectedLocationId, selectedEdgeId }]) => {
        return this.edgeManagementService.getEdgeSwUpdate(selectedEdgeId)
          .pipe(
            switchMap(result => {
              return [
                EdgeEditActions.loadEdgeSwUpdateSuccess({
                  result,
                }),
              ];
            }),
            catchError(response => {
              return [
                SharedActions.showMessage({ error: JSON.stringify(response) }),
                EdgeEditActions.loadEdgeSwUpdateFailed({
                  error: JSON.stringify(response),
                }),
                SharedActions.setSomethingWentWrong({ somethingWentWrong: true }),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public loadEdgeConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.loadEdgeConfig),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{ configType }, { selectedLocationId, selectedEdgeId }]) => {
        return this.locationService.getEdgeConfig(selectedLocationId, selectedEdgeId, configType)
          .pipe(
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.getEdgeConfig,
                }),
              ];
            }),
            catchError(response => {
              return [
                SharedActions.showMessage({ error: JSON.stringify(response) }),
                SharedActions.setSomethingWentWrong({ somethingWentWrong: true }),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public uploadEdgeConfigJson$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.uploadEdgeConfigJson),
      exhaustMap(({ config, configType }) => {
        return [SharedActions.setIsSaving({ isSaving: true }), EdgeEditActions.sendEdgeConfigJson({ config, configType })];
      }),
    ),
  );

  public sendEdgeConfigJson$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.sendEdgeConfigJson),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{ config, configType }, { selectedLocationId, selectedEdgeId }]) => {
        return this.locationService.uploadEdgeConfigJson(config, selectedLocationId, selectedEdgeId, configType)
          .pipe(
            switchMap(res => {
              return [
                SharedActions.setIsSaving({ isSaving: false }),
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.sendEdgeConfigJson,
                }),
              ];
            }),
            catchError(response => {
              return [SharedActions.showMessage({ error: response?.error?.message || 'Unknown error occurred' }), SharedActions.setIsSaving({ isSaving: false })];
            }),
          );
      }),
      share(),
    ),
  );

  public getEdgeStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.getEdgeStatus),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([, { selectedEdgeId }]) => {
        return this.edgeStatusService.getEdgePulsationStatus(selectedEdgeId)
          .pipe(
            switchMap(res => {
              const sendUpdate = res === PulsationModels.ComponentStatus.Online;
              return [EdgeEditActions.setSendUpdate({ sendUpdate })];
            }),
            catchError(response => {
              return [SharedActions.showMessage({ error: 'Unknown error occurred' })];
            }),
          );
      }),
      share(),
    ),
  );

  public updateEdgeConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.uploadEdgeConfig),
      exhaustMap(({ file }) => {
        return [SharedActions.setIsSaving({ isSaving: true }), EdgeEditActions.sendEdgeConfig({ file })];
      }),
    ),
  );

  public sendEdgeConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.sendEdgeConfig),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{ file }, { selectedLocationId, selectedEdgeId }]) => {
        return this.locationService.uploadEdgeConfig(selectedLocationId, selectedEdgeId, file)
          .pipe(
            switchMap(res => {
              return [
                SharedActions.setIsSaving({ isSaving: false }),
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.sendEdgeConfig,
                }),
              ];
            }),
            catchError(response => {
              return [SharedActions.showMessage({ error: 'Unknown error occurred' }), SharedActions.setIsSaving({ isSaving: false })];
            }),
          );
      }),
      share(),
    ),
  );

  public saveEdgeEditForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.saveEdgeEditForm),
      exhaustMap(({ edgeUpdate }) => [SharedActions.setIsSaving({ isSaving: true }), EdgeEditActions.sendEdgeEditForm({ edgeUpdate })]),
    ),
  );

  public sendEdgeEditForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.sendEdgeEditForm),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{ edgeUpdate }, { sendUpdate, selectedEdgeId, selectedLocationId, selectedEdge }]) => {
        const request: LocationModel.UpdateEdgeInLocationRequest = {
          sendUpdate: sendUpdate,
          edgeId: selectedEdgeId,
          locationId: selectedLocationId,
          name: edgeUpdate?.name ?? selectedEdge.name,
          update: edgeUpdate,
          edge: { ...selectedEdge, ...edgeUpdate },
        };

        if (!sendUpdate) {
          return this.locationService.updateEdgeInLocation(request)
            .pipe(
              switchMap(() => {
                return [
                  EdgeActions.UpdateEdgeNoBackendCall({
                    request,
                  }),
                ];
              }),
            );
        } else {
          return [EdgeEditActions.updateEdgeInLocation({ updateEdgeInLocationRequest: request })];
        }
      }),
      share(),
    ),
  );

  public updateEdgeInLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.updateEdgeInLocation),
      switchMap(({ updateEdgeInLocationRequest }) => {
        return this.locationService.updateEdgeInLocation(updateEdgeInLocationRequest)
          .pipe(
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.updateEdgeInLocation,
                  params: {
                    updateEdgeInLocationRequest,
                  },
                }),
              ];
            }),
            catchError(response => {
              return [SharedActions.showMessage({ error: 'Unknown error occurred' })];
            }),
          );
      }),
      share(),
    ),
  );

  public approveEdgeConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.approveEdgeConfig),
      exhaustMap(() => {
        return [SharedActions.setIsSaving({ isSaving: true }), EdgeEditActions.sendApproveEdgeConfig()];
      }),
      catchError(response => {
        return [SharedActions.doNothing()];
      }),
      share(),
    ),
  );

  public sendApproveEdgeConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.sendApproveEdgeConfig),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([, { selectedLocationId, selectedEdgeId }]) => {
        return this.locationService.sendApproveEdgeConfig(selectedLocationId, selectedEdgeId)
          .pipe(
            switchMap(res => {
              return [
                SharedActions.setIsSaving({ isSaving: false }),
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.sendApproveEdgeConfig,
                }),
              ];
            }),
            catchError(response => {
              return [SharedActions.showMessage({ error: 'Unknown error occurred' }), SharedActions.setIsSaving({ isSaving: false })];
            }),
          );
      }),
      share(),
    ),
  );

  public getEdgeDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.getEdgeDocument),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{ docType }, { selectedLocationId, selectedEdgeId }]) => {
        return this.locationService.getEdgeDocument(selectedLocationId, selectedEdgeId, docType)
          .pipe(
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.getEdgeDocument,
                }),
              ];
            }),
            catchError(response => {
              return [
                SharedActions.showMessage({ error: JSON.stringify(response) }),
                SharedActions.setSomethingWentWrong({ somethingWentWrong: true }),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public getEdgeInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.getEdgeInfo),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([, { selectedLocationId, selectedEdgeId }]) => {
        return this.locationService.getEdgeInfo(selectedLocationId, selectedEdgeId)
          .pipe(
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.getEdgeInfo,
                  params: {
                    msTimeout: 20000,
                  },
                }),
              ];
            }),
            catchError(response => {
              return [
                SharedActions.showMessage({ error: JSON.stringify(response) }),
                // SharedActions.setSomethingWentWrong({somethingWentWrong: true}),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public getDeviceMsgsVersion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.getDeviceMsgsVersion),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([, { selectedLocationId, selectedEdgeId }]) => {
        return this.edgeManagementService
          .manageEdge(EdgeManagerMsgType.GetDeviceMsgsVersion, {
            locationId: selectedLocationId,
            edgeId: selectedEdgeId,
          })
          .pipe(
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.getDeviceMsgsVersion,
                }),
              ];
            }),
            catchError(response => {
              return [
                SharedActions.showMessage({ error: JSON.stringify(response) }),
                // SharedActions.setSomethingWentWrong({somethingWentWrong: true}),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public updateSoftwareVersion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.updateSoftwareVersion),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([res, { selectedLocationId, selectedEdgeId }]) => {
        return this.edgeManagementService
          .manageEdge(EdgeManagerMsgType.SWUpdate, {
            locationId: selectedLocationId,
            edgeId: selectedEdgeId,
            version: res.version,
          })
          .pipe(
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.updateSoftwareVersion,
                }),
              ];
            }),
            catchError(response => {
              return [
                SharedActions.showMessage({ error: JSON.stringify(response) }),
                // SharedActions.setSomethingWentWrong({somethingWentWrong: true}),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public getSoftwareVersion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.getSoftwareVersion),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([, { selectedLocationId, selectedEdgeId }]) => {
        return this.edgeManagementService
          .manageEdge(EdgeManagerMsgType.GetSWVersion, {
            locationId: selectedLocationId,
            edgeId: selectedEdgeId,
          })
          .pipe(
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.getSoftwareVersion,
                }),
              ];
            }),
            catchError(response => {
              return [
                SharedActions.showMessage({ error: JSON.stringify(response) }),
                // SharedActions.setSomethingWentWrong({somethingWentWrong: true}),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public getEthDeviceInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.getEthDeviceInfo),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{ ethInterface }, { selectedLocationId, selectedEdgeId }]) => {
        return this.edgeManagementService
          .manageEdge(EdgeManagerMsgType.GetEthDeviceInfo, {
            locationId: selectedLocationId,
            edgeId: selectedEdgeId,
            ethInterface,
          })
          .pipe(
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.getEthDeviceInfo,
                }),
              ];
            }),
            catchError(response => {
              return [SharedActions.showMessage({ error: JSON.stringify(response) })];
            }),
          );
      }),
      share(),
    ),
  );

  public ifUp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.ifUp),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{ ethInterface }, { selectedLocationId, selectedEdgeId }]) => {
        return this.edgeManagementService
          .manageEdge(EdgeManagerMsgType.Ifup, {
            locationId: selectedLocationId,
            edgeId: selectedEdgeId,
            ethInterface,
          })
          .pipe(
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.ifUp,
                }),
              ];
            }),
            catchError(response => {
              return [SharedActions.showMessage({ error: JSON.stringify(response) })];
            }),
          );
      }),
      share(),
    ),
  );

  public ifDown$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.ifDown),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{ ethInterface }, { selectedLocationId, selectedEdgeId }]) => {
        return this.edgeManagementService
          .manageEdge(EdgeManagerMsgType.Ifdown, {
            locationId: selectedLocationId,
            edgeId: selectedEdgeId,
            ethInterface,
          })
          .pipe(
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.IfDown,
                }),
              ];
            }),
            catchError(response => {
              return [SharedActions.showMessage({ error: JSON.stringify(response) })];
            }),
          );
      }),
      share(),
    ),
  );

  public deleteEthManualConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.deleteEthManualConfiguration),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{ ethInterface }, { selectedLocationId, selectedEdgeId }]) => {
        return this.edgeManagementService
          .manageEdge(EdgeManagerMsgType.DeleteEthManualConfiguration, {
            locationId: selectedLocationId,
            edgeId: selectedEdgeId,
            ethInterface,
          })
          .pipe(
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.deleteEthManualConfiguration,
                }),
              ];
            }),
            catchError(response => {
              return [SharedActions.showMessage({ error: JSON.stringify(response) })];
            }),
          );
      }),
      share(),
    ),
  );

  public pingAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.ping),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{ pingAddress }, { selectedLocationId, selectedEdgeId }]) => {
        return this.edgeManagementService
          .manageEdge(EdgeManagerMsgType.Ping, {
            locationId: selectedLocationId,
            edgeId: selectedEdgeId,
            pingAddress,
          })
          .pipe(
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.ping,
                  params: {
                    msTimeout: 20000,
                  },
                }),
              ];
            }),
            catchError(response => {
              return [SharedActions.showMessage({ error: JSON.stringify(response) })];
            }),
          );
      }),
      share(),
    ),
  );

  public ifconfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.ifconfig),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([, { selectedLocationId, selectedEdgeId }]) => {
        return this.edgeManagementService
          .manageEdge(EdgeManagerMsgType.Ifconfig, {
            locationId: selectedLocationId,
            edgeId: selectedEdgeId,
          })
          .pipe(
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.ifConfig,
                }),
              ];
            }),
            catchError(response => {
              return [SharedActions.showMessage({ error: JSON.stringify(response) })];
            }),
          );
      }),
      share(),
    ),
  );

  public routeInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.routeInfo),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([, { selectedLocationId, selectedEdgeId }]) => {
        return this.edgeManagementService
          .manageEdge(EdgeManagerMsgType.RouteInfo, {
            locationId: selectedLocationId,
            edgeId: selectedEdgeId,
          })
          .pipe(
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.routeInfo,
                }),
              ];
            }),
            catchError(response => {
              return [SharedActions.showMessage({ error: JSON.stringify(response) })];
            }),
          );
      }),
      share(),
    ),
  );

  public interfaceEthConf$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.interfaceEthConf),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{ ethInterface }, { selectedLocationId, selectedEdgeId }]) => {
        return this.edgeManagementService
          .manageEdge(EdgeManagerMsgType.InterfaceEthConf, {
            locationId: selectedLocationId,
            edgeId: selectedEdgeId,
            ethInterface,
          })
          .pipe(
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.interfaceEthConf,
                }),
              ];
            }),
            catchError(response => {
              return [SharedActions.showMessage({ error: JSON.stringify(response) })];
            }),
          );
      }),
      share(),
    ),
  );

  public arp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.arp),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([, { selectedLocationId, selectedEdgeId }]) => {
        return this.edgeManagementService
          .manageEdge(EdgeManagerMsgType.Arp, {
            locationId: selectedLocationId,
            edgeId: selectedEdgeId,
          })
          .pipe(
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.arp,
                }),
              ];
            }),
            catchError(response => {
              return [SharedActions.showMessage({ error: JSON.stringify(response) })];
            }),
          );
      }),
      share(),
    ),
  );

  public deleteLogs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.deleteLogs),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([, { selectedLocationId, selectedEdgeId }]) => {
        return this.edgeManagementService
          .manageEdge(EdgeManagerMsgType.DeleteLogs, {
            locationId: selectedLocationId,
            edgeId: selectedEdgeId,
          })
          .pipe(
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.deleteLogs,
                }),
              ];
            }),
            catchError(response => {
              return [SharedActions.showMessage({ error: JSON.stringify(response) })];
            }),
          );
      }),
      share(),
    ),
  );

  public writeEthManualConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.writeEthManualConfiguration),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{ ethConfig }, { selectedLocationId, selectedEdgeId }]) => {
        const { ethInterface, ...rest } = ethConfig;

        return this.edgeManagementService
          .manageEdge(EdgeManagerMsgType.NMCLIConfigure, {
            locationId: selectedLocationId,
            edgeId: selectedEdgeId,
            ethConfig: rest,
            ethInterface,
          })
          .pipe(
            switchMap(res => {
              return [
                SharedActions.setIsSaving({ isSaving: true }),
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.writeEthManualConfiguration,
                  params: {
                    msTimeout: 7000,
                  },
                }),
              ];
            }),
            catchError(response => {
              return [SharedActions.showMessage({ error: JSON.stringify(response) })];
            }),
          );
      }),
      share(),
    ),
  );

  public getDisksInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.getdisksinfo),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([, { selectedLocationId, selectedEdgeId }]) => {
        return this.edgeManagementService
          .manageEdge(EdgeManagerMsgType.GetDisksInfo, {
            locationId: selectedLocationId,
            edgeId: selectedEdgeId,
          })
          .pipe(
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.getDisksInfo,
                }),
              ];
            }),
            catchError(response => {
              return [SharedActions.showMessage({ error: JSON.stringify(response) })];
            }),
          );
      }),
      share(),
    ),
  );

  public mount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.mount),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([, { selectedLocationId, selectedEdgeId }]) => {
        return this.edgeManagementService
          .manageEdge(EdgeManagerMsgType.Mount, {
            locationId: selectedLocationId,
            edgeId: selectedEdgeId,
          })
          .pipe(
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.mount,
                }),
              ];
            }),
            catchError(response => {
              return [SharedActions.showMessage({ error: JSON.stringify(response) })];
            }),
          );
      }),
      share(),
    ),
  );

  public getEdgeLogs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.getEdgeLogs),
      exhaustMap(({ daysBack }) => [
        SharedActions.setSomethingWentWrong({ somethingWentWrong: false }),
        EdgeEditActions.requestEdgeLogs({ daysBack }),
      ]),
    ),
  );

  public requestEdgeLogs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.requestEdgeLogs),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{ daysBack }, { selectedLocationId, selectedEdgeId }]) => {
        return this.locationService
          .getEdgeLogs({
            locationId: selectedLocationId,
            edgeId: selectedEdgeId,
            daysBack,
          })
          .pipe(
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.getEdgeLogs,
                }),
              ];
            }),
            catchError(response => {
              return [SharedActions.showMessage({ error: JSON.stringify(response) })];
            }),
          );
      }),
      share(),
    ),
  );

  public getEdgeAWSLogs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.getEdgeAWSLogs),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      exhaustMap(([, { selectedEdgeId }]) => {
        return this.locationService.getEdgeAwsLogs({ edgeId: selectedEdgeId })
          .pipe(
            switchMap(res => {
              return [EdgeEditActions.setEdgeAWSLogs({ logs: res })];
            }),
            catchError(response => {
              return [SharedActions.showMessage({ error: JSON.stringify(response) })];
            }),
          );
      }),
      share(),
    ),
  );

  public dockerPs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.dockerPs),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([, { selectedLocationId, selectedEdgeId }]) => {
        return this.edgeManagementService
          .manageEdge(EdgeManagerMsgType.DockerPs, {
            locationId: selectedLocationId,
            edgeId: selectedEdgeId,
          })
          .pipe(
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.dockerPs,
                }),
              ];
            }),
            catchError(response => {
              return [SharedActions.showMessage({ error: JSON.stringify(response) })];
            }),
          );
      }),
      share(),
    ),
  );

  public dockerInspect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.dockerInspect),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{ container }, { selectedLocationId, selectedEdgeId }]) => {
        return this.edgeManagementService
          .manageEdge(EdgeManagerMsgType.DockerInspect, {
            locationId: selectedLocationId,
            edgeId: selectedEdgeId,
            container,
          })
          .pipe(
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.dockerInspect,
                }),
              ];
            }),
            catchError(response => {
              return [SharedActions.showMessage({ error: JSON.stringify(response) })];
            }),
          );
      }),
      share(),
    ),
  );

  public dockerStats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.dockerStats),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([, { selectedLocationId, selectedEdgeId }]) => {
        return this.edgeManagementService
          .manageEdge(EdgeManagerMsgType.DockerStats, {
            locationId: selectedLocationId,
            edgeId: selectedEdgeId,
          })
          .pipe(
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.dockerStats,
                }),
              ];
            }),
            catchError(response => {
              return [SharedActions.showMessage({ error: JSON.stringify(response) })];
            }),
          );
      }),
      share(),
    ),
  );

  public nMCLI$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.nMCLI),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{ args }, { selectedLocationId, selectedEdgeId }]) => {
        return this.edgeManagementService
          .manageEdge(EdgeManagerMsgType.NMCLI, {
            locationId: selectedLocationId,
            edgeId: selectedEdgeId,
            args,
          })
          .pipe(
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.nMCLI,
                }),
              ];
            }),
            catchError(response => {
              return [SharedActions.showMessage({ error: JSON.stringify(response) })];
            }),
          );
      }),
      share(),
    ),
  );

  public du$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.du),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{ args }, { selectedLocationId, selectedEdgeId }]) => {
        return this.edgeManagementService
          .manageEdge(EdgeManagerMsgType.DU, {
            locationId: selectedLocationId,
            edgeId: selectedEdgeId,
            args,
          })
          .pipe(
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.dU,
                }),
              ];
            }),
            catchError(response => {
              return [SharedActions.showMessage({ error: JSON.stringify(response) })];
            }),
          );
      }),
      share(),
    ),
  );

  public ls$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.ls),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{ args }, { selectedLocationId, selectedEdgeId }]) => {
        return this.edgeManagementService
          .manageEdge(EdgeManagerMsgType.LS, {
            locationId: selectedLocationId,
            edgeId: selectedEdgeId,
            args,
          })
          .pipe(
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.lS,
                }),
              ];
            }),
            catchError(response => {
              return [SharedActions.showMessage({ error: JSON.stringify(response) })];
            }),
          );
      }),
      share(),
    ),
  );

  public nMCLIDeviceShow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.nMCLIDeviceShow),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{ ethInterface }, { selectedLocationId, selectedEdgeId }]) => {
        return this.edgeManagementService
          .manageEdge(EdgeManagerMsgType.NMCLIDeviceShow, {
            locationId: selectedLocationId,
            edgeId: selectedEdgeId,
            ethInterface,
          })
          .pipe(
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.nMCLIDeviceShow,
                }),
              ];
            }),
            catchError(response => {
              return [SharedActions.showMessage({ error: JSON.stringify(response) })];
            }),
          );
      }),
      share(),
    ),
  );

  public nMCLIDeviceStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.nMCLIDeviceStatus),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([, { selectedLocationId, selectedEdgeId }]) => {
        return this.edgeManagementService
          .manageEdge(EdgeManagerMsgType.NMCLIDeviceStatus, {
            locationId: selectedLocationId,
            edgeId: selectedEdgeId,
          })
          .pipe(
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.nMCLIDeviceStatus,
                }),
              ];
            }),
            catchError(response => {
              return [SharedActions.showMessage({ error: JSON.stringify(response) })];
            }),
          );
      }),
      share(),
    ),
  );

  public checkEdgeInstallation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.checkEdgeInstallation),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([, { selectedLocationId, selectedEdgeId }]) => {
        return this.edgeManagementService
          .manageEdge(EdgeManagerMsgType.CheckEdgeInstallation, {
            locationId: selectedLocationId,
            edgeId: selectedEdgeId,
          })
          .pipe(
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.checkEdgeInstallation,
                  params: {
                    msTimeout: 40000,
                  },
                }),
              ];
            }),
            catchError(response => {
              return [SharedActions.showMessage({ error: JSON.stringify(response) })];
            }),
          );
      }),
      share(),
    ),
  );

  public getDate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.getDate),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([, { selectedLocationId, selectedEdgeId }]) => {
        return this.edgeManagementService
          .manageEdge(EdgeManagerMsgType.GetDate, {
            locationId: selectedLocationId,
            edgeId: selectedEdgeId,
          })
          .pipe(
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.getDate,
                }),
              ];
            }),
            catchError(response => {
              return [SharedActions.showMessage({ error: JSON.stringify(response) })];
            }),
          );
      }),
      share(),
    ),
  );

  public setDate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.setDate),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{ date }, { selectedLocationId, selectedEdgeId }]) => {
        return this.edgeManagementService
          .manageEdge(EdgeManagerMsgType.SetDate, {
            locationId: selectedLocationId,
            edgeId: selectedEdgeId,
            date,
          })
          .pipe(
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.setDate,
                }),
              ];
            }),
            catchError(response => {
              return [SharedActions.showMessage({ error: JSON.stringify(response) })];
            }),
          );
      }),
      share(),
    ),
  );

  public setTimezone$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.setTimezone),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{ date }, { selectedLocationId, selectedEdgeId }]) => {
        return this.edgeManagementService
          .manageEdge(EdgeManagerMsgType.SetTimezone, {
            locationId: selectedLocationId,
            edgeId: selectedEdgeId,
            date,
          })
          .pipe(
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.setTimezone,
                }),
              ];
            }),
            catchError(response => {
              return [SharedActions.showMessage({ error: JSON.stringify(response) })];
            }),
          );
      }),
      share(),
    ),
  );

  public setNtp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.setNtp),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{ ntpEnabled }, { selectedLocationId, selectedEdgeId }]) => {
        return this.edgeManagementService
          .manageEdge(EdgeManagerMsgType.SetNTP, {
            locationId: selectedLocationId,
            edgeId: selectedEdgeId,
            ntpEnabled,
          })
          .pipe(
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.setNTP,
                }),
              ];
            }),
            catchError(response => {
              return [SharedActions.showMessage({ error: JSON.stringify(response) })];
            }),
          );
      }),
      share(),
    ),
  );

  public pM2Logs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.pM2Logs),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{ lines }, { selectedLocationId, selectedEdgeId }]) => {
        return this.edgeManagementService
          .manageEdge(EdgeManagerMsgType.PM2Logs, {
            locationId: selectedLocationId,
            edgeId: selectedEdgeId,
            lines,
          })
          .pipe(
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.pM2Logs,
                }),
              ];
            }),
            catchError(response => {
              return [SharedActions.showMessage({ error: JSON.stringify(response) })];
            }),
          );
      }),
      share(),
    ),
  );

  public pS$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.pS),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([, { selectedLocationId, selectedEdgeId }]) => {
        return this.edgeManagementService
          .manageEdge(EdgeManagerMsgType.PS, {
            locationId: selectedLocationId,
            edgeId: selectedEdgeId,
          })
          .pipe(
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.pS,
                }),
              ];
            }),
            catchError(response => {
              return [SharedActions.showMessage({ error: JSON.stringify(response) })];
            }),
          );
      }),
      share(),
    ),
  );

  public getEdgeIPInfos$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.getEdgeIPInfos),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([, { selectedLocationId, selectedEdgeId }]) => {
        return this.edgeManagementService
          .manageEdge(EdgeManagerMsgType.GetEdgeIPInfos, {
            locationId: selectedLocationId,
            edgeId: selectedEdgeId,
          })
          .pipe(
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.getEdgeIPInfos,
                }),
              ];
            }),
            catchError(response => {
              return [SharedActions.showMessage({ error: JSON.stringify(response) })];
            }),
          );
      }),
      share(),
    ),
  );

  public getExternalIP$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.getExternalIP),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([, { selectedLocationId, selectedEdgeId }]) => {
        return this.edgeManagementService
          .manageEdge(EdgeManagerMsgType.GetExternalIP, {
            locationId: selectedLocationId,
            edgeId: selectedEdgeId,
          })
          .pipe(
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.getExternalIP,
                }),
              ];
            }),
            catchError(response => {
              return [SharedActions.showMessage({ error: JSON.stringify(response) })];
            }),
          );
      }),
      share(),
    ),
  );

  public getHeartbeats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.getHeartbeats),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([, { selectedLocationId, selectedEdgeId, heartBeatsPaginationTimeStamps, heartBeatsDateFrom, heartBeatsDateTo }]) => {
        return this.heartbeatService
          .getAllHeartbeats(0, 20, {
            edgeId: selectedEdgeId,
            start: heartBeatsDateFrom,
            end: heartBeatsPaginationTimeStamps ?? heartBeatsDateTo,
          })
          .pipe(
            switchMap(res => {
              return [EdgeEditActions.getHeartbeatsSuccess({ heartbeats: res })];
            }),
            catchError(response => {
              return [SharedActions.showMessage({ error: JSON.stringify(response) })];
            }),
          );
      }),
      share(),
    ),
  );

  public getCameraThumbnails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.getCameraThumbnails),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{ start, end, selectedCamera }, { selectedLocationId, selectedEdgeId }]) => {
        return this.cameraThumbnailsService.getThumbnailsByDateFromDb(selectedEdgeId, selectedCamera.edgeOnly.cameraId, start, end)
          .pipe(
            switchMap(res => {
              return [EdgeEditActions.getCameraThumbnailsSuccess({ thumbnailsBitmap: res })];
            }),
            catchError(response => {
              return [SharedActions.showMessage({ error: JSON.stringify(response) })];
            }),
          );
      }),
      share(),
    ),
  );

  public getEdgeById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.getEdgeById),
      withLatestFrom(
        this.store$.pipe(select(state => state.edgeEditState)),
        this.store$.pipe(select(EdgeSelectors.selectEdgeState)),
      ),
      exhaustMap(([, { selectedEdgeId, selectedLocationId }, { entities }]) => {
        const selectedEdge = entities[selectedEdgeId];
        return [EdgeEditActions.setSelectedEdge({ selectedEdge })];
      }),
    ),
  );

  public getEdgeSWByIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.getEdgeSWByIds),
      switchMap(({ edgeId, locationId }) => {
        return this.edgeManagementService.manageEdge(EdgeManagerMsgType.GetSWVersion, { locationId, edgeId })
          .pipe(
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.getSoftwareVersion,
                }),
              ];
            }),
            catchError(response => {
              return [
                SharedActions.showMessage({ error: JSON.stringify(response) }),
                // SharedActions.setSomethingWentWrong({somethingWentWrong: true}),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public uploadVideoStorage = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.uploadVideoStorage),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      exhaustMap(([{ filePattern, dirPattern, cameraId }, { selectedLocationId, selectedEdgeId }]) => [
        SharedActions.setIsSaving({ isSaving: true }),
        EdgeEditActions.sendUploadVideoStorage({ filePattern, dirPattern, cameraId }),
      ]),
    ),
  );

  public listVideoStorage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.listVideoStorage),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      exhaustMap(([{ dirPattern, cameraId }, { selectedLocationId, selectedEdgeId }]) => [
        SharedActions.setIsSaving({ isSaving: true }),
        EdgeEditActions.sendListVideoStorage({ dirPattern, cameraId }),
      ]),
    ),
  );

  public uploadVideoStorage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.sendUploadVideoStorage),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{ filePattern, dirPattern, cameraId }, { selectedLocationId, selectedEdgeId }]) => {
        return this.locationService
          .uploadVideoStorage({
            locationId: selectedLocationId,
            edgeId: selectedEdgeId,
            cameraId,
            filePattern,
            dirPattern,
          })
          .pipe(
            switchMap(res => {
              return [
                SharedActions.setIsSaving({ isSaving: false }),
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.UploadVS,
                }),
                SharedActions.showMessage({ success: 'Upload video storage saved' }),
              ];
            }),
            catchError(response => {
              return [SharedActions.setIsSaving({ isSaving: false }), SharedActions.showMessage({ error: JSON.stringify(response) })];
            }),
          );
      }),
      share(),
    ),
  );

  public sendUploadVideoStorage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.sendListVideoStorage),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{ dirPattern, cameraId }, { selectedLocationId, selectedEdgeId }]) => {
        return this.locationService
          .listVideoStorage({
            locationId: selectedLocationId,
            edgeId: selectedEdgeId,
            cameraId,
            dirPattern,
          })
          .pipe(
            switchMap(res => {
              return [
                SharedActions.setIsSaving({ isSaving: false }),
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.ListVS,
                }),
                SharedActions.showMessage({ success: 'List video storage Sent' }),
              ];
            }),
            catchError(response => {
              return [SharedActions.setIsSaving({ isSaving: false }), SharedActions.showMessage({ error: JSON.stringify(response) })];
            }),
          );
      }),
      share(),
    ),
  );

  public rmEdgeLink$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.rmEdgeLink),
      switchMap(({ link }) => {
        return this.locationService
          .rmEdgeAWSLog({
            link,
          })
          .pipe(
            switchMap(res => {
              return [
                SharedActions.showMessage({
                  success: 'Log has been removed',
                }),
              ];
            }),
            catchError(response => {
              return [SharedActions.showMessage({ error: JSON.stringify(response) })];
            }),
          );
      }),
      share(),
    ),
  );

  public rmCameraFromEdge$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.rmCameraFromEdge),
      exhaustMap(({ cameraId }) => [SharedActions.setIsDeleting({ isDeleting: true }), EdgeEditActions.sendRmCameraFromEdge({ cameraId })]),
    ),
  );

  public sendRmCameraFromEdge$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.sendRmCameraFromEdge),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{ cameraId }, { selectedLocationId, selectedEdgeId }]) => {
        return this.locationService
          .rmCamera({
            locationId: selectedLocationId,
            edgeId: selectedEdgeId,
            cameraId,
          })
          .pipe(
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.rmCamera,
                }),
              ];
            }),
            catchError(response => {
              return [SharedActions.setIsDeleting({ isDeleting: false }), SharedActions.showMessage({ error: JSON.stringify(response) })];
            }),
          );
      }),
      share(),
    ),
  );

  public rmEventFromEdge$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.rmEventFromEdge),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      exhaustMap(([{ eventId, cameraId }, { selectedLocationId, selectedEdgeId }]) => [
        SharedActions.setIsDeleting({ isDeleting: true }),
        AlertEventsActions.removeEvent({ id: eventId }),
      ]),
    ),
  );

  public getAnalyticConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.getAnalyticConfig),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{ cameraId }, { selectedLocationId, selectedEdgeId }]) =>
        this.locationService
          .getAnalyticConfigJson({
            cameraId,
            edgeId: selectedEdgeId,
            locationId: selectedLocationId,
          })
          .pipe(
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.getAnalyticConfig,
                }),
              ];
            }),
            catchError(response => {
              return [SharedActions.showMessage({ error: JSON.stringify(response) })];
            }),
          ),
      ),
    ),
  );

  public updateAnalyticConfigJson$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.updateAnalyticConfigJson),
      exhaustMap(({ config, cameraId }) => {
        return [SharedActions.setIsSaving({ isSaving: true }), EdgeEditActions.sendUpdateAnalyticConfigJson({ config, cameraId })];
      }),
    ),
  );

  public sendUpdateAnalyticConfigJson$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.sendUpdateAnalyticConfigJson),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{ config, cameraId }, { selectedLocationId, selectedEdgeId }]) => {
        return this.locationService
          .updateAnalyticConfigJson({
            locationId: selectedLocationId,
            edgeId: selectedEdgeId,
            config,
            cameraId,
          })
          .pipe(
            switchMap(res => {
              return [
                SharedActions.setIsSaving({ isSaving: false }),
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.sendUpdateAnalyticConfigJson,
                }),
              ];
            }),
            catchError(response => {
              return [SharedActions.showMessage({ error: 'Unknown error occurred' }), SharedActions.setIsSaving({ isSaving: false })];
            }),
          );
      }),
      share(),
    ),
  );

  public probFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.probFile),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{ filename }, { selectedLocationId, selectedEdgeId }]) => {
        return this.locationService
          .probFile({
            locationId: selectedLocationId,
            edgeId: selectedEdgeId,
            filename,
          })
          .pipe(
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.probFile,
                }),
              ];
            }),
            catchError(response => {
              return [SharedActions.showMessage({ error: JSON.stringify(response) })];
            }),
          );
      }),
      share(),
    ),
  );

  /**
   * @deprecated
   */
  public manage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.manage),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{ action, params }, { selectedLocationId, selectedEdgeId }]) => {
        return this.edgeManagementService
          .manageEdgeAction(action, {
            ...params,
            locationId: selectedLocationId,
            edgeId: selectedEdgeId,
          })
          .pipe(
            takeUntil(this.actions$.pipe(ofType(EdgeEditActions.cancelManageRequest))),
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.manageAction,
                }),
              ];
            }),
            catchError(response => {
              return [
                SharedActions.showMessage({ error: JSON.stringify(response) }),
                // SharedActions.setSomethingWentWrong({somethingWentWrong: true}),
              ];
            }),
          );
      }),
      share(),
    ),
  );


  public restoreEdge$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.restoreEdge),
      switchMap(({ locationId, edgeId }) => {
        return this.edgeService
          .restoreEdge(locationId, edgeId)
          .pipe(
            takeUntil(this.actions$.pipe(ofType(EdgeEditActions.cancelManageRequest))),
            switchMap(result => {
              return [
                EdgeEditActions.restoreEdgeSuccess({ result }),
              ];
            }),
            catchError(response => {
              return [
                EdgeEditActions.restoreEdgeFail(),
                SharedActions.showMessage({ error: JSON.stringify(response) }),
                // SharedActions.setSomethingWentWrong({somethingWentWrong: true}),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public getEdgeNtp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.getEdgeNtp),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{}, { selectedLocationId, selectedEdgeId }]) => {
        return this.locationService.getEdgeNtp(selectedLocationId, selectedEdgeId)
          .pipe(
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.getNtpServers,
                }),
              ];
            }),
            catchError(response => {
              return [
                SharedActions.showMessage({ error: JSON.stringify(response) }),
                SharedActions.setSomethingWentWrong({ somethingWentWrong: true }),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public setEdgeNtp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.setEdgeNtp),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{ servers }, { selectedLocationId, selectedEdgeId }]) => {
        return this.locationService.setEdgeNtp(selectedLocationId, selectedEdgeId, servers)
          .pipe(
            switchMap(res => {
              return [
                SharedActions.setIsSaving({ isSaving: true }),
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.setNtpServers,
                }),
              ];
            }),
            catchError(response => {
              return [
                SharedActions.showMessage({ error: JSON.stringify(response) }),
                SharedActions.setSomethingWentWrong({ somethingWentWrong: true }),
              ];
            }),
          );
      }),
      share(),
    ),
  );


  public getCoreRecoveryDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.getCoreRecoveryDocument),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([, { selectedEdge }]) => {
        return this.locationService.getCoreRecoveryDocument(selectedEdge.edgeId)
          .pipe(
            switchMap(res => {
              return [
                EdgeEditActions.getCoreRecoveryDocumentSuccess({ edgeRecoveryDocument: res ?? {} }),
              ];
            }),
            catchError(response => {
              return [
                SharedActions.showMessage({ error: JSON.stringify(response) }),
                EdgeEditActions.getCoreRecoveryDocumentFail(),
              ];
            }),
          );
      }),
      share(),
    ),
  );


  public saveCoreRecoveryDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.saveCoreRecoveryDocument),
      exhaustMap(({ body }) => {
        return [SharedActions.setIsSaving({ isSaving: true }), EdgeEditActions.serverCallSaveCoreRecoveryDocument({ body })];
      }),
    ),
  );

  public serverCallSaveCoreRecoveryDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.serverCallSaveCoreRecoveryDocument),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{ body }, { selectedEdge }]) => {
        return this.locationService.saveCoreRecoveryDocument(selectedEdge.edgeId, body)
          .pipe(
            switchMap(res => {
              return [
                EdgeEditActions.serverCallSaveCoreRecoveryDocumentSuccess(),
                SharedActions.showMessage({ success: 'Saved' }),
                SharedActions.setIsSaving({ isSaving: false }),
              ];
            }),
            catchError(response => {
              return [
                SharedActions.setIsSaving({ isSaving: false }),
                SharedActions.showMessage({ error: !!response?.error?.message ? response?.error?.message : JSON.stringify(response) }),
                EdgeEditActions.serverCallSaveCoreRecoveryDocumentFail(),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public dig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.dig),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{ address }, { selectedLocationId, selectedEdgeId }]) => {
        return this.edgeManagementService
          .manageEdge(EdgeManagerMsgType.Dig, {
            locationId: selectedLocationId,
            edgeId: selectedEdgeId,
            address,
          })
          .pipe(
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.dig,
                  params: {
                    msTimeout: 20000,
                  },
                }),
              ];
            }),
            catchError(response => {
              return [SharedActions.showMessage({ error: JSON.stringify(response) })];
            }),
          );
      }),
      share(),
    ),
  );

  public traceroute$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.traceroute),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{ address }, { selectedLocationId, selectedEdgeId }]) => {
        return this.edgeManagementService
          .manageEdge(EdgeManagerMsgType.Traceroute, {
            locationId: selectedLocationId,
            edgeId: selectedEdgeId,
            address,
          })
          .pipe(
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.traceroute,
                  params: {
                    msTimeout: 20000,
                  },
                }),
              ];
            }),
            catchError(response => {
              return [SharedActions.showMessage({ error: JSON.stringify(response) })];
            }),
          );
      }),
      share(),
    ),
  );

  public speedTest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.speedTest),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{}, { selectedLocationId, selectedEdgeId }]) => {
        return this.edgeManagementService
          .manageEdge(EdgeManagerMsgType.SpeedtestInfo, {
            locationId: selectedLocationId,
            edgeId: selectedEdgeId,
          })
          .pipe(
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.speedtest,
                  params: {
                    msTimeout: 40000,
                  },
                }),
              ];
            }),
            catchError(response => {
              return [SharedActions.showMessage({ error: JSON.stringify(response) })];
            }),
          );
      }),
      share(),
    ),
  );

  public firewallTest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.firewallTest),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{}, { selectedLocationId, selectedEdgeId }]) => {
        return this.edgeManagementService
          .manageEdge(EdgeManagerMsgType.CheckWhiteList, {
            locationId: selectedLocationId,
            edgeId: selectedEdgeId,
          })
          .pipe(
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.firewall,
                  params: {
                    msTimeout: 20000,
                  },
                }),
              ];
            }),
            catchError(response => {
              return [SharedActions.showMessage({ error: JSON.stringify(response) })];
            }),
          );
      }),
      share(),
    ),
  );


  public getEdgeHeartBeatStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeEditActions.getEdgeHeartBeatStatus),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{}, { selectedLocationId, selectedEdgeId }]) => {
        return this.heartbeatService
          .getEdgeHeartBeatsStatus(selectedEdgeId)
          .pipe(
            switchMap(res => {
              return [
                EdgeEditActions.getEdgeHeartBeatStatusSuccess({ body: res.res }),
              ];
            }),
            catchError(response => {
              return [SharedActions.showMessage({ error: JSON.stringify(response) })];
            }),
          );
      }),
      share(),
    ),
  );


  public startGetCertifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CertificationActions.startGetCertifications),
      exhaustMap(() => [
        CertificationActions.setCertificationsLoading({ isLoading: true }),
        CertificationActions.getCertifications(),
      ]),
    ),
  );

  public getCertifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CertificationActions.getCertifications),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{}, { selectedEdgeId }]) => {
        return this.edgeService
          .getCertifications(selectedEdgeId)
          .pipe(
            switchMap(res => {
              return [
                CertificationActions.getCertificationsSuccess({ entities: res }),
              ];
            }),
            catchError(response => {
              return [
                CertificationActions.getCertificationsFail(),
              ];
            }),
          );
      }),
      share(),
    ),
  );


  public updateEdgeWithExistsCert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CertificationActions.updateEdgeWithExistsCert),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{ id }, { selectedEdgeId, selectedLocationId }]) => {
        return this.edgeService
          .updateEdgeWithCertificate(selectedLocationId, selectedEdgeId, id)
          .pipe(
            switchMap(res => {
              return [
                CertificationActions.updateEdgeWithExistsCertSuccess(),
                CertificationActions.getCertifications(),
                SharedActions.showMessage({ success: 'Edge certificates updated successfully' }),
              ];
            }),
            catchError(response => {
              return [
                CertificationActions.updateEdgeWithExistsCertFail(),
              ];
            }),
          );
      }),
      share(),
    ),
  );


  public startValidateExistsCert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CertificationActions.startValidateExistsCert),
      exhaustMap(({ id }) => [
        CertificationActions.setValidationLoading({ validationLoading: true }),
        CertificationActions.validateExistsCert({ id }),
      ]),
    ),
  );


  public validateExistsCert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CertificationActions.validateExistsCert),
      withLatestFrom(this.store$.pipe(select(state => state.edgeEditState))),
      switchMap(([{ id }, { selectedEdgeId, selectedLocationId }]) => {
        return this.edgeService
          .validateExistsCert(selectedLocationId, selectedEdgeId, id)
          .pipe(
            switchMap(res => {
              return [
                CertificationActions.validateExistsCertSuccess(),
              ];
            }),
            catchError(response => {
              return [
                CertificationActions.updateEdgeWithExistsCertFail(),
              ];
            }),
          );
      }),
      share(),
    ),
  );


  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private getEdgeIpAddressService: GetEdgeIpAddressService,
    private locationService: LocationsService,
    private edgeStatusService: EdgeStatusService,
    private edgeManagementService: EdgeManagementService,
    private cameraThumbnailsService: CamerasThumbnailsService,
    private heartbeatService: HeartbeatService,
    private edgeService: EdgeService,
  ) {
  }
}
